// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloFetchPolicy } from '../../common/Definitions';
import { logPageView, logEventWithProperties, VIEW, EVENT } from '../../amplitude';

// queries
import GET_PAYMENT_METHODS from '../../graphql/query/user/userPaymentMethodLast4';

// mutations
import CREATE_SETUP_INTENT from '../../graphql/mutation/user/createSetupIntent';
import REMOVE_PAYMENT_METHOD from '../../graphql/mutation/user/removePaymentMethod';

// components
import MainViewContent from '../../component/App/MainViewContent';
import CheckoutForm from '../../component/Common/Stripe/CheckoutForm';
import SectionList from '../../component/Common/Layout/SectionList';
import CreditCardOption from '../../component/Common/Stripe/CreditCardOption';
import LoadingInline from '../../component/Common/Misc/LoadingInline';

class UserAccountView extends Component {
  constructor(props) {
    super(props);

    logPageView(VIEW.USER_ACCOUNT);

    this.state = {
      setupIntentToken: null,
      addingPaymentMethod: false,
      processingCard: false,
    };
  }

  cardAddedSuccess = () => {
    this.props.userPaymentMethodLast4.refetch();
    this.setState({
      setupIntentToken: null,
      addingPaymentMethod: false,
      processingCard: false,
    });
  }

  deletePaymentMethod = (last4) => {
    this.setState({
      processingCard: true,
    }, () => {
      this.props.removePaymentMethod({
        variables: { last4 },
        update: () => {
          this.props.userPaymentMethodLast4.refetch();
          this.setState({
            processingCard: false,
          });
          logEventWithProperties(EVENT.PAYMENT_METHOD_REMOVED);
        },
      }).catch(error => {
        console.log(error);
        this.setState({
          error,
          processingCard: false,
        });
      });
    });
  }

  wantsToAddPaymentMethod = () => {
    this.setState({
      addingPaymentMethod: true,
    }, () => {
      this.props.createSetupIntent({
        update: (cache, { data: { createSetupIntent }}) => {
          this.setState({
            setupIntentToken: createSetupIntent,
          });
        },
      }).catch(error => {
        console.log(error);
        this.setState({
          error,
        });
      });
    });
  }

  _renderAddPaymentMethod = () => {
    if (!this.state.addingPaymentMethod) {
      return (
        <button
          className="btn-outline"
          onClick={this.wantsToAddPaymentMethod}
        >
          Add New Card
        </button>
      );
    }

    return (
      <CheckoutForm
        setupIntentToken={this.state.setupIntentToken}
        onSetupComplete={this.cardAddedSuccess}
        onProcessing={() => this.setState({ processingCard: true })}
        onError={() => {
          this.setState({
            processingCard: false,
          });
        }}
      />
    )
  }

  _renderPaymentMethods = () => {
    let content;
    if (this.state.processingCard || this.props.userPaymentMethodLast4.loading) {
      content = (
        <LoadingInline />
      );
    } else if (this.props.userPaymentMethodLast4.userPaymentMethodLast4 &&
      this.props.userPaymentMethodLast4.userPaymentMethodLast4.length) {
      content = (
        <div className="padding-y-1">
          {this.props.userPaymentMethodLast4.userPaymentMethodLast4.map(last4 => (
            <CreditCardOption
              last4={last4}
              onRemove={() => this.deletePaymentMethod(last4)}
            />
          ))}
        </div>
      )
    } else {
      content = (
        <div className="padding-y-1">No payment methods added.</div>
      );
    }
    
    return (
      <div className="padding-y-1">
        <h2>Payment Methods</h2>
        <div className="section">
          <div className="wrap padding-y-1">
            {content}
            {this._renderAddPaymentMethod()}
          </div>
        </div>
      </div>
    );
  }

  _renderProfileInfo = () => {
    const items = [
      { name: 'Username', value: this.props.user.username },
      { name: 'Email', value: this.props.user.email },
    ];
    if (this.props.user.usac_id) {
      items.push({ name: 'USAC ID', value: this.props.user.usac_id });
    }

    return (
      <div className="padding-y-1">
        <h2>Profile</h2>
        <SectionList
          items={items}
        />
        <div className="small padding-y-1">
          <FontAwesomeIcon icon="cog" /> Manage or Edit your account from KAYA's mobile app.
        </div>
      </div>
    );
  }

  render() {
    return (
      <MainViewContent
        className="account-view"
        title="Account Overview"
        error={this.state.error}
        onCloseError={() => this.setState({ error: null })}
      >
        <div className="wrap">
          {this._renderProfileInfo()}
          {this._renderPaymentMethods()}
        </div>
      </MainViewContent>
    );
  }
}

// QUERIES
const withPaymentMethods = graphql(GET_PAYMENT_METHODS, {
  name: 'userPaymentMethodLast4',
  options: (props) => ({
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  }),
});

// MUTATIONS
const withCreateSetupIntent = graphql(CREATE_SETUP_INTENT, {
  name: 'createSetupIntent',
});

const withRemovePaymentMethod = graphql(REMOVE_PAYMENT_METHOD, {
  name: 'removePaymentMethod',
});

export default withRouter(compose(
  withPaymentMethods,
  withCreateSetupIntent,
  withRemovePaymentMethod,
)(UserAccountView));
