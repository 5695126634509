import gql from 'graphql-tag';
import POST_FRAGMENT from '../../fragment/webPost';

export default gql`

    query webPostsForGym($gym_id: ID!, $count: Int!, $offset: Int!) {
        webPostsForGym(gym_id: $gym_id, count: $count, offset: $offset) {
            ...WebPostFields
        }
    }

    ${POST_FRAGMENT}

`;
