// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router';
import { ApolloFetchPolicy } from '../../common/Definitions';
import { logPageView, logEventWithProperties, VIEW, EVENT } from '../../amplitude';
import { Breakpoints } from '../../common/LayoutStyle';

// queries
import GET_POST from '../../graphql/query/post/getWebPost';

// components
import LoadingView from '../../component/Common/Misc/LoadingView';
import {MainHeader} from "../../component/Common/Layout/MainHeader";
import {postItemImageUrl, postItemVideoThumbnailUrl, postItemVideoUrl, postItemClimb} from "../../common/PostItem";
import {getClimbName} from "../../common/Climb";
import ProfileImg from '../../component/User/ProfileImg';
class VideoEmbedView extends Component {
  constructor(props) {
    super(props);

    logPageView(VIEW.VIDEO_EMBED);

    this.state = {
      climbId: null,
      error: null,
      isMobile: window.innerWidth < Breakpoints.DESKTOP,
      checkMobileTimeout: null,
    };
  }

  componentDidMount() {
    if (this.props.shouldPlay) {
      this.playVideo();
    }
    window.addEventListener('resize', this.checkMobile);
    
    // HACK: Hide cookies modal as this is embedded on other websites
    setTimeout(() => {
      const privacyBanner = document.getElementById('onetrust-consent-sdk');
      if (privacyBanner && privacyBanner.style) {
        privacyBanner.style.opacity = 0;
        privacyBanner.style.display = 'none';
      }
    }, 200);
    
    // Second opacity Hack as a catch all 
    setTimeout(() => {
      const privacyBanner = document.getElementById('onetrust-consent-sdk');
      if (privacyBanner && privacyBanner.style) {
        privacyBanner.style.opacity = 0;
        privacyBanner.style.display = 'none';
      }
    }, 3000);
  }
  
  playVideo = () => {
    this.setState({
      videoIsPlaying: true,
    }, () => {
      this.refs.vidEmbedRef.play();
    });
  }
  
  formatUserProfileImg = (uri) => {
    if (!uri) {
      return;
    }

    const resizeString = 'https://plastick.rocks/cdn-cgi/image/width=80,quality=75/';

    return resizeString.concat(uri)
  }
  
  checkMobile = () => {
    // Throttle Set State calls on Resize 
    if (!this.state.checkMobileTimeout) {
      this.setState({
        checkMobileTimeout: setTimeout(() => {
          this.setState({
            isMobile: window.innerWidth < Breakpoints.DESKTOP,
            checkMobileTimeout: null,
          });
        }, 200),
      });
    }
  }

  _getPostItem = (post, postItemId) => {
    if (!post) {
      return null; 
    }

    const postItem = post.items && post.items.length > 0 && post.items.find(i => i.id === postItemId); 
    return postItem;
  }

  render() {
    const post = this.props.webPost.webPost;
    const postItemId = this.props.match.params.post_item_id; 
    const postItem = this._getPostItem(post, postItemId); 
    const climb = postItemClimb(postItem); 
    const user = post && post.user; 
    
    if (!post || post.items?.length === 0) {
      return (
        <div className="container video-embed-view">
          <LoadingView className="climb-view-loading"  />
      </div>
      )
    }
    
    return (
      <div className="container video-embed-view" >
        <div className="user-info-container">
          <div className="user-container">
            <div className="user-thumbnail-container">
              <ProfileImg 
                src={this.formatUserProfileImg(user.photo_url)}
                size={"user-thumbnail"}
                placeholderIconSize={"2x"}
              />
            </div>
            <div className="user-info">
              <p style={{ fontWeight: 'bold', paddingBottom: '6px' }}>{`@${user.username}`}</p>
              <p>{getClimbName(climb)}</p>
            </div>
          </div>
          <div className="view-climb-button">
            <a href={`https://kaya-app.kayaclimb.com/climb/${climb.slug}`} target="_blank" rel="noopener noreferrer">View Climb</a>
          </div>
        </div>

        {this.state.videoIsPlaying ? 
          <video 
            controls 
            className="video"
            ref="vidEmbedRef"
            controlsList="nodownload" // removes download option from controls 
            title={`Beta video for ${climb.name} ${climb.grade.name} in ${climb.destination && climb.destination.name} - KAYA | the climber's app`}
            onEnded={() => {this.setState({ videoIsPlaying: false })}}
            onPlay={() => {this.setState({ videoIsPlaying: true })}}
          >
            <source src={postItemVideoUrl(postItem)} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          :
          <div className="video-embed-thumbnail-container">
            <img 
              className="video-thumbnail-embed" 
              onClick={() => this.playVideo()}
              src={postItemVideoThumbnailUrl(postItem)}
              alt={`Climbing Beta video of climbers on ${climb.name} ${climb.grade.name} in ${climb.destination && climb.destination.name}.`} 
            />

            <div className="play-icon-embed-container">
              <FontAwesomeIcon
                icon={"play"}
                size="4x"
                className="play-icon-embed"
                onClick={() => this.playVideo()}
              />
            </div>
            
            <div className="kaya-logo" />
          </div>
        }
      </div>
    );
  }
}

// QUERIES
const withWebPost = graphql(GET_POST, {
  name: 'webPost',
  options: (props) => {
    return {
      variables: { 
        post_id: props.match.params.post_id,
      }, 
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

export default withRouter(compose(
  withWebPost,
)(VideoEmbedView));
