import gql from 'graphql-tag';
import WEB_USER_FRAGMENT from '../../fragment/webUser';

export default gql`

    query webSearchForUser($term: String!,$offset: Int!, $count: Int!) {
        webSearchForUser(term: $term, offset: $offset, count: $count) {
            ...WebUserFields
        }
    }

    ${WEB_USER_FRAGMENT}

`;
