// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';
import { Breakpoints } from '../../common/LayoutStyle';
import {ApolloFetchPolicy, ClimbType} from "../../common/Definitions";

// relative 
import {EVENT, logEventWithProperties} from "../../amplitude";
import {postItemClimb, postItemImageUrl, postItemVideoThumbnailUrl} from "../../common/PostItem";
import {getClimbName, getClimbLocationName} from "../../common/Climb";
import {getCdnUri} from "../../common/Media";
import {getClimbUrl, getPostUrl, getProfileUrl} from "../../common/Navigation";

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import GET_WEB_POSTS_FOR_USER from '../../graphql/query/user/webPostsForUser';

// components
import LoadingInline from "../../component/Common/Misc/LoadingInline";
import PostLightbox from "../../component/Common/Media/PostLightbox";
import ProfileImg
 from '../../component/User/ProfileImg';
import { isEmptyString } from '../../common/util';
const MOBILE_DISPLAY_COUNT = 2;
const DISPLAY_COUNT = 4;
const PAGE_COUNT = 12;
class UserPostsGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingMore: false,
      offset: 0,
      currentPageIdx: 0, 
      selectedPost: null,
    };
  }
  
  _fetchMore = () => {
    this.setState({
      fetchingMore: true,
      offset: this.state.offset + PAGE_COUNT,
    }, () => {
      this.props.webPostsForUser.fetchMore({
        variables: { offset: this.state.offset },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;

          const moreToLoad = fetchMoreResult.webPostsForUser.length > 0;

          this.setState({
            fetchingMore: false,
            moreToLoad,
          });

          const posts = [...prev.webPostsForUser, ...fetchMoreResult.webPostsForUser];

          // Append new data to old data
          return Object.assign({}, prev, {
            webPostsForUser: posts,
          });
        },
      }).catch(error => {
        console.log(error);
        this.setState({
          fetchingMore: false,
          error,
        });
      });
    });
  }
  
  _getDisplayCount = () => {
    const {
      isMobile,
    } = this.props;

    return isMobile ? MOBILE_DISPLAY_COUNT : DISPLAY_COUNT;
  }
  
  goToPage = (idx) => {
    this._fetchMore();

    this.setState({
      currentPageIdx: idx,
    });

    // Analytics
    logEventWithProperties(EVENT.USER_POST_LOAD_MORE);
  }
  
  renderEmpty = () => {
    return (
      <div className='list-empty'>
        <p>No beta videos exist for this user</p>
      </div>
    );
  }
  
  renderPost = (post, itemsInRow) => {
    const climb = postItemClimb(post.items[0]);

    // Leverage CDN to display smallest thumbnail as possible
    const imgWidth = 1600 / itemsInRow;

    return (
      <div
        key={post.id}
        className="post"
      >
        <div
          className="post-thumb"
          onClick={() => this.setState({ selectedPost: post })}
        >
          <img
            src={getCdnUri(postItemVideoThumbnailUrl(post.items[0]) || postItemImageUrl(post.items[0]), imgWidth)}
            alt={`Beta Video from ${post.user.username} for climb ${getClimbName(postItemClimb(post.items[0]), false, true, true)}`}
          />
          <a className="post-link" href={getPostUrl(climb.slug, post.id, post.items[0].id)} />
          <div className="overlay">
            <div />
            <div className='icon-play-circle-outlined' />
            <div 
              className="user"
              onClick={() => {
                if (post.user?.username && !isEmptyString(post.user?.username)) {
                  window.open(getProfileUrl(post.user?.username));
                }
            
                // Analytics
                logEventWithProperties(EVENT.POST_USER_CLICKED);
              }}
            >
              <ProfileImg
                src={getCdnUri(post.user.thumbnail_url || post.user.photo_url, 80)}
                size={"small"}
                placeholderIconSize={"2x"}
              />
              <div className="user-meta">
                <div className="name">
                  {post.user.fname} {post.user.lname}
                </div>
                <div className="username">
                  @{post.user.username}
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href={getClimbUrl(climb.slug)}
          target="_blank"
          className="climb-info"
        >
          <div className="climb-name">{getClimbName(climb, true)}</div>
          <div className="climb-area">{getClimbLocationName(climb)}</div>
        </a>
      </div>
    );
  }
  
  renderVideoGrid = () => {
    const displayCount = this._getDisplayCount();
    const rowsPerPage = 2;
    const posts = this.props.webPostsForUser.webPostsForUser;
    const totalCount = (this.state.currentPageIdx * displayCount * rowsPerPage) + (displayCount * rowsPerPage);
    const postsToDisplay = posts.slice(0, totalCount);
    const formattedData = [];
    postsToDisplay.forEach(p => {
      formattedData.push({
        id: p.id,
        post: p,
      });
    });
    const isLoadMoreInactive = formattedData.length < totalCount;

    const rowsMarkup = [];
    for (let rowIdx = 0; rowIdx < Math.ceil(formattedData.length / displayCount); rowIdx++) {
      const items = formattedData.slice(rowIdx * displayCount, (rowIdx * displayCount) + displayCount);
      const placeholderCount = displayCount - items.length;
      let placeholders = []; 
      if (placeholderCount > 0) {
        for (let i = 0; i < placeholderCount; i++) {
          placeholders.push(i); 
        }
      }
      
      const rowMarkup = (
        <div
          className="row"
          key={rowIdx}
        >
          {
            items.map(item => {
              return this.renderPost(item.post, 4);
            })
          }
          { 
            placeholders.map(p => {
              return <div className='placeholder'/>;
            })
          }
        </div>
      );
      rowsMarkup.push(rowMarkup);
    }

    return (
      <div className="post-carousel">
        <div className="wrap">
          {rowsMarkup.length === 0 && this.props.webPostsForUser?.loading &&
            <div style={{ height: '750px', textAlign: 'center', marginTop: '200px' }}>
              <LoadingInline />
            </div>
          }
          {rowsMarkup}
          { !isLoadMoreInactive &&
            <div className="load-more-container">
              <div
                className={'load-more-cta'}
                onClick={() => this.goToPage(this.state.currentPageIdx + 1)}
              >
                LOAD MORE
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
  
  render() {
    const {
      user,
      climbTypeId,
    } = this.props;
    
    if (this.props.webPostsForUser.loading || !this.props.webPostsForUser.webPostsForUser) {
      return <LoadingInline className="user-post-gallery-loading"/>
    }

    return (
      <div className={classnames('user-posts-gallery', this.props.isMobile && 'mobile')}>
        { this.state.selectedPost &&
          <PostLightbox
            post={this.state.selectedPost}
            postItem={this.state.selectedPostItem}
            onClose={() => this.setState({ selectedPost: null, selectedPostItem: null })}
            origin={'location'}
          />
        }
        <h2 className='title wrap'>Beta Videos</h2>
        { this.props.webPostsForUser.webPostsForUser.length === 0 && this.renderEmpty()}
        { this.renderVideoGrid() }
      </div>
    )
  }
}

// QUERIES
const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

const withWebPostsForUser = graphql(GET_WEB_POSTS_FOR_USER, {
  name: 'webPostsForUser',
  options: (props) => {
    return {
      variables: {
        user_id: props.user?.id,
        offset: 0,
        count: PAGE_COUNT,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

export default compose(
  withWebPostsForUser,
  withGrades,
)(UserPostsGallery);
  