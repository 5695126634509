import gql from 'graphql-tag';
import LOCATION_FRAGMENT from '../../fragment/webLocation';

export default gql`

    query webLocation($slug: String!) {
        webLocation(slug: $slug) {
            ...WebLocationFields
        }
    }

    ${LOCATION_FRAGMENT}

`;
