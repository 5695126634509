// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';
import {ApolloFetchPolicy, LocationType} from '../../common/Definitions';
import { getLocationUrl } from '../../common/Navigation';
import {EVENT, PROP, logEventWithProperties} from "../../amplitude";

// queries
import SEARCH_FOR_LOCATION from '../../graphql/query/location/webSearchForLocation';

class GBModeratedDestinations extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  _renderSpacers = (spacerNum) => {
    const spacers= [];
    for (let i = 0; i < spacerNum; i++) {
      spacers.push(<div className='area-link' />);
    }
    return spacers;
  }

  render() {
    const locations = this.props.webSearchForLocation.webSearchForLocation;

    if (!locations) {
      return <div />
    }
    const columnsCount = 3;
    const itemsPerColumn = locations.length / columnsCount;
    const columns = [];
    for (let i = 0; i < columnsCount; i++) {
      const locationsForColumn = locations.slice(i * itemsPerColumn, (i * itemsPerColumn) + itemsPerColumn);
      columns.push(
        <div className='gb-destinations-column'>
          { locationsForColumn.map(location => {
              return (
                <a
                  className="area-link"
                  href={getLocationUrl(location.slug)}
                  target="_blank"
                  onClick={() => {
                    // Analytics
                    logEventWithProperties(EVENT.LOCATION_OFFICIAL_CLICKED, {
                      [PROP.LOCATION]: location.name,
                    });}
                  }
                >
                  <div>{location.name}</div>
                </a>
              );
            })
          }
        </div>
      )
    }

    return (
      <div className={classnames('gb-destinations-list', this.props.isMobile && 'mobile')}>
        <div className="map-embed wrap">
          <h2>Official Destinations in KAYA</h2>
          <iframe width="100%" height="576" src="https://maphub.net/embed_h/3rzlvQ6ZKL8Fh50f?geolocation=1&autoplay=1"
                  frameBorder="0" allow="geolocation"></iframe>
        </div>
        <div className='gb-destinations-links wrap'>
          {columns}
        </div>
      </div>
    );
  }
}

// QUERIES
const withLocationsForLocation = graphql(SEARCH_FOR_LOCATION, {
  name: 'webSearchForLocation',
  options: (props) => {
    return {
      variables: {
        name: '',
        location_type_id: LocationType.DESTINATION,
        is_gb_moderated_only: true,
        offset: 0,
        count: 100, // Should capture all areas for most locations
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

export default compose(
  withLocationsForLocation,
)(GBModeratedDestinations);
