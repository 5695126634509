import React from 'react';

export default function () {
  return (
    <footer id="footer">
      <div id="footer-inner" className="wrap cf">
        <div className="m-all t-1of3 d-1of3">
          <a href="https://kayaclimb.com/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <a href="https://kayaclimb.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
          <a href="https://kayaclimb.com/community-standards" target="_blank" rel="noopener noreferrer">Community Standards</a>
        </div>
        <div className="m-all t-1of3 d-1of3">
          <a href="https://kayaclimb.com/support" target="_blank" rel="noopener noreferrer">Support</a>
          <a href="https://kayaclimb.com/about-us" target="_blank" rel="noopener noreferrer">About</a>
          <a href="https://kayaclimb.com/careers-1" target="_blank" rel="noopener noreferrer">Careers</a>
        </div>
        <div className="m-all t-1of3 d-1of3">
          <a href="https://kayaclimb.com/contact" target="_blank" rel="noopener noreferrer">Contact</a>
          <a href="https://kayaclimb.com/usacpartner" target="_blank" rel="noopener noreferrer">USA Climbing Events</a>
          <a href="https://www.instagram.com/kayaclimbing" target="_blank" rel="noopener noreferrer">Instagram</a>
        </div>
      </div>
      <div className="center small padding-y-1">
        © {(new Date()).getFullYear()} Project 9a Labs, Inc.
      </div>
    </footer>
  );
}
