import axios from 'axios';
import aws from 'aws-sdk';
import { getAuthToken } from '../common/Auth';
import config from '../config';

export const action = (type, actionParams = {}) => {
  return Object.assign(actionParams, { type });
};

export function axiosReq() {
  const jwt = getAuthToken();
  let headers = {};
  if (jwt) {
    headers = {
      Authorization: `Bearer ${jwt}`,
    };
  }
  if (config.type === 'test') {
    return axios.create({
      headers,
    });
  }

  return axios.create({
    headers,
    baseURL: config.uri,
  });
}

export function dispatchBasicReq(reqPromise, loadingAction, successAction, failureAction) {
  return async dispatch => {
    dispatch(loadingAction());
    try {
      const { data: { data, error } } = await reqPromise;
      if (error) {
        dispatch(failureAction(error));
      } else {
        dispatch(successAction(data));
      }
    } catch (error) {
      dispatch(failureAction(error));
    }
  };
}

export async function uploadfileAWSS3(credentials, region, bucket, tempFolder, filename, file, progressCallback) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = async () => {
      aws.config.update({
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken,
        region,
      });

      const Key = `${tempFolder}/${filename}`;
      const s3 = new aws.S3();

      const params = {
        ACL: 'public-read',
        Body: fr.result,
        Bucket: bucket,
        ContentType: file.type,
        Key,
      };
      s3.putObject(params).on('httpUploadProgress', (progress) => {
        progressCallback(progress);
      }).send((err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(Key);
        }
      });
    };
    fr.readAsArrayBuffer(file);
  });
}

export async function transcodeAWS(credentials, endpoint, role, jobTemplate, bucket, region, inputKey) {
  return axiosReq().post('/api/transcodeAWS', {
    credentials,
    endpoint,
    role,
    jobTemplate,
    bucket,
    region,
    inputKey,
  }).then(({ data }) => {
    return data;
  });
}

export async function getTranscodeJobAWS(credentials, endpoint, region, Id) {
  return axiosReq().post('/api/getTranscodeJobAWS', {
    credentials,
    endpoint,
    region,
    Id,
  }).then(({ data }) => {
    return data;
  });
}
