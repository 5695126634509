import config from '../config';

export const randomId = () => {
  return (new Date()).getTime() * -1;
};

export const isMobileDevice = () => {
  return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

export const abbreviateFirstandLast = (text = '') => {
  const split = text.split(' ');

  if (text.length === 1) {
    return text.toUpperCase();
  } else if (split.length > 1 && split[1].length) {
    return `${split[0][0].toUpperCase()}${split[1][0].toUpperCase()}`;
  } else if (text.length > 1) {
    return `${text[0].toUpperCase()}${text[text.length - 1].toUpperCase()}`;
  }
  return '';
};

export function reduceToHash(values = [], key = 'id') {
  return values.reduce((prev, curr) => { prev[`${curr[key]}`] = curr; return prev; }, {});
}

export function addToHash(key, value, hash = {}) {
  const dupeHash = Object.assign({}, hash);
  dupeHash[`${key}`] = value;
  return dupeHash;
}

export function removeFromHash(key, hash = {}) {
  const dupeHash = Object.assign({}, hash);
  delete dupeHash[`${key}`];
  return dupeHash;
}

export function stringToHash(str = '') {
  var hash = 0, i, chr;
  for (i = 0; i < str.length; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isEmptyString(str) {
  if (!str || str === undefined || str === null || str === '') return true;
  return false;
}