export const Breakpoints = {
  MOBILE: 0,
  TABLET: 600,
  DESKTOP: 770,
};

export const ChartHeights = {
  LARGE: 150,
  MEDIUM: 75,
};
