import gql from 'graphql-tag';

export default gql`

fragment GradeFields on Grade {
    id
    name
    climb_type_id
    grade_type_id
    ordering
    mapped_grade_ids
    climb_type_group
}

`;
