import gql from 'graphql-tag';
import LINK_FRAGMENT from '../../fragment/climbMediaLink';

export default gql`

    query webClimbMediaLinksForLocation($location_id: ID!, $offset: Int!, $count: Int!) {
        webClimbMediaLinksForLocation(location_id: $location_id, offset: $offset, count: $count) {
            ...WebClimbMediaLinkFields
        }
    }

    ${LINK_FRAGMENT}

`;
