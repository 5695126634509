import gql from 'graphql-tag';
import WEB_CLIMB_FIELDS from './webClimbBasic';
import WEB_USER_FIELDS from './webUser';
import GRADE_FIELDS from './grade';

export default gql`

    fragment WebAscentFields on WebAscent {
        id
        user {
            ...WebUserFields
        }
        climb {
            ...WebClimbBasicFields
        }
        date
        comment
        rating
        stiffness
        grade {
            ...GradeFields
        }
        photo {
            photo_url
            thumb_url
        }
        video {
            video_url
            thumb_url
        }
    }

  ${WEB_USER_FIELDS}
  ${WEB_CLIMB_FIELDS}
  ${GRADE_FIELDS}
`;
