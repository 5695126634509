// react
import * as React from 'react';
import classnames from 'classnames';
import { Oval } from 'svg-loaders-react'

export default function LoadingInline({ className }) {
  return (
    <div className={classnames('loading-inline', className)}>
      <Oval />
    </div>
  );
}
