import gql from 'graphql-tag';
import CHALLENGE_FRAGMENT from '../../fragment/challenge';

export default gql`

mutation confirmPaymentIntentForChallenge($challenge_id: ID!, $payment_intent_id: ID!) {
  confirmPaymentIntentForChallenge(challenge_id: $challenge_id, payment_intent_id: $payment_intent_id) {
    ...ChallengeFields
  }
}

${CHALLENGE_FRAGMENT}
`;
