export const ASSETS_BASE_URL = 'https://assets.plastick.rocks';

export const ClimbType = {
  BOULDERING: '1',
  ROUTES: '2',
};

export const LocationType = {
  COUNTRY: '1',
  DESTINATION: '2',
  AREA: '3',
  SUBAREA: '4',
  BOARD: '5',
  BOARD_BRAND: '6',
  WALLAREA: '7',
  DYNAMIC: '8',
}

export const ApolloFetchPolicy = {
  CACHE_FIRST: 'cache-first',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  CACHE_ONLY: 'cache-only',
  NO_CACHE: 'no-cache',
};

export const LocalStorageKey = {
  TOKEN: 'token',
  REFRESH_TOKEN: 'refresh_token',
  USER_ID: 'id',
  USER_SLUG: 'slug',
  UPDATE_VERSION: 'update-version',
  HIDDEN_NOTIFICATIONS: 'hidden-notifications',
  USER_UI_CONFIG: 'user-ui-config',
  LAST_REFRESHED_VERSION: 'last-refreshed-version',
};

export const MediaLinkType = {
  YOUTUBE: '1',
  VIMEO: '2',
  INSTAGRAM: '3',
}

export const MonthIdMap = {
  1: 'Jan',
  2: 'Feb', 
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov', 
  12: 'Dec', 
}

export const UnitType = {
  IMPERIAL: '1',
  METRIC: '2',
};

export const AscentSortType = {
  DATE: 'DATE',
  GRADE: 'GRADE',
  RATING: 'RATING',
};

export const FilterAscentLocations = {
  OUTDOOR: 'OUTDOOR',
  GYM: 'GYM',
  BOARD: 'BOARD',
  PRIVATE: 'PRIVATE',
  ALL: 'ALL',
};