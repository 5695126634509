import gql from 'graphql-tag';
import CLIMB_FIELDS from './webClimbBasic';
import ASCENT_FIELDS from './webAscent';
import USER_FIELDS from './webUser';

export default gql`

    fragment WebPostFields on WebPost {
        id
        items {
            id
            post_id
            photo_url
            video_url
            climb {
                ...WebClimbBasicFields
            }
            ascent {
                ...WebAscentFields
            }
            video_thumbnail_url
            caption
        }
        user {
            ...WebUserFields
        }
        date_created
    }

    ${CLIMB_FIELDS}
    ${ASCENT_FIELDS}
    ${USER_FIELDS}
`;
