import gql from 'graphql-tag';
import CHALLENGE_BASIC_FRAGMENT from './challengeBasic';

export default gql`

fragment ReceiptFields on Receipt {
    id
    description
    charge_amount
    charge_currency
    status
    date
    challenge {
      ...ChallengeBasicFields
    }
}

${CHALLENGE_BASIC_FRAGMENT}

`;
