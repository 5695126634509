import gql from 'graphql-tag';
import CHALLENGE_FRAGMENT from '../../fragment/challenge';

export default gql`

query challenge($id: ID!) {
  challenge(id: $id) {
    ...ChallengeFields
  }
}

${CHALLENGE_FRAGMENT}
`;
