import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DownloadBadges({ isMobile }) {
  return (
    <div className={`download-badge-container ${isMobile ? "mobile" : "desktop"}`}>
      <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.project9a.redpoint&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img className="download-badge" alt='Get it on Google Play' src={require('../../lib/img/google-play-badge.png')}/>
      </a>
      <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/kaya-the-climbers-app/id1481413749?itsct=apps_box_promote_link&itscg=30200">
        <img className="download-badge" alt='Download on the App Store' src={require('../../lib/img/apple-app-store-badge.svg')}/>
      </a>
    </div>
  );
}