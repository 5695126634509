import React from 'react';
import { Spring } from 'react-spring';

export default function CssTransition({ from = {}, to = {}, flip, children }) {
  return (
    <Spring from={flip ? to : from} to={flip ? from : to}>
      {styles => React.Children.map(children, child => React.cloneElement(child, { style: { ...styles } }))}
    </Spring>
  );
}
