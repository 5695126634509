// react
import * as React from 'react';
import { Puff } from 'svg-loaders-react'
import classnames from 'classnames';

export default function Loading({ className }) {
  return (
    <div className={classnames('loading', className)}>
      <Puff />
    </div>
  );
}
