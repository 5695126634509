import React from 'react';

export default function (props) {
  return (
    <header id="header">
      <div id="header-inner" className="wrap">
        <div className="title">
          <h1>{props.title}</h1>
        </div>
        <span className="subtitle">{props.subTitle}</span>
        <div className="buttons">
          {props.buttons}
        </div>
      </div>
    </header>
  );
}
