import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmptyString } from '../../common/util';

export default class RecommendedClimbListItem extends Component {
  constructor(props) {
    super(props);
  }

  _getThumbUrl = (climb) => {
    if (climb.top_videos && climb.top_videos.length > 0) {
      return climb.top_videos[0].thumb_url;
    } else if (climb.media_links && climb.media_links.length > 0) {
      return climb.media_links[0].thumb_url; 
    }
    
    return null; // Background image CSS style will show if URL does not exist 
  }

  _getClimbUrl = (slug) => {
    const path = `/climb/${slug}`;
    
    return `https://kaya-app.kayaclimb.com${path}`;
  }
  
  render() {
    const {
        climb, 
        isMobile,
    } = this.props;

    return (
      <div 
        className="recommended-climb-container"
      >
        <div 
          className="recommended-climb"
          onClick={() => climb && !isEmptyString(climb.slug) && window.open(this._getClimbUrl(climb.slug))}
        >
            <div className="recommended-climb-thumbnail-container" >
              <img 
                src={this._getThumbUrl(climb)} 
                className="recommended-climb-thumbnail"
                
              />
            </div>
            
            <a 
              className={`recommended-climb-title ${isMobile ? "mobile" : "web"}`}
              href={climb && !isEmptyString(climb.slug) && this._getClimbUrl(climb.slug)}
              target="_blank" 
              rel="noopener noreferrer"
            >
              {`${climb.name}, ${climb.grade && climb.grade.name}`}
            </a>
            
            <div className={`recommended-climb-meta ${isMobile ? "mobile" : "web"}`}> 
              <FontAwesomeIcon icon={"star"} className="recommended-climb-meta-star-icon" />
              <p>{`${climb? (Math.round(climb.rating * 10) / 10).toFixed(1) : `N/A`},  `}</p>
              <p>{`${climb.ascent_count || 0} ascents`}</p>
            </div>
        </div>
      </div>
    )	
  }
}