// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';

// queries
import GET_VIDEOS from "../../graphql/query/climb/webPostsForClimb";
import GET_MEDIALINKS from "../../graphql/query/climb/webMediaLinksForClimb";

// relative
import {ApolloFetchPolicy} from "../../common/Definitions";
import {postItemClimb, postItemImageUrl, postItemVideoThumbnailUrl} from "../../common/PostItem";
import {getClimbLocationName, getClimbName} from "../../common/Climb";
import {getClimbUrl, getPostUrl, getProfileUrl} from "../../common/Navigation";
import config from '../../config';

// comopnents
import PostLightbox from "../../component/Common/Media/PostLightbox";
import ClimbRating from "../../component/Climb/ClimbRating";
import ProfileImg from "../../component/User/ProfileImg";
import GET_POST from "../../graphql/query/post/getWebPost";
import {EVENT, logEventWithProperties, PROP} from "../../amplitude";
import MediaLinkLightbox from "../../component/Common/Media/MediaLinkLightbox";
import {getCdnUri} from "../../common/Media";
import LoadingInline from "../../component/Common/Misc/LoadingInline";

const MOBILE_DISPLAY_COUNT = 2;
const DISPLAY_COUNT = 4;
const PAGE_COUNT = 12;

class ClimbHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPageIdx: 0,
      offset: 0,
      selectedPost: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.webPost && prevProps.webPost) {
      if (!this.state.selectedPost && this.props.webPost.webPost) {
        const hash = window.location.hash?.split('-') || [];
        const postItemId = hash[1];
        this.setState({
          selectedPost: this.props.webPost.webPost,
          selectedPostItem: this.props.webPost.webPost.items.find(pi => pi.id === postItemId),
        });
      }
    }
  }

  goToPage = (idx) => {
    this._fetchMore();

    this.setState({
      currentPageIdx: idx,
    });

    // Analytics
    logEventWithProperties(EVENT.CLIMB_POST_LOAD_MORE);
  }

  _fetchMore = () => {
    this.setState({
      fetchingMore: true,
      offset: this.state.offset + PAGE_COUNT,
    }, () => {
      this.props.webPostsForClimb.fetchMore({
        variables: { offset: this.state.offset },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;

          const moreToLoad = fetchMoreResult.webPostsForClimb.length > 0;

          this.setState({
            fetchingMore: false,
            moreToLoad,
          });

          const webPostsForClimb = [...prev.webPostsForClimb, ...fetchMoreResult.webPostsForClimb];

          // Append new data to old data
          return Object.assign({}, prev, {
            webPostsForClimb,
          });
        },
      }).catch(error => {
        console.log(error);
        this.setState({
          fetchingMore: false,
          error,
        });
      });

      this.props.webClimbMediaLinksForClimb.fetchMore({
        variables: { offset: this.state.offset },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;

          const moreMediaLinksToLoad = fetchMoreResult.webClimbMediaLinksForClimb.length > 0;

          this.setState({
            moreMediaLinksToLoad,
          });

          const webClimbMediaLinksForClimb = [...prev.webClimbMediaLinksForClimb, ...fetchMoreResult.webClimbMediaLinksForClimb];

          // Append new data to old data
          return Object.assign({}, prev, {
            webClimbMediaLinksForClimb,
          });
        },
      }).catch(error => {
        console.log(error);
        this.setState({
          error,
        });
      });
    });
  }
  
  _getPosts = () => {
    const data = this.props.webPostsForClimb.webPostsForClimb;
    return data || [];
  }

  _getMediaLinks = () => {
    const data = this.props.webClimbMediaLinksForClimb.webClimbMediaLinksForClimb;
    return data || [];
  }

  _getDisplayCount = () => {
    const {
      isMobile,
    } = this.props;

    return isMobile ? MOBILE_DISPLAY_COUNT : DISPLAY_COUNT;
  }

  renderMediaLink = (mediaLink) => {
    const climb = mediaLink.climb;
    return (
      <div
        key={mediaLink.id}
        className="post"
        onClick={() => this.setState({ selectedMediaLink: mediaLink })}
      >
        <img
          src={mediaLink.thumbnail_url}
          alt={`Beta Video for climb ${getClimbName(climb, false, true, true)}`}
        />
        <div className="overlay">
          <div />
          <div className='icon-play-circle-outlined' />
          <div className="user">
          </div>
        </div>
      </div>
    );
  }

  renderPost = (post, postItem, itemsInRow) => {
    const climb = postItemClimb(post.items[0]);

    // Leverage CDN to display smallest thumbnail as possible
    const imgWidth = 1600 / itemsInRow;

    return (
      <div
        key={post.id}
        className="post"
        onClick={() => this.setState({ selectedPost: post, selectedPostItem: postItem })}
      >
        <a className="post-link" href={getPostUrl(climb.slug, post.id, postItem.id)} />
        <img
          src={getCdnUri(postItemVideoThumbnailUrl(postItem) || postItemImageUrl(postItem), imgWidth)}
          alt={`Beta Video from ${post.user.username} for climb ${getClimbName(climb, false, true, true)}`}
        />
        <div className="overlay">
          <div />
          <div className='icon-play-circle-outlined' />
          <a 
            className="user"
            target="_top"
            href={getProfileUrl(post.user.username)}
            onClick={() => {
              // Analytics
              logEventWithProperties(EVENT.USER_PROFILE_CLICK);
            }}
          >
            <ProfileImg
              src={getCdnUri(post.user.thumbnail_url || post.user.photo_url, 80)}
              size={"small"}
              placeholderIconSize={"2x"}
            />
            <div className="user-meta">
              <div className="name">
                {post.user.fname} {post.user.lname}
              </div>
              <div className="username">
                @{post.user.username}
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }

  renderVideoCarousel = (climb) => {
    const displayCount = this._getDisplayCount();
    const rowsPerPage = 2;
    const posts = this._getPosts();
    const mediaLinks = this._getMediaLinks();
    const totalCount = (this.state.currentPageIdx * displayCount * rowsPerPage) + (displayCount * rowsPerPage);
    const postsToDisplay = posts.slice(0, totalCount);
    const mediaLinksToDisplay = totalCount > postsToDisplay.length ? mediaLinks.slice(0, totalCount - postsToDisplay.length) : [];
    const formattedData = [];
    postsToDisplay.forEach(p => {
      formattedData.push({
        id: p.id,
        post: p,
        mediaLink: null,
      });
    });
    mediaLinksToDisplay.forEach(m => {
      formattedData.push({
        id: m.id,
        post: null,
        mediaLink: m,
      });
    });
    const isLoadMoreInactive = formattedData.length < totalCount;

    const rowsMarkup = [];
    for (let rowIdx = 0; rowIdx < Math.ceil(formattedData.length / displayCount); rowIdx++) {
      const items = formattedData.slice(rowIdx * displayCount, (rowIdx * displayCount) + displayCount);
      const placeholderCount = displayCount - items.length;
      let placeholders = []; 
      if (placeholderCount > 0) {
        for (let i = 0; i < placeholderCount; i++) {
          placeholders.push(i); 
        }
      }
      
      const rowMarkup = (
        <div
          className="row"
          key={rowIdx}
        >
          {
            items.map(item => {
              if (item.post) {
                const postItem = item.post.items.find(pi => postItemClimb(pi)?.name === climb.name);
                return this.renderPost(item.post, postItem, items.length);
              }
              return this.renderMediaLink(item.mediaLink)
            })
          }
          { 
            placeholders.map(p => {
              return <div className='placeholder'/>;
            })
          }
        </div>
      );
      rowsMarkup.push(rowMarkup);
    }

    return (
      <div className="post-carousel">
        <div className="wrap">
          {rowsMarkup.length === 0 && (this.props.webPostsForClimb?.loading) &&
            <div style={{ height: '500px', textAlign: 'center', marginTop: '200px' }}>
              <LoadingInline />
            </div>
          }
          {rowsMarkup}
          { !isLoadMoreInactive &&
            <div className="load-more-container">
              <div
                className={'load-more-cta'}
                onClick={() => this.goToPage(this.state.currentPageIdx + 1)}
              >
                LOAD MORE
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  renderRating = (climb, dark) => {
    let rating = 'N/A';
    if (climb.rating) {
      rating = parseFloat(climb.rating).toFixed(1);
    }
    return (
      <span className="rating group-title">
        <ClimbRating
          dark={dark}
          rating={rating}
          ascentCount={climb.ascent_count}
        />
      </span>
    );
  }

  renderShare = (climb) => {
    return (
      <div
        className="share"
        onClick={() => {
          navigator.clipboard.writeText(`${config.uri}${getClimbUrl(climb.slug)}`);
          this.setState({
            hasShared: true,
          }, () => {
            setTimeout(() => {
              this.setState({
                hasShared: false,
              });
            }, 5000);
          })

          // Analytics
          logEventWithProperties(EVENT.CLIMB_PAGE_SHARED, {
            [PROP.NAME]: getClimbName(climb, true, true),
          });
        }}
      >
        <div className="icon-send-outlined" />
        { !this.state.hasShared && 'Share' }
        { this.state.hasShared &&
          <div className="copied">
            Copied
          </div>
        }
      </div>
    )
  }

  renderMeta = (climb, singlePost) => {
    return (
      <div className="climb-header-meta">
        <div className="title">
          <h1>{getClimbName(climb, true)}</h1>
          {climb.is_gb_moderated &&
            <img
              src={require('../../lib/img/verified-icon.png')}
              className="verified"
            />
          }
        </div>
        <div className="meta">
          <div className="left">
            <div className="group">
              {this.renderRating(climb, singlePost)}
            </div>
            {climb.fa &&
              <div className="group">
                <span className="group-title">FA:</span> ${climb.fa}
              </div>
            }
            {climb.date_updated &&
              <div className="group">
                <span className="group-title">Last Updated:</span> ${climb.date_updated}
              </div>
            }
            <div className="group">
              { this.renderShare(climb) }
            </div>
          </div>
        </div>
        { climb.description &&
          <div className="description-copyright">
            {climb.description}
          </div>
        }
        { singlePost &&
          <div className="cta">
            <div>
              Get beta, approach information, detailed descriptions, and GPS maps for {getClimbName(climb, true)} only on KAYA.
            </div>
            <a
              href="https://kayaclimb.com/download"
              className="btn-round-green"
              target="_blank"
            >
              Download KAYA
            </a>
          </div>
        }
      </div>
    );
  }

  renderMultiPostHeader = (climb) => {
    return (
      <div className="multi-post-banner">
        <div className="wrap">
          {this.renderMeta(climb)}
        </div>
        {this.renderVideoCarousel(climb)}
      </div>
    )
  }

  renderSinglePostHeader = (climb) => {
    let backgroundImageUri = require('../../lib/img/empty_climb_bg.png');
    if (climb.top_videos?.length) {
      backgroundImageUri = climb.top_videos[0].thumb_url;
    }
    const postsData = this._getPosts();

    return (
      <div className="single-post-banner">
        <img
          src={backgroundImageUri}
          alt={`Beta for Climb ${getClimbName(climb, false, true, true)}`}
        />
        <div className="overlay">
          <div className="wrap">
            {this.renderMeta(climb, true)}
            {postsData.length > 0 &&
              <div
                className="watch-post-cta"
                onClick={() => this.setState({ selectedPost: postsData[0] })}
              >
                <div>WATCH BETA VIDEO</div>
                <div
                  className='icon-play-circle'
                />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      climb,
    } = this.props;

    return (
      <div className="climb-header" >
        {this.renderMultiPostHeader(climb)}

        {this.state.selectedPost &&
          <PostLightbox
            post={this.state.selectedPost}
            postItem={this.state.selectedPostItem}
            onClose={() => this.setState({ selectedPost: null, selectedPostItem: null })}
            origin={'climb'}
          />
        }

        {this.state.selectedMediaLink &&
          <MediaLinkLightbox
            mediaLink={this.state.selectedMediaLink}
            onClose={() => this.setState({ selectedMediaLink: null })}
            origin={'location'}
          />
        }
      </div>
    );
  }
}

function getPostVariables() {
  const hash = window.location.hash;
  if (hash) {
    const hashSplit = hash.replace('#', '').split('-');
    if (hashSplit.length === 2) {
      const post_id = hashSplit[0];
      const post_item_id = hashSplit[1];
      return {
        post_id,
        post_item_id,
      };
    }
  }
  return null
}

// QUERIES
const withVideos = graphql(GET_VIDEOS, {
  name: 'webPostsForClimb',
  options: (props) => {
    return {
      variables: {
        climb_id: props.climb.id,
        offset: 0,
        count: PAGE_COUNT,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

const withMediaLinks = graphql(GET_MEDIALINKS, {
  name: 'webClimbMediaLinksForClimb',
  options: (props) => {
    return {
      variables: {
        climb_id: props.climb.id,
        offset: 0,
        count: PAGE_COUNT,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

const withWebPost = graphql(GET_POST, {
  name: 'webPost',
  options: (props) => {
    const variables = getPostVariables();
    return {
      variables,
    };
  },
  skip: (props) => !getPostVariables(),
});

export default compose(
  withVideos,
  withMediaLinks,
  withWebPost,
)(ClimbHeader);
