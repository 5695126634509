// react
import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router';
import { ApolloFetchPolicy, ClimbType, FilterAscentLocations } from '../../common/Definitions';
import {logPageView, logEventWithProperties, VIEW, EVENT, PROP} from '../../amplitude';
import { Breakpoints } from '../../common/LayoutStyle';
import { isSafari } from '../../common/util';
import classnames from 'classnames';

// relative 
import {getClimbLocationName, getClimbName} from "../../common/Climb";

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import GET_WEB_USER from '../../graphql/query/user/webUser';
import GET_FILTER_DISTRIBUTION_FOR_ASCENTS from '../../graphql/query/user/webFilterDistributionForAscents';

// components
import LoadingView from '../../component/Common/Misc/LoadingView';
import Footer from '../../component/App/Footer';
import {MainHeader} from "../../component/Common/Layout/MainHeader";
import GBModeratedDestinations from "../../container/Location/GBModeratedDestinations";
import UserAscentsPyramid from "../../container/User/UserAscentsPyramid";
import webUser from '../../graphql/fragment/webUser';
import UserProfileHeader from '../../component/User/UserProfileHeader';
import UserPostsGallery from '../../container/User/UserPostsGallery';
import UserAscentsList from '../../container/User/UserAscentsList';
import LocationClimbSearch from '../../container/Location/LocationClimbSearch';
import GlobalAutocompleteSearch from '../../container/Common/GlobalAutocompleteSearch';
import LocationAdCard from '../../component/Location/LocationAdCard';
import { getProfileUrl } from '../../common/Navigation';
import { filter } from 'lodash';

class ProfileView extends Component {
  constructor(props) {
    super(props);      

    this.isLoaded = false;

    this.state = {
      error: null,
      isMobile: window.innerWidth < Breakpoints.DESKTOP,
      checkMobileTimeout: null,
      boulderingData: [],
      routesData: [],
      climb_type_id: ClimbType.BOULDERING, 
      min_grade_id: null,
      max_grade_id: null,
      filter_by: FilterAscentLocations.OUTDOOR,
      sort_by: null,
      isInitialized: false, 
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkMobile);
  }
  
  componentDidUpdate(prev) {
    if (this.props.webUser && this.props.webUser.webUser && !this.state.isInitialized) {
      this.fetchAscentDistributionData();
      this.setState({ isInitialized: true }); 
    }    
  }
  
  checkMobile = () => {
    // Throttle Set State calls on Resize 
    if (!this.state.checkMobileTimeout) {
      this.setState({
        checkMobileTimeout: setTimeout(() => {
          this.setState({
            isMobile: window.innerWidth < Breakpoints.DESKTOP,
            checkMobileTimeout: null,
          });
        }, 200),
      });
    }
  }
  
  fetchAscentDistributionData = () => {
    const user = this.props.webUser.webUser;
    if (!user || user?.is_private) {
      return; 
    }
    
    this.setState({
      isLoading: true,
    }, async () => {      
      const boulderingData = await this.props.client.query({
        query: GET_FILTER_DISTRIBUTION_FOR_ASCENTS,
        variables: {
          user_id: user.id,
          climb_type_id: ClimbType.BOULDERING,
          filter_by: FilterAscentLocations.ALL,
        },
      });
      
      const routesData  = await this.props.client.query({
        query: GET_FILTER_DISTRIBUTION_FOR_ASCENTS,
        variables: {
          user_id: user.id,
          climb_type_id: ClimbType.ROUTES,
          filter_by: FilterAscentLocations.ALL,
        },
      });

      this.setState({
        boulderingData: boulderingData?.data?.webFilterDistributionForAscents?.data || [],
        routesData: routesData?.data?.webFilterDistributionForAscents?.data || [],
        isLoading: false,
      });
    });
  }
  
  _getPageTitle = (user) => {
    if (!user) {
      return;
    }
    return `${user.fname} ${user.lname} - @${user.username} - KAYA | The Climber's App`;
  }
  
  _getPageDescription = (user) => {
    if (!user) {
      return
    }

    return `@${user.username} on KAYA. See ascents, beta videos, and more from ${user.fname} ${user.lname} in KAYA`;
  }
  
  _renderPrivate = () => {
    return (
      <div className='private-user'>
        <p>This user is private.</p>
      </div>
    );
  }
  
  render() {
    const user = this.props.webUser.webUser;
    
    if (!user) {
      return (
        <div className="container profile-view main-view">
          <MainHeader />
          <LoadingView className="main-view-loading"  />
        </div>
      )
    }
    
    if (user.is_private) {
      return (
        <div className="container profile-view main-view">
          <MainHeader />
          <div className="search-header">
            <GlobalAutocompleteSearch/>
          </div>
          <UserProfileHeader 
            user={user}
            isMobile={this.state.isMobile}
          />
          { this._renderPrivate() }
        </div>
      );
    }

    return (
      <div 
        className="container profile-view main-view" 
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this._getPageTitle(user)}</title> 
          <meta name="description" content={this._getPageDescription(user)} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={this._getPageTitle(user)} />
          <meta property="og:description" content={this._getPageDescription(user)} />
          {user.photo_url && <meta property="og:image" content={user.photo_url} />}
          {user.photo_url && <meta property="og:image:url" content={user.photo_url} />}
          <meta property="og:url" content={`https://kaya-app.kayaclimb.com/user/${this.props.match.params.username}`} />
          <meta property="og:site_name" content="KAYA" />
          <meta property="og:image:width" content="1500" />
          <meta property="og:image:height" content="1013" />
          <link rel="canonical" href={getProfileUrl(user.username)} />
        </Helmet>
        
        <MainHeader />
        
        <div className="search-header">
          <GlobalAutocompleteSearch/>
        </div>
        
        <UserProfileHeader 
          user={user}
          filterBy={this.state.filter_by}
          boulderingData={this.state.boulderingData}
          routesData={this.state.routesData}
          isMobile={this.state.isMobile}
        />
        
        <UserPostsGallery 
          user={user}
          isMobile={this.state.isMobile}
        />
        
        { this.state.isMobile ?
          <div className="ascent-section-profile-view">
            <div className='wrap'>      
              <UserAscentsPyramid 
                user={user}
                climbTypeId={this.state.climb_type_id}
                filterBy={this.state.filter_by}
                gradeId={this.state.min_grade_id}
                isMobile={this.state.isMobile}
              /> 
              <UserAscentsList 
                user={user}
                isMobile={this.state.isMobile}
                climbTypeId={this.state.climb_type_id}
                gradeId={this.state.min_grade_id}
                filterBy={this.state.filter_by}
                sortBy={this.state.sort_by}
                onChangeFilterParams={(params, cb) => {
                  this.setState({
                    climb_type_id: params.climb_type_id,
                    min_grade_id: params.min_grade_id,
                    max_grade_id: params.max_grade_id,
                    filter_by: params.filter_by,
                    sort_by: params.sort_by,
                  }, () => {
                    if (cb) {
                      cb();
                    }
                  })
                }}
              />
            </div>
          </div>
          : 
          <div className="ascent-section-profile-view">
            <div className="wrap ascent-row">
              <div style={{ flex: 2 }}>
                <UserAscentsList 
                  user={user}
                  isMobile={this.state.isMobile}
                  climbTypeId={this.state.climb_type_id}
                  gradeId={this.state.min_grade_id}
                  filterBy={this.state.filter_by}
                  sortBy={this.state.sort_by}
                  onChangeFilterParams={(params, cb) => {
                    const { climb_type_id, min_grade_id, max_grade_id, filter_by, sort_by } = params; 
                    this.setState({
                      climb_type_id,
                      min_grade_id,
                      max_grade_id,
                      filter_by,
                      sort_by,
                    }, () => {
                      if (cb) {
                        cb();
                      }
                    })
                  }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <UserAscentsPyramid 
                  user={user}
                  climbTypeId={this.state.climb_type_id}
                  gradeId={this.state.min_grade_id}
                  filterBy={this.state.filter_by}
                  isMobile={this.state.isMobile}
                /> 
              </div>
            </div>
          </div>
        }
        <GBModeratedDestinations />
        <LocationAdCard mobile={this.state.isMobile} />
        <Footer /> 
      </div>
    );
  }
}

// QUERIES
const withWebUser = graphql(GET_WEB_USER, {
  name: 'webUser',
  options: (props) => {
    return {
      variables: { 
        username: props.match.params.username,
      }, 
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

export default withRouter(withApollo(compose(
  withWebUser,
  withGrades,
)(ProfileView)));
