/**
 * Calculate the Levenshtein distance between a search word and a potential match word. Levenshtein distance is used to achieve Fuzzy Search
 * and does so by calculating the # of insertions, deletions, and updates are needed to travel from one word to another.
 *
 * @param searchTerm - String search term
 * @param matchTerm - String match term to compare to search
 * @returns number that represents the distance between the search term and match term. The larger the number, the lesser the match is
 */
export function _levDist(searchTerm, matchTerm) {
  const d = []; // 2d matrix

  // pre-processing
  const s = searchTerm.toLowerCase().replace('the', '').trim();
  const t = matchTerm.toLowerCase().replace('the', '').trim();

  const n = s.length; // search term length
  const m = t.length; // match string length

  // If exact match prioritze highest
  if (t === s) {
    return 0;
  }

  // If part of search term exactly matches match term - prioritize
  if (t.includes(s)) {
    return 1;
  }

  // If search and match strings have extremely different lengths - return high value
  if (Math.abs(m - n) > 8) {
    return 10000;
  }

  // Levenshtein distance will be the length of one string if the other is empty
  if (n === 0) return m;
  if (m === 0) return n;

  // create array of arrays
  for (let i = n; i >= 0; i--) {
    d[i] = [];
  }

  // fill out 2d matrix
  for (let i = n; i >= 0; i--) {
    d[i][0] = i;
  }
  for (let j = m; j >= 0; j--) {
    d[0][j] = j;
  }

  for (let i = 1; i <= n; i++) {
    // get string characters at each index for search term
    const s_i = s.charAt(i - 1);

    for (let j = 1; j <= m; j++) {
      // Check the jagged ld total so far
      if (i === j && d[i][j] > 4) return n;
      // get string characters at each index for match term
      const t_j = t.charAt(j - 1);
      const cost = (s_i === t_j) ? 0 : 1; // If character at same index for search and match term are same, cost is zero

      // Calculate the minimum
      let mi = d[i - 1][j] + 1;
      const b = d[i][j - 1] + 1;
      const c = d[i - 1][j - 1] + cost;

      if (b < mi) {
        mi = b;
      }
      if (c < mi) {
        mi = c;
      }

      d[i][j] = mi; // Set matrix position to min cost of that position

      // Damerau transposition
      if (i > 1 && j > 1 && s_i === t.charAt(j - 2) && s.charAt(i - 2) === t_j) {
        d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
      }
    }
  }

  // Return Lev distance
  return d[n][m];
}
