import gql from 'graphql-tag';
import SETTER_FRAGMENT from '../../fragment/webSetter';

export default gql`

    query webModeratorsForDestination($destination_id: ID!) {
        webModeratorsForDestination(destination_id: $destination_id) {
            ...WebSetterFields
        }
    }

    ${SETTER_FRAGMENT}

`;
