import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ResponsiveSidebar from './ResponsiveSidebar';
import Footer from './Footer';
import ProfileImg from '../User/ProfileImg';

export default class MainView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showUserMenu: false,
    };
  }

  render() {
    const { className, user, onLogout, children } = this.props;
    return (
      <div id="main-view">
        <div className="main-view-inner wrap">
        <ResponsiveSidebar
          user={user}
          onLogout={onLogout}
        />
        <div id="content">
          <header id="top-header">
            <div className="inner wrap">
              <a className="logo" href="https://kayaclimb.com"><div className="logo-inner" /></a>
             <div
              className="btn-user"
              role="button"
              onClick={() => this.setState({ showUserMenu: !this.state.showUserMenu })}
            >
              Hi, {user.fname} <ProfileImg src={user.photo_url} size="small" />
            </div>
            </div>
          </header>
          {children}
          {this.state.showUserMenu &&
            <div className="user-menu">
              <div
                className="btn-logout"
                role="button"
                onClick={onLogout}
              >
                Logout
              </div>
            </div>
          }
        </div>
        </div>
        <Footer />
      </div>
    );
  }
}
