import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import classnames from 'classnames';

function SidebarLink({
  faName,
  className,
  active,
  subMenu,
  subMenuPath,
  location,
  to,
  text,
}) {
  const activeClassName = active ? 'active' : '';
  return (
    <div className="nav-link">
      <NavLink to={to} activeClassName="active" className={classnames(activeClassName, className)}>
        { faName && <FontAwesomeIcon icon={faName} /> }
        {text}
      </NavLink>
      {subMenu && 
      <div className={classnames('sub-menu', location.pathname.startsWith(subMenuPath) ? 'active' : '')}>
        {subMenu.map(x => (
          <NavLink key={x.to} to={x.to} className={classnames('sub-menu-item', x.className)} activeClassName="active">
            {x.text}
          </NavLink>
        ))}
      </div>
      }
    </div>
  );
}

export default withRouter(SidebarLink);
