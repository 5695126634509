import gql from 'graphql-tag';
import ASCENT_FRAGMENT from '../../fragment/webAscent';

export default gql`

query webAscentsForUser($user_id: ID!,
  $climb_type_id: ID,
  $min_grade_id: ID,
  $max_grade_id: ID,
  $filter_by: filterAscentLocation,
  $sort_by: sortAscents,
  $offset: Int!,
  $count: Int!) {
  webAscentsForUser(user_id: $user_id,
    climb_type_id: $climb_type_id,
    min_grade_id: $min_grade_id,
    max_grade_id: $max_grade_id,
    filter_by: $filter_by,
    sort_by: $sort_by,
    offset: $offset,
    count: $count) {
    ...WebAscentFields
  }
}

${ASCENT_FRAGMENT}

`;

