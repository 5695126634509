import gql from 'graphql-tag';

export default gql`

    fragment WebGymFields on WebGym {
        id
        slug
        name
    }

`;
