import gql from 'graphql-tag';
import GYM_FRAGMENT from '../../fragment/webGym';

export default gql`

    query webGym($slug: String!) {
        webGym(slug: $slug) {
            ...WebGymFields
        }
    }

    ${GYM_FRAGMENT}

`;
