// Polyfills
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

// React
import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';

// Redux
import * as Redux from 'redux';
import ReduxThunk from 'redux-thunk';

// GA
import ReactGA from "react-ga4";

// AMPLITUDE
import { initAmplitude } from './amplitude';

// Router
import { Router } from 'react-router';
import { routerReducer } from 'react-router-redux';

// Apollo Client
import { ApolloProvider } from 'react-apollo';

// Reducers
import AuthReducer from './reducer/AuthReducer';

import App from './view/App';
import AuthTokenManager from './view/Auth/AuthTokenManager';
import EmbedSearchBarView from "./view/Search/EmbedSearchBarView";

import NetworkClient from './networkClient';

// Initialize Analytics
initAmplitude();

// CSS
require('./lib/css/style.css');
require('rc-tooltip/assets/bootstrap.css');

// Load Fonts
require('./fonts');

const createHistory = require('history').createBrowserHistory;

// Loaded Successfully -- Remove refresh app cache check for next version update
try {
  sessionStorage.removeItem('appRefreshed');
} catch (e) {
  // continue
}

try {
  // Initialize store
  const store = Redux.createStore(
    Redux.combineReducers({
      routing: routerReducer,
      Auth: AuthReducer,
    }),
    Redux.applyMiddleware(ReduxThunk));

  // Initialize Network Client
  const apolloClient = NetworkClient(store);

  // Create an enhanced history that syncs navigation events with the store
  const history = createHistory();

  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

	if (path) {
		history.replace(path);
	}

  // Initialize Google Analytics
  ReactGA.initialize('G-FFX4BRBB27');
  ReactGA.set({ anonymizeIp: true });
  history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA._gaCommandSendPageview(location.pathname);
  });

  // Handle on click firing for iOS
  if ('ontouchstart' in document.documentElement) {
    document.body.style.cursor = 'pointer';
    document.body.style.webkitTapHighlightColor = 'rgba(0, 0, 0, 0)';
  }

  if (document.getElementById('root')) {
    ReactDom.render(
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <Router history={history}>
            <AuthTokenManager>
              <App/>
            </AuthTokenManager>
          </Router>
        </ApolloProvider>
      </Provider>,
      document.getElementById('root'));
  } else if (document.getElementById('global-search-bar')) {
    ReactDom.render(
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <EmbedSearchBarView />
        </ApolloProvider>
      </Provider>,
      document.getElementById('global-search-bar'));
  }

  // Remove CookiePro buggy cookies
  // document.cookie = `OptanonAlertBoxClosed=; path=/; domain=.set.plastick.rocks; expires=${new Date().toUTCString()}`;
  // document.cookie = `OptanonConsent=; path=/; domain=.set.plastick.rocks; expires=${new Date().toUTCString()}`;
} catch (error) {
  ReactDom.render(
    <div style={{ display: 'none' }}>
      {JSON.stringify(error)}
    </div>,
    document.getElementById('root'));
}
