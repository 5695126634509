// react
import React from 'react';
import classnames from 'classnames';

export default function SectionList({ className, items }) {
  return (
    <div className={classnames('section-list', className)}>    
      { items.map((item, idx) => (
          <div key={idx} className="section-list-item">
            <div className="name">{item.name}</div>
            <div className="value">{item.value}</div>
          </div>
        ))
      }
    </div>
  );
}
