import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Breakpoints } from '../../common/LayoutStyle';
import Sidebar from './Sidebar';
import CssTransition from '../Common/Transition/CssTransition';

const SIDEBAR_WIDTH = 165;

export default class ResponsiveSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSidebar: false,
      isMobile: window.innerWidth < Breakpoints.DESKTOP,
      checkMobileTimeout: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkMobile);
    this.setState({
      isInitialLoad: false,
    });
  }

  checkMobile = () => {
    // Throttle Set State calls on Resize 
    if (!this.state.checkMobileTimeout) {
      this.setState({
        checkMobileTimeout: setTimeout(() => {
          this.setState({
            isMobile: window.innerWidth < Breakpoints.DESKTOP,
            checkMobileTimeout: null,
          });
        }, 200),
      });
    }
  }

  render() {
    const { className, gyms, selectedGym, user, userRole, walls, competition, hasBillingAlert, hasSubscriptionAlert, onLogout } = this.props;
    if (this.state.isMobile && !this.state.isInitialLoad) {
      return (
        <div>
          <CssTransition from={{ width: SIDEBAR_WIDTH }} to={{ width: 0 }} flip={this.state.showSidebar}>
            <Sidebar
              gyms={gyms}
              selectedGym={selectedGym}
              user={user}
              walls={walls}
              userRole={userRole}
              competition={competition}
              hasBillingAlert={hasBillingAlert}
              hasSubscriptionAlert={hasSubscriptionAlert}
              onLogout={onLogout}
              onHide={() => this.setState({ showSidebar: false })}
            />
          </CssTransition>
          
          <div className="btn-mobile-menu" role="button" onClick={() => this.setState({ showSidebar: true })}>
            <FontAwesomeIcon icon="bars" />
          </div>
        </div>
      );
    }
    return (
      <Sidebar
        user={user}
        onLogout={onLogout}
      />
    );
  }
}
