// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { throttle } from 'lodash';
import { getChallengeUrl } from '../../common/Navigation';
import { ApolloFetchPolicy } from '../../common/Definitions';
import { logPageView, logEvent, EVENT, VIEW } from '../../amplitude';

import SEARCH_FOR_CHALLENGE from '../../graphql/query/challenge/searchForChallenge';

// components
import MainViewContent from '../../component/App/MainViewContent';
import Loading from '../../component/Common/Misc/LoadingView';
import LoadingInline from '../../component/Common/Misc/LoadingInline';
import ChallengeCard from '../../component/Challenge/ChallengeCard';

const COUNT_PER_PAGE = 20;
class ChallengesMarketView extends Component {
  constructor(props) {
    super(props);

    logPageView(VIEW.PURCHASE_EVENTS);

    this.state = {
      term: '',
      offset: 0,
      moreToLoad: true,
    };
  }

  componentDidMount() {
    // Scroll happens at the .app node instead of window
    const appNodes = document.getElementsByClassName('app');

    if (appNodes.length) {
      this._appNode = appNodes[0];
      this._throttleHandler = throttle(this.onScroll, 500); // throttle onScroll event
      this._appNode.addEventListener('scroll', this._throttleHandler);
    }
  }

  componentWillUnmount() {
    if (this._appNode) {
      this._appNode.removeEventListener('scroll', this._throttleHandler);
    }
  }

  isCloseToBottom(el) {
    return el.scrollTop >= (el.scrollHeight - window.innerHeight - 100)
  }

  onScroll = (e) => {
    if (this.isCloseToBottom(e.target) && this.state.moreToLoad && !this.state.fetchingMore) {
      this.setState({
        offset: this.state.offset + COUNT_PER_PAGE,
        fetchingMore: true,
      }, () => {
        this.props.searchForChallenge.fetchMore({
          variables: { offset: this.state.offset },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            const moreToLoad = fetchMoreResult.searchForCurrentAndUpcomingChallenges.length > 0;

            this.setState({
              fetchingMore: false,
              moreToLoad,
            });

            const searchForCurrentAndUpcomingChallenges = [...prev.searchForCurrentAndUpcomingChallenges, ...fetchMoreResult.searchForCurrentAndUpcomingChallenges];

            logEvent(EVENT.EVENTS_LOAD_MORE);

            // Append new data to old data
            return Object.assign({}, prev, {
              searchForCurrentAndUpcomingChallenges,
            });
          },
        }).catch(error => {
          console.log(error);
          this.setState({
            fetchingMore: false,
            error,
          });
        });
      });
    }
  };

  onSearch = ({ target }) => {
    this.setState({
      searching: true,
      term: target.value,
      moreToLoad: true,
      offset: 0,
    }, () => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(async () => {
        await this.props.searchForChallenge.refetch({
          term: this.state.term,
          offset: 0,
        });
        this.setState({
          searching: false,
        });
        logEvent(EVENT.EVENTS_SEARCHED);
      }, 500);
    });
  }

  _renderSearch = () => {
    return (
      <div className="search-input">
        <FontAwesomeIcon icon="search" />
        <input 
          type="text"
          onChange={this.onSearch}
          value={this.state.term}
          placeholder="Enter keyword to search"
        />
      </div>
    );
  }

  _renderEvents = () => {
    return (
      <div className="padding-y-1">
        <h2>Events</h2>
        {this.state.searching && <LoadingInline className="center" />}
        {!this.state.searching && this.props.searchForChallenge.searchForCurrentAndUpcomingChallenges.map(challenge => (
          <ChallengeCard
            key={challenge.id}
            onClick={() => this.props.history.push(getChallengeUrl(challenge.id))}
            challenge={challenge}
          />
        ))}
        {!this.state.searching && this.props.searchForChallenge.searchForCurrentAndUpcomingChallenges.length === 0 &&
          <div className="padding-y-1">No Events Found</div>
        }
        {this.state.fetchingMore && <LoadingInline className="center" />}
      </div>
    );
  }

  render() {
    if (!this.props.searchForChallenge.searchForCurrentAndUpcomingChallenges) {
      return <Loading />;
    }

    return (
      <MainViewContent
        className="challenges-market-view"
        title="Purchase Events"
        error={this.state.error}
        onCloseError={() => this.setState({ error: null })}
      >
        <div className="wrap">
          {this._renderSearch()}
          {this._renderEvents()}
        </div>
      </MainViewContent>
    );
  }
}

const withChallenges = graphql(SEARCH_FOR_CHALLENGE, {
  name: 'searchForChallenge',
  options: props => ({
    variables: { term: '', offset: 0, count: COUNT_PER_PAGE },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  }),
});

export default withRouter(compose(
  withChallenges,
)(ChallengesMarketView));
