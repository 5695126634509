// react
import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import classnames from 'classnames';

// queries
import GET_CLIMBS from '../../graphql/query/climb/webClimbsForLocation';
import GET_GRADES from "../../graphql/query/grade/getGrades";
import {ApolloFetchPolicy, ClimbType} from "../../common/Definitions";
import {getClimbUrl} from "../../common/Navigation";
import LoadingInline from "../../component/Common/Misc/LoadingInline";
import ClimbRating from "../../component/Climb/ClimbRating";
import {getClimbStiffnessString} from "../../common/Climb";
import {EVENT, logEventWithProperties, PROP} from "../../amplitude";

const PAGE_COUNT = 20;
const SORT = {
  NAME: 'NAME',
  GRADE: 'GRADE',
  ASCENTS: 'ASCENT_COUNT',
  RATING: 'RATING',
};

class LocationClimbSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      currentPageIdx: 0,
      offset: 0,
      term: '',
      climbTypeId: ClimbType.BOULDERING,
      sort_by: null,
    };
  }

  componentDidMount() {
    this.filterClimbs();
  }

  goToPage = (idx) => {
    if (idx < 0) {
      idx = 0;
    }

    this.filterClimbs({ offset: idx * PAGE_COUNT });
    this.setState({
      currentPageIdx: idx,
      offset: idx * PAGE_COUNT,
    });

    // Analytics
    logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_LOAD_MORE);
  }

  filterClimbs = (params = {}) => {
    this.setState({
      isLoading: true,
    }, async () => {
      const requiredParams = {
        location_id: this.props.location.id,
        climb_name: this.state.term,
        climb_type_id: this.state.climbTypeId,
        min_grade_id: this.state.grade_id,
        max_grade_id: this.state.grade_id,
        min_rating: this.state.rating ? parseInt(this.state.rating, 10) : null,
        max_rating: this.state.rating ? parseInt(this.state.rating, 10) + 1 : null,
        sort_by: this.state.sort_by,
        offset: this.state.offset,
        count: PAGE_COUNT,
      };

      const {data} = await this.props.client.query({
        query: GET_CLIMBS,
        variables: {
          ...Object.assign({}, requiredParams, params),
        },
      });

      this.setState({
        data: data.webClimbsForLocation || [],
        isLoading: false,
      });
    });
  }

  _getGrades = () => {
    if (this.props.grades.allGrades) {
      const filteredGrades = this.props.grades.allGrades.filter(g => g.climb_type_id === this.state.climbTypeId && g.grade_type_id === '1');
      return [
        {
          id: -1,
          name: 'All grades',
        },
        ...filteredGrades,
      ];
    }

    return [];
  }

  _getRatings = () => {
    return [
      { id: null, name: 'All ratings' },
      { id: 4, name: '4 - 5' },
      { id: 3, name: '3 - 4' },
      { id: 2, name: '2 - 3' },
      { id: 1, name: '1 - 2' },
      { id: 0, name: '0 - 1' },
    ];
  }

  _getClimbTypes = () => {
    return [
      { id: ClimbType.BOULDERING, name: 'Bouldering' },
      { id: ClimbType.ROUTES, name: 'Routes' },
    ];
  }

  renderSelect = (value, data, labelAccessor, valueAccessor, onSelect) => {
    return (
      <select
        value={value}
        onChange={e => onSelect(e.target.value)}
      >
        {data.map(d => (
          <option
            value={d[valueAccessor]}
          >
            {d[labelAccessor]}
          </option>
        ))}
      </select>
    );
  }

  renderHeader = (location) => {
    const {
      isMobile,
    } = this.props;

    const searchInput = (
      <div className="search-input">
        <input
          type='text'
          value={this.state.term}
          placeholder="Search for climbs"
          onChange={e => {
            if (this._searchTimeout) {
              clearTimeout(this._searchTimeout);
            }

            this.setState({
              term: e.target.value,
              offset: 0,
            }, () => {
              this._searchTimeout = setTimeout(() => {
                this.filterClimbs({ climb_name: this.state.term });

                // Analytics
                logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_SEARCHED);
              }, 1000);
            })
          }}
        />
        <div className="search-icon">
          <div className="icon-search" />
        </div>
      </div>
    );

    return (
      <div className={classnames('location-climb-search-header', isMobile && 'mobile')}>
        <h2>Search {location.name} Climbs</h2>
        <div className="right">
          {this.renderSelect(
            this.state.climbTypeId,
            this._getClimbTypes(),
            'name',
            'id',
            (id) => {
              this.setState({
                climbTypeId: id,
              }, () => {
                this.filterClimbs({ climb_type_id: id });

                // Analytics
                logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_FILTERED, {
                  [PROP.TYPE]: 'climbtype',
                });
              });
            })
          }

          {this.renderSelect(
            this.state.grade_id,
            this._getGrades(),
            'name',
            'id',
            (id) => {
              const grade_id = id === '-1' ? null : id;
              this.setState({
                grade_id,
              }, () => {
                this.filterClimbs({ min_grade_id: grade_id, max_grade_id: grade_id });

                // Analytics
                logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_FILTERED, {
                  [PROP.TYPE]: 'grade',
                });
              });
            })
          }

          {this.renderSelect(
            this.state.rating,
            this._getRatings(),
            'name',
            'id',
            (id) => {
              this.setState({
                rating: id,
              }, () => {
                this.filterClimbs({ min_rating: parseInt(id, 10), max_rating: parseInt(id, 10) + 1 });

                // Analytics
                logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_FILTERED, {
                  [PROP.TYPE]: 'rating',
                });
              });
            })
          }

          { !isMobile && searchInput }

        </div>

        { isMobile && searchInput }
      </div>
    )
  }

  renderTable = (location) => {
    const data = this.state.data || [];

    return (
      <div className="location-climb-search-results">
        <div className="table">
          <div className="row header">
            <div
              className={classnames('cell name', this.state.sort_by === SORT.NAME && 'selected')}
               onClick={() => {
                 this.setState({
                   sort_by: SORT.NAME,
                   offset: 0,
                 }, () => this.filterClimbs({ sort_by: SORT.NAME }));

                 // Analytics
                 logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_SORTED, {
                   [PROP.TYPE]: 'name',
                 });
               }}
            >
              Name
            </div>
            <div
              className={classnames('cell grade', this.state.sort_by === SORT.GRADE && 'selected')}
              onClick={() => {
                this.setState({
                  sort_by: SORT.GRADE,
                  offset: 0,
                }, () => this.filterClimbs({ sort_by: SORT.GRADE }));

                // Analytics
                logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_SORTED, {
                  [PROP.TYPE]: 'grade',
                });
              }}
            >
              Grade
            </div>
            <div className="cell area">
              Area
            </div>
            <div
              className={classnames('cell ascents', this.state.sort_by === SORT.ASCENTS && 'selected')}
              onClick={() => {
                this.setState({
                  sort_by: SORT.ASCENTS,
                  offset: 0,
                }, () => this.filterClimbs({ sort_by: SORT.ASCENTS }));

                // Analytics
                logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_SORTED, {
                  [PROP.TYPE]: 'ascents',
                });
              }}
            >
              # of Ascents
            </div>
            <div
              className={classnames('cell rating', this.state.sort_by === SORT.RATING && 'selected')}
              onClick={() => {
                this.setState({
                  sort_by: SORT.RATING,
                  offset: 0,
                }, () => this.filterClimbs({ sort_by: SORT.RATING }));

                // Analytics
                logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_SORTED, {
                  [PROP.TYPE]: 'rating',
                });
              }}
            >
              Rating
            </div>
          </div>

          {(this.state.isLoading) && (
            <div className="no-results">
              <LoadingInline />
            </div>
          )}

          {(!this.state.isLoading && data.length === 0) && (
            <div className="no-results">
              <div>No Results Found</div>
            </div>
          )}

          {!this.state.isLoading && data.map(d => (
            <a
              className="row"
              href={getClimbUrl(d.slug)}
              target="_blank"
              onClick={() => {
                // Analytics
                logEventWithProperties(EVENT.LOCATION_CLIMB_SEARCH_CLIMB_CLICKED, {
                  [PROP.LOCATION]: location.name,
                });
              }}
            >
              <div className="cell name">
                {d.name}
              </div>
              <div className="cell grade">
                {d.grade.name}
              </div>
              <div className="cell area">
                {d.area?.name}
              </div>
              <div className="cell ascents">
                {d.ascent_count}
              </div>
              <div className="cell rating">
                <ClimbRating
                  rating={d.rating}
                  ascentCount={d.ascent_count}
                  stiffness={getClimbStiffnessString(d)}
                  small
                />
              </div>
            </a>
          ))}
        </div>

        {(!this.state.isLoading && data.length > 0) &&
          <div className="pagination">
            <div className="page-arrows">
              <div className="page-arrow-container">
                <div
                  className={classnames('page-arrow', this.state.offset <= 0 && 'disabled')}
                  onClick={this.state.offset <= 0 ? null : () => this.goToPage(this.state.currentPageIdx - 1)}
                >
                  <div
                    className={'icon-arrow-backward'}
                  />
                </div>
              </div>
              <div className="page-arrow-container">
                <div
                  className={classnames('page-arrow', this.state.data.length === 0 && 'disabled')}
                  onClick={this.state.data.length === 0 ? null : () => this.goToPage(this.state.currentPageIdx + 1)}
                >
                  <div
                    className={'icon-arrow-forward'}
                  />
                </div>
              </div>
            </div>
            <div className="page-current">Showing results: {this.state.offset} - {this.state.offset + PAGE_COUNT}</div>
          </div>
        }
      </div>
    )
  }

  render() {
    const {
      location,
    } = this.props;

    return (
      <div className="location-climb-search" >
        <div className="wrap">
          {this.renderHeader(location)}
          {this.renderTable(location)}
        </div>
      </div>
    );
  }
}

// QUERIES
const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

export default withApollo(compose(
  withGrades,
)(LocationClimbSearch));
