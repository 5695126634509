export const getError = (error) => {
  if (error) {
    if (Array.isArray(error)) {
      const messages = [];
      error.forEach(e => {
        messages.push(getError(e));
      });
      return messages.join('\n').trim();
    } else if (typeof error === 'object' && 'graphQLErrors' in error) {
      const messages = [];
      error.graphQLErrors.forEach(e => {
        messages.push(getError(e));
      });
      console.log(error);
      return messages.join('\n').trim();
    } else if (typeof error === 'object' && 'message' in error) {
      console.log(error);
      return error.message;
    } else if (typeof error === 'string') {
      console.log(error);
      return error;
    }
    return 'Unknown error';
  }
};
