import gql from 'graphql-tag';

export default gql`

fragment WebClimbFields on WebClimb {
    id
    slug
    name
    grade {
      name 
      id
    }
    climb_type {
      name
    }
    color {
        name
    }
    ascent_count
    is_offensive
    top_videos {
      video_url
      thumb_url
      username
      user_photo_url
      ascent_date
    }
    media_links {
      url
      thumbnail_url
      rp_climb_media_link_type_id
    }
    lead
    rating
    num_reviews
    avg_grade_ordering
    gym {
      id
      name
      slug
    }
    board {
      id
      name
      slug
    }
    destination {
      id
      name
      latitude
      longitude
      slug
    }
    area {
      id
      name
      slug
    }
    angle
    description
    has_access_issues
    is_gb_moderated
}

`;
