import { isEmptyString } from './util';

const PERSONAL_STRING = 'PERSONAL';
const OUTDOOR_BOULDER_STRING = 'Outdoor boulder';
const OUTDOOR_ROUTE_STRING = 'Outdoor route';
const OUTDOOR_CLIMB_ACCESS_ISSUES_STRING = '(ACCESS ISSUES)';
const BOARD_CLIMB_STRING = 'Board';
const INDOOR_BOULDER_STRING = 'Indoor boulder';
const INDOOR_ROUTE_STRING = 'Indoor route';
const SEND_TEXT = 'send';
const SENDS_TEXT = 'sends';
const REPEAT_TEXT = 'repeat';
const REPEATS_TEXT = 'repeats';
const SENDS_AND_REPEATS_TEXT = 'total sends + repeats';
const GYM_STRING = 'gym';
const DESTINATION_STRING = 'destination';
const OUTDOOR_STRING = 'outdoor';
const BOARD_STRING = 'board';
const SOLID_STRING = 'Solid';
const SOFT_STRING = 'Soft';
const HARD_STRING = 'Hard';
const KAYA_GRADE_LIST = 'kaya grade list';
const BOULDER_STRING = 'boulder';
const ROUTE_STRING = 'route';

export function getAscentStiffnessString(ascent) {
  let stiffnessText = SOLID_STRING;
  if (ascent.stiffness < 0) {
    stiffnessText = SOFT_STRING;
  } else if (ascent.stiffness > 0) {
    stiffnessText = HARD_STRING;
  }
  return stiffnessText;
}
export function getClimbStiffnessString(climb) {
  let stiffnessText = SOLID_STRING;
  if (climb.avg_stiffness < -0.5) {
    stiffnessText = SOFT_STRING;
  } else if (climb.avg_stiffness > 0.5) {
    stiffnessText = HARD_STRING;
  }
  return stiffnessText;
}

/**
 * Returns a name for a climb based on where the climb is from: indoors, outdoors, board, etc.
 * @param {*} climb the climb that will be described
 * @param {*} shortVersion if true, the returned name will be shorter than otherwise
 * @param {*} includeGym determines if gym name should be included for an indoor climb
 * @param {*} reveal if true, climb name will be hidden
 */
export function getClimbName(climb, shortVersion, includeGym, reveal, ascent, useEstablishedGradeOnly) {
  if (!climb) return '';

  // prioritize user selected grade
  let grade = (ascent && ascent.grade) || climb.grade;
  if (useEstablishedGradeOnly) {
    grade = climb.grade;
  }

  // indoor
  if (climb.gym && grade) {
    let name = `${grade.name}`;
    if (!isEmptyString(climb.name)) {
      name = `${climb.name.trim()}, ${name}`;
    } else if (climb.color) {
      name = `${climb.color.name} ${grade.name}`;
    }
    if (shortVersion) {
      return name;
    }
    if (climb.wall && climb.wall.name === KAYA_GRADE_LIST && climb.gym) {
      name = `${name}, ${climb.gym.name}`;
    } else if (climb.wall) {
      name = `${name}, ${climb.wall.group_name || climb.wall.name}`;
    }
    if (includeGym && climb.gym) {
      name = `${name}, ${climb.gym.name}`;
    }
    return name;
    // outdoor or board
  } else if (climb.name && grade) {
    // obscure name if offensive and not revealed
    let name = climb.is_offensive && !reveal ? _obscureName(climb.name) : climb.name;
    // append access flag if has_access_issues and not revealed
    name = climb.has_access_issues && !reveal ? `${name} ${grade.name} ${OUTDOOR_CLIMB_ACCESS_ISSUES_STRING}` : `${name} ${grade.name}`;
    if (shortVersion) {
      return name;
    }
    // outdoor
    if (climb.destination) {
      return `${name}, ${climb.destination.name}`;
      // board
    } else if (climb.board) {
      return `${name}, ${climb.board.name}`;
    }
    // private
    let privateName = grade.name;
    if (climb.name) {
      privateName = `${climb.name} ${privateName}`;
    }
    return privateName;
  }
  return grade.name;
}

export function getClimbLocationName(climb) {
  if (climb.board) {
    return climb.board.name;
  }
  if (climb.gym) {
    return climb.gym.name;
  }
  if (climb.destination) {
    let location = climb.destination.name;
    if (climb.area) {
      location = `${climb.area.name}, ${location}`;
    }
    return location;
  }
  return '-';
}

/**
 * Obscures name by keeping first letter of each word and replacing the rest with '-'.
 * ex: "Test Test" => "T--- T---"
 *
 * @param {*} name
 */
function _obscureName(name = '') {
  const nameSplit = name.split(' ');
  return nameSplit.map(word => {
    if (word && word.length) {
      let result = word[0];
      for (let i = 1; i < word.length; i++) {
        result = `${result}-`;
      }
      return result;
    }
    return '';
  }).join(' ');
}
