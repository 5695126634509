import gql from 'graphql-tag';
import CLIMB_FRAGMENT from '../../fragment/webClimbBasic';

export default gql`

    query webSearchForClimb($name: String!, $offset: Int!, $count: Int!) {
        webSearchForClimb(name: $name, offset: $offset, count: $count) {
            ...WebClimbBasicFields
        }
    }

    ${CLIMB_FRAGMENT}

`;
