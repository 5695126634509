import gql from 'graphql-tag';
import FILTER_DISTRIBUTION_FRAGMENT from '../../fragment/WebFilterDistribution';

export default gql`

query webFilterDistributionForAscents($user_id: ID!,
  $filter_by: filterAscentLocation,
  $climb_type_id: ID) {
    webFilterDistributionForAscents(user_id: $user_id,
      filter_by: $filter_by,
      climb_type_id: $climb_type_id) {
    ...WebFilterDistributionFields
  }
}

${FILTER_DISTRIBUTION_FRAGMENT}

`;