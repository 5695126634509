import config from '../config';

export function getLoginUrl() {
  return '/login';
}

export function getChallengeUrl(id) {
  return `/purchase/challenge/${id}`;
}

export function getClimbUrl(slug) {
  return `${config.clientUri}/climb/${slug}`;
}

export function getPostUrl(slug, postId, postItemId) {
  return `${config.clientUri}/climb/${slug}#${postId}-${postItemId}`;
}

export function getLocationUrl(slug) {
  return `${config.clientUri}/location/${slug}`;
}

export function getGymUrl(slug) {
  return `${config.clientUri}/gym/${slug}`;
}

export function getProfileUrl(username) {
  return `${config.clientUri}/user/${username}`;
}

export function getEmbedCode(postId, postItemId) {
  // width and height set to match Youtube defaults 
  return `<iframe src="${config.clientUri}/embed/${postId}/${postItemId}" title="KAYA video player" width="560" height="315" frameborder="0" scrolling="no" allowfullscreen="true"></iframe>`;
}
