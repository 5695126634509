import React from 'react';
import BtnClose from '../Button/BtnClose';

export default function SuccessAlert({ text, onClose }) {
  if (onClose) {
    return (
      <div className="alert-success alert"><BtnClose onClick={onClose} /> {text}</div>
    );
  }
  return (
    <div className="alert-success">{text}</div>
  );
}
