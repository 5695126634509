// react
import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CheckedSectionList({ className, items }) {
  // props structure:
  // items = [{name: 'name', values: [true, false, true]}]

  return (
    <div className={classnames('checked-section-list', className)}> 
      { items.map((item, idx) => (
          <div key={idx} className={classnames('checked-section-list-row', idx % 2 === 0 && 'highlight')} >
            <p className="row-title">{item.name}</p>
            { item.values.map((val, valIdx) => (
              <div key={valIdx} className="value">
                { val ? 
                  <FontAwesomeIcon icon={"check"} size="2x" color={"#AFFF00"} />
                  : 
                  <p></p>
                }
              </div>
            ))}
          </div>
        ))
      }
    </div>
  );
}
