// react
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { refreshToken } from '../../action/AuthAction';
import LoadingView from '../../component/Common/Misc/LoadingView';

const REFRESH_TIMEOUT = 1000 * 60 * 30; // 30 min in ms

/**
 * Manages authorization tokens to keep authorized users logged in.
 * Uses OAuth 2.0 implicit grant.
 * Using the locally stored refresh_token received upon login, the auth_token is refreshed
 * prior to expiration (30 minutes refresh - 1 hour expiration).
 * Auth_token should be stored in memory.
 */
class AuthTokenManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInitialized: false,
    };
  }

  componentDidMount() {
    // refresh auth token on focus of window (ie opening device after sleeping)
    this._focusListener = window.addEventListener('focus', this.refreshAuthToken);
    
    // start refresh timer immediately
    this.refreshAuthToken();
  }

  componentDidUpdate(prevProps) {
    // While initially refreshing, show loading spinner to prevent unauthorized calls (ie; if auth_token is expired)
    if ((prevProps.Auth.isRefreshingToken && !this.props.Auth.isRefreshingToken) && !this.state.isInitialized) {
      this.setState({
        isInitialized: true,
      });
    }

    // Stop refresh timer onLogout
    if (prevProps.Auth.isLoggedIn && !this.props.Auth.isLoggedIn) {
      this.clearRefreshTimer();
    }
    
    // Restart Timer onLogin
    if (!prevProps.Auth.isLoggedIn && this.props.Auth.isLoggedIn) {
      this.refreshAuthToken();
    }
  }

  componentWillUnmount() {
    // Clear all timers and dependencies
    this.clearRefreshTimer();
    if (this._focusListener) {
      window.removeEventListener('focus', this._focusListener);
    }
  }

  refreshAuthToken = () => {
    this.clearRefreshTimer();

    this.props.refreshToken();

    // Refresh auth token every 30 minutes
    this._refreshTokenTimer = setTimeout(this.refreshAuthToken, REFRESH_TIMEOUT);
  }

  clearRefreshTimer = () => {
    if (this._refreshTokenTimer) {
      clearTimeout(this._refreshTokenTimer);
    }
  }

  render() {
    if (!this.state.isInitialized) return <LoadingView />;
    
    // Pass props to children
    return React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, this.props);
    });
  }
}

const mapStateToProps = state => ({
  Auth: state.Auth,
});

const mapDispatchToProps = dispatch => ({
  refreshToken: () => {
    dispatch(refreshToken());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthTokenManager)
);
