import gql from 'graphql-tag';
import POST_FRAGMENT from '../../fragment/webPost';

export default gql`

    query webPostsForClimb($climb_id: ID!, $count: Int!, $offset: Int!) {
        webPostsForClimb(climb_id: $climb_id, count: $count, offset: $offset) {
            ...WebPostFields
        }
    }

    ${POST_FRAGMENT}

`;
