// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';
import { Breakpoints } from '../../common/LayoutStyle';

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import GET_RECOMMENDED_CLIMBS from "../../graphql/query/climb/recommendedClimbsForWeb";
import {ApolloFetchPolicy} from "../../common/Definitions";
import LoadingInline from "../../component/Common/Misc/LoadingInline";
import RecommendedClimbListItem from "../../component/Climb/RecommendedClimbListItem";
import {getClimbUrl} from "../../common/Navigation";
import {getClimbName, getClimbLocationName, getClimbStiffnessString} from "../../common/Climb";
import ClimbRating from '../../component/Climb/ClimbRating';
import {EVENT, logEventWithProperties} from "../../amplitude";

const RECOMMENDED_CLIMBS_STRING = 'CLIMBS RELATED TO';

class RelatedClimbList extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  
  _getThumbUrl = (climb) => {
    if (climb.top_videos && climb.top_videos.length > 0) {
      return climb.top_videos[0].thumb_url;
    } else if (climb.media_links && climb.media_links.length > 0) {
      return climb.media_links[0].thumb_url; 
    }
    
    return null; // Background image CSS style will show if URL does not exist 
  }

  renderClimb = (climb) => {
    return (
      <div
        key={climb.id}
        className="climb"
      >
        <div
          className="climb-thumb"
          onClick={() => {
            window.open(getClimbUrl(climb.slug));
            logEventWithProperties(EVENT.CLIMB_RELATED_CLIMB_CLICKED);
          }
          }
        >
          <img
            src={this._getThumbUrl(climb) || require('../../lib/img/climb-empty-placeholder.png')}
          />
        </div>
        <a
          href={getClimbUrl(climb.slug)}
          target="_blank"
          className="climb-info"
          onClick={() => {
            // Analytics
            logEventWithProperties(EVENT.CLIMB_RELATED_CLIMB_CLICKED);
          }}
        >
          <div className="climb-name">{getClimbName(climb, true)}</div>
          <div className="climb-area">{getClimbLocationName(climb)}</div>
          <div className='climb-rating'>
            <ClimbRating
              rating={climb.rating}
              ascentCount={climb.ascent_count}
              stiffness={getClimbStiffnessString(climb)}
              small
            />
          </div>
        </a>
      </div>
    );
  }
  
  renderForMobile = (climbs) => {
    return (
      <div className="related-climb-cards mobile">
        <div className="wrap">
        {
          climbs.slice(0, 2).map(climb => this.renderClimb(climb))
        }
      </div>
      <div className="wrap">
        {
          climbs.slice(2, 4).map(climb => this.renderClimb(climb))
        }
      </div>
    </div> 
    )
  }
  
  renderForDesktop = (climbs) => {
    return (
      <div className="related-climb-cards">
        <div className="wrap">
        {
          climbs.map(climb => this.renderClimb(climb))
        }
      </div>
    </div>
    );
  }
  
  renderRating = (climb) => {
    let rating = 'N/A';
    if (climb.rating) {
      rating = parseFloat(climb.rating).toFixed(1);
    }
    return (
      <span className="rating group-title">
        <div className="icon-star" /> {rating}
      </span>
    );
  }

  renderAscentsCount = (climb) => {
    let ascentString = 'Ascents';
    if (climb.ascent_count === 1) {
      ascentString = 'Ascent';
    }
    return (
      <span className="ascent-count">
        {climb.ascent_count} Ascents
      </span>
    )
  }
  
  render() {
    const {
      climb,
    } = this.props;

    const climbs = this.props.recommendedClimbsForWeb.recommendedClimbsForWeb;

    if (this.props.recommendedClimbsForWeb.loading || !climbs) {
      return <LoadingInline />
    }

    return (
      <div className={classnames('related-climb-list', this.props.isMobile && 'mobile')}>
        <h3 className='title'>{RECOMMENDED_CLIMBS_STRING} {getClimbName(climb, true).toUpperCase()}</h3>
        { this.props.isMobile ? 
          this.renderForMobile(climbs)
          :
          this.renderForDesktop(climbs)
        }
      </div>
    )
  }
}

// QUERIES
const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

const withRecommendedClimbsForWeb = graphql(GET_RECOMMENDED_CLIMBS, {
  name: 'recommendedClimbsForWeb',
  options: (props) => {
    return {
      variables: {
        slug: props.slug,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

export default compose(
  withGrades,
  withRecommendedClimbsForWeb,
)(RelatedClimbList);
