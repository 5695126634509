import gql from 'graphql-tag';

export default gql`

fragment UserFields on User {
    id
    email
    fname
    lname
    username
    photo_url
    usac_id
}

`;
