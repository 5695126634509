import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { graphql, compose } from 'react-apollo';
import { ApolloFetchPolicy } from '../../common/Definitions';
import { isEmptyString } from '../../common/util';
import * as config from '../../config';

// components 
import Loading from '../../component/Common/Misc/LoadingView';

// queries
import GET_SLUG_FOR_CLIMB from '../../graphql/query/climb/getSlugForClimb';
import GET_SLUG_FOR_LOCATION from '../../graphql/query/location/getSlugForLocation';
import GET_SLUG_FOR_GYM from '../../graphql/query/gym/getSlugForGym';
import GET_WEB_POST from '../../graphql/query/post/getWebPost';
import GET_USER_BY_ID from '../../graphql/query/user/webUserById';

import {postItemClimb} from "../../common/PostItem";

class ShareView extends Component {
  constructor(props) {
    super(props);
  }

  _getSlug = () => {
    if (this.props.slugForClimb) {
      return this.props.slugForClimb.slugForClimb;
    }

    if (this.props.slugForLocation) {
      return this.props.slugForLocation.slugForLocation;
    }

    if (this.props.slugForGym) {
      return this.props.slugForGym.slugForGym;
    }
  }

  _shouldRedirect = () => {
    if (
      this.props.slugForClimb ||
      this.props.slugForLocation ||
      this.props.slugForClimb ||
      this.props.webPost || 
      this.props.webUserById
    ) {
      return true;
    }
    return false;
  }

  render() {
    const base = config.clientUri;
    const path = window.location.pathname;
    const slug = this._getSlug();
    const post = this.props.webPost?.webPost;
    const username = this.props.webUserById?.webUserById?.username; 
    const affiliateCode = getAffiliateVariables(this.props); 
    
    if (path.includes('climb') && !path.includes('climblist') && slug) {
      const path = `/climb/${slug}`;
      window.location = `kaya://kaya-app.kayaclimb.com${window.location.pathname}${window.location.search}`;
      setTimeout(`window.location = '${base}${path}';`, 1000);
    } else if (path.includes('affiliate') && affiliateCode) {
      window.location = `kaya://kaya-app.kayaclimb.com${window.location.pathname}${window.location.search}`;
      setTimeout(`window.location = 'https://kayaclimb.com/share-download?id=${affiliateCode}';`, 1000);
    } else if (path.includes('profile') && username) {
      const path = `/user/${username}`;
      window.location = `kaya://kaya-app.kayaclimb.com${window.location.pathname}${window.location.search}`;
      setTimeout(`window.location = '${base}${path}';`, 1000);
    } else if (path.includes('location') && slug) {
      const path = `/location/${slug}`;
      window.location = `kaya://kaya-app.kayaclimb.com${window.location.pathname}${window.location.search}`;
      setTimeout(`window.location = '${base}${path}';`, 1000);
    } else if (path.includes('gym') && slug) {
      const path = `/gym/${slug}`;
      window.location = `kaya://kaya-app.kayaclimb.com${window.location.pathname}${window.location.search}`;
      setTimeout(`window.location = '${base}${path}';`, 1000);
    } else if (path.includes('post') && post) {
      const { post_item_id, post_id } = getPostVariables();
      const climb = postItemClimb(post.items[0]);
      const path = `/climb/${climb.slug}#${post_id}-${post_item_id}`;
      if (post && !isEmptyString(climb.slug)) {
        window.location = `kaya://kaya-app.kayaclimb.com${window.location.pathname}${window.location.search}`;
        setTimeout(`window.location = '${base}${path}';`, 1000);
      }
      if (post && isEmptyString(climb.slug)) {
        setTimeout(`window.location = 'https://kayaclimb.com${window.location.pathname}${window.location.search}';`, 1000);
      }
    } else if (!this._shouldRedirect()) {
      window.location = `kaya://kaya-app.kayaclimb.com${window.location.pathname}${window.location.search}`;
      setTimeout(`window.location = 'https://kayaclimb.com${window.location.pathname}${window.location.search}';`, 1000);
    }

    return (
      <Loading/>
    );
  }
}

function getAffiliateVariables(props) {
  const path = window.location.pathname;
  const search = window.location.search;
  const isAffiliatePath = path.includes("affiliate");
  const splitSearch = isAffiliatePath && search.split('=') || [];
  const affiliate_code = splitSearch.length && splitSearch[1] || null;
  if (affiliate_code) {
    return affiliate_code
  }
  return null;
}

function getClimbVariables(props) {
  const path = window.location.pathname;
  const search = window.location.search;
  const isClimbPath = path.includes("climb");
  const isClimblistPath = path.includes("climblist");
  const splitSearch = isClimbPath && !isClimblistPath && search.split('=') || [];
  const climb_id = splitSearch.length && splitSearch[1] || null;
  if (climb_id) {
    return {
      climb_id,
    };
  }
  return null;
}


function getLocationVariables(props) {
  const path = window.location.pathname;
  const search = window.location.search;
  const isLocationPath = path.includes("location");
  const splitSearch = isLocationPath && search.split('=') || [];
  const location_id = splitSearch.length && splitSearch[1] || null;
  if (location_id) {
    return {
      location_id,
    };
  }
  return null
}

function getGymVariables(props) {
  const path = window.location.pathname;
  const search = window.location.search;
  const isGymPath = path.includes("gym");
  const splitSearch = isGymPath && search.split('=') || [];
  const gym_id = splitSearch.length && splitSearch[1] || null;
  if (gym_id) {
    return {
      gym_id,
    };
  }
  return null
}

function getPostVariables(props) {
  const path = window.location.pathname;
  const search = window.location.search;

  // process URL to get relevant params, first split by = symbol then & symbol to get id and child id
  const isPostPath = path.includes("post");
  const splitSearch = isPostPath && search.split('=') || [];
  const postIdSection = splitSearch.length && splitSearch[1] || null;
  const postItemIdSection = splitSearch.length && splitSearch[2] || null;
  const splitPostIdSection = postIdSection && postIdSection.split('&') || [];
  const splitPostItemIdSection = postItemIdSection && postItemIdSection.split('&') || [];
  const post_id = splitPostIdSection.length && splitPostIdSection[0];
  const post_item_id = splitPostItemIdSection.length && splitPostItemIdSection[0];

  if (post_id && post_item_id) {
    return {
      post_id,
      post_item_id,
    };
  }
  return null;
}

function getUserVariables(props) {
  const path = window.location.pathname;
  const search = window.location.search;
  const isUserPath = path.includes("profile");
  const splitSearch = isUserPath && search.split('=') || [];
  const id = splitSearch.length && splitSearch[1] || null;
  if (id) {
    return {
      id,
    };
  }
  return null
}

// QUERIES
const withSlugForClimb = graphql(GET_SLUG_FOR_CLIMB, {
  name: 'slugForClimb',
  options: (props) => {
    const variables = getClimbVariables(props)

    return {
      variables,
    };
  },
  skip: (props) => !getClimbVariables(props),
});

const withSlugForLocation = graphql(GET_SLUG_FOR_LOCATION, {
  name: 'slugForLocation',
  options: (props) => {
    const variables = getLocationVariables(props);

    return {
      variables,
    };
  },
  skip: (props) => !getLocationVariables(props),
});

const withSlugForGym = graphql(GET_SLUG_FOR_GYM, {
  name: 'slugForGym',
  options: (props) => {
    const variables = getGymVariables(props);

    return {
      variables,
    };
  },
  skip: (props) => !getGymVariables(props),
});

const withWebPost = graphql(GET_WEB_POST, {
  name: 'webPost',
  options: (props) => {
    const variables = getPostVariables(props);

    return {
      variables,
    };
  },
  skip: (props) => !getPostVariables(props),
});

const withWebUser = graphql(GET_USER_BY_ID, {
  name: 'webUserById',
  options: (props) => {
    const variables = getUserVariables(props);

    return {
      variables,
    };
  },
  skip: (props) => !getUserVariables(props),
});

export default withRouter(compose(
  withSlugForClimb,
  withSlugForLocation,
  withSlugForGym,
  withWebPost,
  withWebUser,
)(ShareView));
