import gql from 'graphql-tag';

export default gql`

    fragment WebClimbBasicFields on WebClimb {
        slug
        name
        rating
        ascent_count
        grade {
            name
            id
        }
        climb_type {
            name
        }
        color {
            name
        }
        gym {
            name
        }
        board {
            name
        }
        destination {
            name
        }
        area {
            name
        }
    }

`;
