import gql from 'graphql-tag';
import LOCATION_FRAGMENT from '../../fragment/webLocation';

export default gql`

    query webSearchForLocation($name: String!, $location_type_id: ID, $is_gb_moderated_only: Boolean, $offset: Int!, $count: Int!) {
        webSearchForLocation(name: $name, location_type_id: $location_type_id, is_gb_moderated_only: $is_gb_moderated_only, offset: $offset, count: $count) {
            ...WebLocationFields
        }
    }

    ${LOCATION_FRAGMENT}

`;
