import gql from 'graphql-tag';
import LOCATION_FRAGMENT from '../../fragment/webLocation';

export default gql`

    query webLocationsForLocation($location_id: ID!, $offset: Int!, $count: Int!, $climb_type_id: ID) {
        webLocationsForLocation(location_id: $location_id, offset: $offset, count: $count, climb_type_id: $climb_type_id) {
            ...WebLocationFields
        }
    }

    ${LOCATION_FRAGMENT}

`;

