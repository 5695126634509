// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login';
import { validateEmail } from '../../common/Validator';
import { getError } from '../../common/Error';
import config from '../../config';
import { logPageView, VIEW } from '../../amplitude';

// actions
import { login, fbLogin, googleLogin, appleLogin } from '../../action/AuthAction';

// components
import Loading from '../../component/Common/Misc/Loading';
import Footer from '../../component/App/Footer';
import ErrorAlert from '../../component/Common/Alert/ErrorAlert';

const WelcomeMessages = [
  'Let\'s Go!',
  'Come On!',
  'Allez!',
  'Gamba!',
  'Jia You!',
  'Venga!',
];

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    logPageView(VIEW.LOGIN);

    this.state = {
      email: '',
      password: '',
      error: '',
      welcomeMessage: WelcomeMessages[Math.floor(WelcomeMessages.length * Math.random())],
    };
  }

  componentDidMount() {
    if (this.props.Auth.isLoggedIn) {
      this.props.history.push('/home');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.Auth.isLoggedIn) {
      this.props.history.push('/home');
    } else if (prevProps.Auth.isLoading && this.props.Auth.error) {
      this.setState({
        error: this.props.Auth.error,
      });
    }

    if (!this.state.isInitialized) {
      this.setState({
        isInitialized: true,
      }, () => {
        if (this.props.location.search) {
          const tokenSplit = this.props.location.search.split('token=');
          if (tokenSplit && tokenSplit.length === 2) {
            const token = tokenSplit[1];
            this.props.loginAppleUser(token);
          }
        }
      });
    }
  }

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  }

  onLogin = (e) => {
    // Validation
    let error;
    if (!validateEmail(this.state.email)) {
      error = 'Invalid Email. Please provide an email with the format: example@example.com';
    }

    if (error) {
      this.setState({
        error,
      });
    } else {
      this.props.loginUser(this.state.email, this.state.password);
    }
  }

  onPasswordKeyPress = (e) => {
    if (e.which === 13) {
      this.onLogin();
    }
  }

  render() {
    if (this.props.Auth.isLoading) return <Loading />;

    const error = getError(this.props.Auth.error) || this.state.error;

    return (
      <div className="container login-view">
        <div className="wrap">
          { error && 
            <ErrorAlert text={error} />
          }
          <div className="login-container">
            <div className="padding-y-1">
              <a href="https://kayaclimb.com">
                <img className="logo" src={require('../../lib/img/kaya-logo-green.png')} alt="KAYA the climber's app" />
              </a>
            </div>
            <div>To continue, sign in</div>
            <div className="social-login-options">
              <div>
                <GoogleLogin
                  clientId="926395056140-sd0de6hi7ts2p26ge3ljqbo0ts4bcdhs.apps.googleusercontent.com"
                  buttonText="Sign in with Google"
                  onSuccess={({ accessToken }) => this.props.loginGoogleUser(accessToken)}
                  onFailure={() => this.setState({ error: 'Unable to login with Google.' })}
                  cookiePolicy={'single_host_origin'}
                  className="btn-google-login"
                />
              </div>
              <div>
                <FacebookLogin
                  appId="396333471218931"
                  fields="name,email,picture"
                  icon="fa-facebook-square"
                  textButton="Sign in with Facebook"
                  redirectUri={config.clientUri}
                  isMobile={true}
                  disableMobileRedirect={true}
                  callback={({ accessToken }) => this.props.loginFBUser(accessToken)}
                />
              </div>
              <div>
                <AppleLogin
                  clientId="com.p9alabs.kayaweb"
                  redirectURI={config.appleLoginRedirectUri}
                  scope={"email"}
                  responseType={"id_token+code"} 
                  responseMode={"form_post"}  
                  designProp={{
                    color: 'white',
                    width: 290,
                    height: 40,
                  }}
                />
              </div>
            </div>
            <div className="login-separator">Or</div>
            <form onSubmit={this.onLogin}>
              <input type="email" onChange={this.onChangeEmail} placeholder="email" />
              <input type="password" onChange={this.onChangePassword} onKeyPress={this.onPasswordKeyPress} placeholder="password" />
              <div className="btn-outline-green" onClick={this.onLogin} role="button">SIGN IN</div>
            </form>
            <div className="register">
              <div className="padding-y-1">
                <div>Forgot your password? Please reset it in the KAYA app.</div>
              </div>
              <div className="padding-t-1">Don't have a KAYA account? Download KAYA from the App Store <a href="https://apps.apple.com/us/app/kaya-the-climbers-app/id1481413749" target="_blank">here</a>.</div>
              <div>or Google Play store <a href="https://play.google.com/store/apps/details?id=com.project9a.redpoint" target="_blank">here</a> and create an account. Happy climbing!</div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({ Auth: state.Auth });

const mapDispatchToProps = dispatch => ({
  loginUser: (email, password) => {
    dispatch(login(email, password));
  },
  loginFBUser: (token) => {
    dispatch(fbLogin(token));
  },
  loginGoogleUser: (token) => {
    dispatch(googleLogin(token));
  },
  loginAppleUser: (token) => {
    dispatch(appleLogin(token));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginContainer));
