import gql from 'graphql-tag';

export default gql`

    fragment WebLocationAscentsDistributionFields on WebLocationAscentsDistribution {
        id
        month
        ascent_count
    }

`;
