import React from 'react';

export default function Unauthorized() {
  return (
    <div className="page-not-found-view">
      <div className="wrap padding-y-1 center">
        <div className="jumbo">Uh Oh! This is awkward.</div>
        <div>
          You've reached a page that doesn't exist. Please check the URL and retry. We apologize for any inconvenience!
          If this seems like a mistake please contact your Gym Admin or Plastick support.
        </div>
      </div>
    </div>
  );
}
