// react
import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import * as moment from 'moment';
import classnames from 'classnames';


// relative 
import {getClimbUrl} from "../../common/Navigation";
import {getAscentStiffnessString, getClimbName, getClimbLocationName} from "../../common/Climb";
import {AscentSortType, ClimbType, FilterAscentLocations} from "../../common/Definitions";
import {logPageView, logEventWithProperties, VIEW, EVENT, PROP} from '../../amplitude';
import {formatReleventDate, getFullDate} from "../../common/Date";

// queries
import GET_ASCENTS_FOR_USER from '../../graphql/query/user/webAscentsForUser';
import GET_GRADES from '../../graphql/query/grade/getGrades';

// components
import ProfileImg from '../../component/User/ProfileImg';
import UserProfileInfoModal from '../../component/User/UserProfileInfoModal';
import ClimbRating from '../../component/Climb/ClimbRating';
import LoadingInline from "../../component/Common/Misc/LoadingInline";

const PAGE_COUNT = 50;

const sortByOptions = [
  {
    id: AscentSortType.DATE,
    name: 'Date'
  },
  {
    id: AscentSortType.GRADE,
    name: 'Grade'
  },
  {
    id: AscentSortType.RATING,
    name: 'Rating'
  },
];

const filterByOptions = [
  {
    id: FilterAscentLocations.OUTDOOR,
    name: 'Outdoor'
  },
  {
    id: FilterAscentLocations.GYM,
    name: 'Gym'
  },
  {
    id: FilterAscentLocations.BOARD,
    name: 'Board'
  },
  {
    id: FilterAscentLocations.ALL,
    name: 'All'
  },
];

class UserAscentsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      climb_type_id: ClimbType.BOULDERING,
      min_grade_id: null,
      max_grade_id: null,
      sort_by: null,
      offset: 0,
      fetchingMore: false,
      moreToLoad: true, 
      data: [], 
    };
  }
  
  componentDidMount = () => {
    this.filterAscents();
    window.addEventListener('scroll', this.trackScrolling, true);
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.trackScrolling, true);
  }
  
  trackScrolling = () => {
    const wrappedElement = document.getElementById('user-ascents-list');
    if (this.isBottom(wrappedElement) && !this.state.fetchingMore && this.state.moreToLoad && this.state.data.length > 0) {
      this.loadMoreAscents();
    }
  };
  
  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }
  
  _getGrades = () => {
    if (this.props.grades.allGrades) {
      const filteredGrades = this.props.grades.allGrades.filter(g => g.climb_type_id === this.props.climbTypeId && g.grade_type_id === '1');
      return [
        {
          id: -1,
          name: 'All grades',
        },
        ...filteredGrades,
      ];
    }

    return [];
  }
  
  loadMoreAscents = (params = {}) => {
    this.setState({
      fetchingMore: true,
    }, async () => {
      const offset = this.state.offset + PAGE_COUNT; 
      const requiredParams = {
        user_id: this.props.user.id,
        filter_by: this.props.filterBy,
        climb_type_id: this.props.climbTypeId,
        min_grade_id: this.props.gradeId,
        max_grade_id: this.props.gradeId,
        sort_by: this.props.sortBy,
        offset,
        count: PAGE_COUNT,
      };

      const { data } = await this.props.client.query({
        query: GET_ASCENTS_FOR_USER,
        variables: {
          ...Object.assign({}, requiredParams, params),
        },
      });
      
      const loadMoreData = data.webAscentsForUser;
      const fullData = [...this.state.data, ...loadMoreData];
    
      this.setState({
        data: fullData,
        fetchingMore: false,
        moreToLoad: loadMoreData?.length >= PAGE_COUNT, 
        offset,
      });
    })
  }
  
  filterAscents = (params = {}) => {
    this.setState({
      isLoading: true,
      offset: 0,
      moreToLoad: true, 
    }, async () => {
      const requiredParams = {
        user_id: this.props.user.id,
        filter_by: this.props.filterBy,
        climb_type_id: this.props.climbTypeId,
        min_grade_id: this.props.gradeId,
        max_grade_id: this.props.gradeId,
        sort_by: this.props.sortBy,
        offset: this.state.offset,
        count: PAGE_COUNT,
      };

      const {data} = await this.props.client.query({
        query: GET_ASCENTS_FOR_USER,
        variables: {
          ...Object.assign({}, requiredParams, params),
        },
      });
      
      this.setState({
        data: data.webAscentsForUser || [],
        isLoading: false,
      });
    });
  }
  
  renderEmptyList = () => {
    return (
      <div className="empty-list">
        <p style={{ textAlign: 'center', padding: '2em' }}>No ascents matching the selected filters</p>
      </div>
    );
  }
  
  renderForMobile = (ascents) => {
    return (
      <div>
        {ascents.map(ascent => (
          <div className="ascent row mobile" key={ascent.id}>
            <div className="comment">
              <a
                className="climb"
                href={getClimbUrl(ascent.climb?.slug)}
                target="_blank"
                onClick={() => {
                  // Analytics
                  logEventWithProperties(EVENT.ASCENT_CLIMB_CLICKED);
                }}
              >
                {getClimbName(ascent.climb, true)}
                <p style={{ color: "#8F92A1"}}>{getClimbLocationName(ascent.climb)}</p>
              </a>
              {ascent.comment &&
                <div className="text">{ascent.comment}</div>
              }
              <div>
                {ascent.rating &&
                  <span className="rating">★ {ascent.rating}</span>
                }
                <span className="grade">
                  ({ascent.grade.name}, {getAscentStiffnessString(ascent)})
                </span>
              </div>
            </div>
            <div className="date mobile">
              {moment(ascent.date).format('M.D.Y')}
            </div>
          </div>
        ))}
        { ascents.length === 0 && !this.state.fetchingMore && this.renderEmptyList() }
        { this.state.fetchingMore && <LoadingInline /> }
      </div>
    );
  }
  
  renderForDesktop = (ascents) => {
    return (
      <div>
        <div className="header row">
          <div className="climb-title">
            CLIMB
          </div>
          <div className="comment-title">
            COMMENT
          </div>
          <div className="date-title">
            DATE
          </div>
        </div>
        {ascents.map(ascent => (
          <div className="ascent row" key={ascent.id}>
            <a
              className="climb"
              href={getClimbUrl(ascent.climb?.slug)}
              target="_blank"
              onClick={() => {
                // Analytics
                logEventWithProperties(EVENT.ASCENT_CLIMB_CLICKED);
              }}
            >
              {getClimbName(ascent.climb, true)}
              <p style={{ color: "#8F92A1"}}>{getClimbLocationName(ascent.climb)}</p>
            </a>
            <div className="comment">
              {ascent.comment &&
                <div className="text">{ascent.comment}</div>
              }
              <div>
                {ascent.rating &&
                  <span className="rating">★ {ascent.rating}</span>
                }
                <span className="grade">
                  ({ascent.grade.name}, {getAscentStiffnessString(ascent)})
                </span>
              </div>
            </div>
            <div className="date">
              {formatReleventDate(ascent.date).replaceAll('/', '.')}
            </div>
          </div>
        ))}
        { ascents.length === 0 && !this.state.fetchingMore && this.renderEmptyList() }
        { this.state.fetchingMore && <LoadingInline /> }
      </div>
    )
  }
  
  renderSelect = (value, data, labelAccessor, valueAccessor, onSelect) => {    
    return (
      <select
        value={value}
        onChange={e => onSelect(e.target.value)}
      >
        {data.map(d => (
          <option
            value={d[valueAccessor]}
          >
            {d[labelAccessor]}
          </option>
        ))}
      </select>
    );
  }
  
  renderHeader = () => {
    const {
      isMobile,
      onChangeFilterParams,
    } = this.props;

    return (
      <div className={classnames('location-climb-search-header', isMobile && 'mobile')}>
        <div className="right">
          {this.renderSelect(
            this.props.climbTypeId,
            [
              { id: ClimbType.BOULDERING,
                name: 'Bouldering'
              },
              { id: ClimbType.ROUTES,
                name: 'Routes'
              }
            ],
            'name',
            'id',
            (id) => {
              onChangeFilterParams({ 
                climb_type_id: id,  
                min_grade_id: null, // Reset grade filter when climb type changes 
                max_grade_id: null,
                sort_by: this.props.sortBy,
                filter_by: this.props.filterBy,
              }, () => {
                this.filterAscents(); 
                
                // Analytics
                logEventWithProperties(EVENT.USER_ASCENTS_FILTERED, {
                  [PROP.TYPE]: 'grade',
                });
              })
            })
          }
          
          <div className="divider" />
          
          { !isMobile && this.renderSelect(
            this.props.gradeId,
            this._getGrades(),
            'name',
            'id',
            (id) => {
              const grade_id = id === '-1' ? null : id;
              onChangeFilterParams({ 
                climb_type_id: this.props.climbTypeId,  
                sort_by: this.props.sortBy,
                min_grade_id: grade_id,
                max_grade_id: grade_id, 
                filter_by: this.props.filterBy,
              }, () => {
                this.filterAscents(); 
                // Analytics
                logEventWithProperties(EVENT.USER_ASCENTS_FILTERED, {
                  [PROP.TYPE]: 'grade',
                });
              })
            })
          }
          
          <div className="divider" />

          { !isMobile && this.renderSelect(
            this.props.filterBy,
            filterByOptions,
            'name',
            'id',
            (id) => {
              onChangeFilterParams({ 
                climb_type_id: this.props.climbTypeId,  
                min_grade_id: this.props.gradeId,
                max_grade_id: this.props.gradeId,
                sort_by: this.props.sortBy,
                filter_by: id, 
              }, () => {
                this.filterAscents(); 
                
                // Analytics
                logEventWithProperties(EVENT.USER_ASCENTS_FILTERED, {
                  [PROP.TYPE]: 'sort',
                });
              })
            })
          }
          
          <div className="divider" />

          { !isMobile && this.renderSelect(
            this.props.sortBy,
            sortByOptions,
            'name',
            'id',
            (id) => {
              onChangeFilterParams({ 
                climb_type_id: this.props.climbTypeId,  
                min_grade_id: this.props.gradeId,
                max_grade_id: this.props.gradeId,
                filter_by: this.props.filterBy,
                sort_by: id 
              }, () => {
                this.filterAscents(); 
                
                // Analytics
                logEventWithProperties(EVENT.USER_ASCENTS_FILTERED, {
                  [PROP.TYPE]: 'sort',
                });
              })
            })
          }
        </div>
      </div>
    )
  }
  
  render() {
    const {
      climbTypeId,
      gradeId,
      sortBy,
      onChangeFilterParams,
    } = this.props;

    const data = this.state.data;

    if (!data) {
      return (
        <div style={{ height: '500px', width: '100%', textAlign: 'center', marginTop: '200px', marginBottom: '200px' }}>
          <LoadingInline />
        </div>
      );
    }

    return (
      <div 
        id="user-ascents-list"
        className="user-ascents-list" 
      >
        <div className="wrap">
          <h2 className='title'>Ascents</h2>
          { this.renderHeader(this.props.isMobile) }
          {/* Render condensed list if mobile */}
          { this.props.isMobile ? 
            this.renderForMobile(data)
            :
            this.renderForDesktop(data)
          }
        </div>
      </div>
    );
  }
}

const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

export default withApollo(compose(
  withGrades,
)(UserAscentsList));
