import gql from 'graphql-tag';

export default gql`

    fragment WebUserFields on WebUser {
        id
        username
        fname
        lname
        photo_url
        is_private
        bio
        height
        ape_index
        limit_grade_bouldering {
          name 
          id
        }
        limit_grade_routes {
          name 
          id
        }
        is_premium
    }

`;
