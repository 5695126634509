import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FormCheckbox({ name, label, checked = false, onClick, tooltip, labelRight }) {
  const labelMarkup = <span>{label} {tooltip}</span>;
  return (
    <div className="checkbox">
      <label htmlFor={name}>
        { !labelRight &&
          labelMarkup
        }
        <div className={classnames('checkbox', checked && 'checked')} onClick={onClick} role="button">
          { checked ?
            <FontAwesomeIcon icon="check-square" />
            :
            <FontAwesomeIcon icon="square" />
          }
        </div>
        { labelRight &&
          labelMarkup
        }
      </label>
    </div>
  );
}

FormCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
