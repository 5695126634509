// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloFetchPolicy } from '../../common/Definitions';
import config from '../../config';
import { logPageView, logEventWithProperties, logEvent, VIEW, EVENT, PROP } from '../../amplitude';
import { calcFinalCost } from '../../common/Stripe';

// queries
import GET_CHALLENGE from '../../graphql/query/challenge/challenge';
import GET_PAYMENT_METHODS from '../../graphql/query/user/userPaymentMethodLast4';

// mutation
import CREATE_PAYMENT_INTENT from '../../graphql/mutation/challenge/createPaymentIntentForChallenge';
import CONFIRM_PAYMENT_INTENT from '../../graphql/mutation/challenge/confirmPaymentIntentForChallenge';
import PAY_FOR_CHALLENGE from '../../graphql/mutation/challenge/payForChallenge';
import VALIDATE_USAC_ID from '../../graphql/mutation/user/validateUSACId';

// components
import PageNotFound from '../../component/App/PageNotFound';
import MainViewContent from '../../component/App/MainViewContent';
import Loading from '../../component/Common/Misc/LoadingView';
import CheckoutForm from '../../component/Common/Stripe/CheckoutForm';
import ChallengeCard from '../../component/Challenge/ChallengeCard';
import CreditCardOption from '../../component/Common/Stripe/CreditCardOption';
import LoadingInline from '../../component/Common/Misc/LoadingInline';
import { getFullDate } from '../../common/Date';

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(config.stripeKey);

class ChallengesMarketView extends Component {
  constructor(props) {
    super(props);

    logPageView(VIEW.PURCHASE_EVENTS_PRODUCT);

    this.state = {
      selectedLast4: null,
      isInitialized: false,
      usac_id: props.user.usac_id,
    };
  }

  componentDidUpdate = () => {
    if (!this.state.isInitialized &&
      this.props.challenge.challenge &&
      !this.props.userPaymentMethodLast4.loading) {
      
      // Get latest payment method last 4 digits
      let selectedLast4 = null;
      if (this.props.userPaymentMethodLast4.userPaymentMethodLast4 &&
        this.props.userPaymentMethodLast4.userPaymentMethodLast4.length) {
        selectedLast4 = this.props.userPaymentMethodLast4.userPaymentMethodLast4[0];
      }

      this.setState({
        isInitialized: true,
        selectedLast4,
      });

      // Get payment intent if user doesn't have an existing payment method
      if (!selectedLast4 && !this.props.challenge.challenge.has_paid) {
        this.userWantsToPay();
      }
    }
  }

  validateUSAC = () => {
    return new Promise(resolve => {
      if (this.props.challenge.challenge.usac_id_required) {
        if (this.state.usac_id) {
          this.setState({
            validatingUSAC: true,
          }, () => {
            this.props.validateUSACId({
              variables: { usac_id: this.state.usac_id, challenge_id: this.props.challenge.challenge.id },
              update: (cache, { data: { validateUSACId }}) => {
                if (validateUSACId && validateUSACId.usac_id) {
                  resolve(true);
                  logEvent(EVENT.USAC_MEMBERSHIP_VERIFIED);
                } else {
                  this.setState({
                    error: 'Invalid USAC Membership ID',
                    validatingUSAC: false,
                  });
                  resolve(false);
                  logEvent(EVENT.USAC_MEMBERSHIP_DECLINED);
                }

                return validateUSACId;
              },
            }).catch(error => {
              this.setState({
                error,
                validatingUSAC: false,
              });
              resolve(false);
              logEvent(EVENT.USAC_MEMBERSHIP_DECLINED);
            });
          });
        } else {
          this.setState({
            error: 'Please enter a valid USAC Membership ID',
            validatingUSAC: false,
          });
          resolve(false);
          logEvent(EVENT.USAC_MEMBERSHIP_DECLINED);
        }
      } else {
        resolve(true);
      }
    });
  }

  confirmPayment = (paymentIntent) => {
      this.setState({
        purchasing: true,
      }, () => {
        if (paymentIntent) {
          this.props.confirmPaymentIntentForChallenge({
            variables: {
              challenge_id: this.props.challenge.challenge.id,
              payment_intent_id: paymentIntent.id,
            },
            update: (cache, { data: { confirmPaymentIntentForChallenge }}) => {
              this.setState({
                purchasing: false,
              });

              // analytics
              logEventWithProperties(EVENT.PURCHASE_COMPLETED, {
                [PROP.NAME]: this.props.challenge.challenge.name,
              });

              return confirmPaymentIntentForChallenge;
            },
          }).catch(error => {
            console.log(error);
            this.setState({
              error,
              purchasing: false,
            });
          });
        }
      });
  }

  userWantsToPay = () => {
    this.setState({
      isPaying: true,
    }, () => {
      this.props.createPaymentIntentForChallenge({
        variables: { challenge_id: this.props.challenge.challenge.id },
        update: (cache, { data: { createPaymentIntentForChallenge }}) => {
          this.setState({
            paymentIntentToken: createPaymentIntentForChallenge,
          });
        },
      }).catch(error => {
        console.log(error);
        this.setState({
          error,
        });
      });
    });
  }

  payWithExitingMethod = async () => {
    const isValid = await this.validateUSAC();
    if (isValid) {
      this.setState({
        purchasing: true,
      }, () => {
        this.props.payForChallenge({
          variables: { challenge_id: this.props.challenge.challenge.id, last4: this.state.selectedLast4 },
          update: async (cache, { data: { payForChallenge }}) => {
            await this.props.challenge.refetch();
            
            this.setState({
              purchasing: false,
            });

            // analytics
            logEventWithProperties(EVENT.PURCHASE_COMPLETED, {
              [PROP.NAME]: this.props.challenge.challenge.name,
            });

            return payForChallenge;
          },
        }).catch(error => {
          console.log(error);
          this.setState({
            error,
            purchasing: false,
          });
        });
      });
    }
  }

  _renderPaymentOptions = () => {
    // Only allow purchasing on/after Registration date
    const registrationUTC = moment(moment(this.props.challenge.challenge.registration_start).format('YYYY-MM-DDTHH:mm:ss'));
    const nowUTC = moment(moment().utc(true).format('YYYY-MM-DDTHH:mm:ss'));
    if (registrationUTC.isAfter(nowUTC)) {
      return (
        <div className="padding-y-1">
          <h2>Registration Starts {getFullDate(this.props.challenge.challenge.registration_start)}</h2>
          <div className="padding-b-1">Registration has not yet started. Please come back and try again on the registration date to purchase an event ticket. </div>
        </div>
      );
    }

    if (this.state.purchasing) {
      return (
        <LoadingInline />
      );
    }
    
    /**
     * User has already paid
     */
    if (this.props.challenge.challenge.has_paid) {
      return (
        <div className="padding-y-1">
          <h2>Thank You!</h2>
          <div className="section">
            <div className="wrap padding-y-1">
              <div className="padding-y-1">
                You've successfully purchased this event. This event is now unlocked for you to register for on the KAYA app. Happy climbing!
              </div>
              <div className="padding-y-1">
                <a
                  className="btn-green full-width center"
                  href={`${config.kayaShareUri}challenge?id=${this.props.challenge.challenge.id}`}
                  target="_blank"
                  onClick={() => {
                    logEventWithProperties(EVENT.EVENT_OPEN_KAYA, {
                      [PROP.NAME]: this.props.challenge.challenge.name,
                    })
                  }}
                >
                  VIEW IN APP
                </a>
                </div>
            </div>
          </div>
        </div>
      );
    }

    /**
     * Registration Ended
     */
    if (moment().isAfter(moment(this.props.challenge.challenge.registration_end))) {
      return (
        <div className="padding-y-1">
          <h2>Registration Closed</h2>
          <div className="section">
            <div className="wrap padding-y-1">
              <div className="padding-y-1">
                The registration window for this event has closed. Please contact the event host for more information.
              </div>
            </div>
          </div>
        </div>
      );
    }

    let content = (
      <Elements stripe={stripePromise}>
        <CheckoutForm
          onPaymentComplete={this.confirmPayment}
          paymentIntentToken={this.state.paymentIntentToken}
          showSaveCardOption
          submitText={`CONFIRM PURCHASE ($${(calcFinalCost(this.props.challenge.challenge.entry_cost * 100)/100).toFixed(2)})`}
          validationPromise={this.validateUSAC}
        />
      </Elements>
    );
    
    if (this.props.userPaymentMethodLast4.userPaymentMethodLast4 && this.props.userPaymentMethodLast4.userPaymentMethodLast4.length) {
      content = (
        <div className="section">
          <div className="wrap padding-y-1">
            { this.props.userPaymentMethodLast4.userPaymentMethodLast4.map(last4 => (
              <CreditCardOption
                last4={last4}
                onClick={() => this.setState({ selectedLast4: last4, useNewCard: false })}
                selected={last4 === this.state.selectedLast4}
              />
            ))}
            <div className="padding-t-1">
              { this.state.useNewCard && content }
              { !this.state.useNewCard && <button className="btn-outline" onClick={() => this.setState({ useNewCard: true, selectedLast4: null }, this.userWantsToPay)}>Use a different card</button>}
              { !this.state.useNewCard && this.state.selectedLast4 &&
                <div className="padding-t-1">
                  <button className="btn-green full-width center" onClick={this.payWithExitingMethod}>CONFIRM PURCHASE (${(calcFinalCost(this.props.challenge.challenge.entry_cost * 100)/100).toFixed(2)})</button>
                </div>
              }
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="padding-y-1">
        <h2>Payment Method</h2>
        {content}
      </div>
    );
  }

  _renderUSACId = () => {
    if (this.props.challenge.challenge.usac_id_required && !this.props.challenge.challenge.has_paid) {
      return (
        <div className="padding-y-1">
          <h2>USAC Membership ID</h2>
          { this.state.validatingUSAC && <LoadingInline /> }
          { !this.state.validatingUSAC &&
          <div className="section">
          <div className="wrap padding-y-1">
            <input
              type="text"
              value={this.state.usac_id}
              onChange={({ target }) => this.setState({ usac_id: target.value })}
              placeholder="USAC Membership ID (Required)"
            />
          </div>
        </div>
          }
        </div>
      );
    }
    return null;
  }

  render() {
    if (this.props.challenge.loading) {
      return <Loading />;
    }

    if (!this.props.challenge.challenge) {
      return <PageNotFound />;
    }

    return (
      <MainViewContent
        className="challenge-product-view"
        title={this.props.challenge.challenge.has_paid ? 'Your Purchase is Confirmed' : 'Your Purchase'}
        error={this.state.error}
        onCloseError={() => this.setState({ error: null })}
      >
        <div className="wrap">
          <ChallengeCard
            challenge={this.props.challenge.challenge}
            hideCTA
          />
          {this._renderUSACId()}
          {this._renderPaymentOptions()}
          {this.props.challenge.challenge.has_paid &&
            <div className="small padding-b-1">
              <FontAwesomeIcon icon="question-circle" /> Have a question about your purchase? Please contact us at hello@kayaclimb.com.
            </div>
          }
        </div>
      </MainViewContent>
    );
  }
}

// QUERIES
const withChallenge = graphql(GET_CHALLENGE, {
  name: 'challenge',
  options: props => ({
    variables: { id: props.match.params.challenge_id },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  }),
});

const withPaymentMethods = graphql(GET_PAYMENT_METHODS, {
  name: 'userPaymentMethodLast4',
  options: props => ({
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  }),
});

// MUTATIONS
const withCreatePaymentIntent = graphql(CREATE_PAYMENT_INTENT, {
  name: 'createPaymentIntentForChallenge',
});

const withConfirmPaymentIntent = graphql(CONFIRM_PAYMENT_INTENT, {
  name: 'confirmPaymentIntentForChallenge',
});

const withPayForChallenge = graphql(PAY_FOR_CHALLENGE, {
  name: 'payForChallenge',
});

const withValidateUSACId = graphql(VALIDATE_USAC_ID, {
  name: 'validateUSACId',
});

export default withRouter(compose(
  withChallenge,
  withPaymentMethods,
  withCreatePaymentIntent,
  withConfirmPaymentIntent,
  withPayForChallenge,
  withValidateUSACId,
)(ChallengesMarketView));
