// react
import React, { Component } from 'react';


// components
import Loading from '../Misc/Loading';
import Modal from '../Layout/Modal';
import ModalButton from '../Button/ModalButton';
import {
  postItemCaption,
  postItemClimb,
  postItemImageUrl,
  postItemVideoThumbnailUrl,
  postItemVideoUrl
} from "../../../common/PostItem";
import ClimbVideo from "../../Climb/ClimbVideo";
import config from "../../../config";
import {getEmbedCode, getPostUrl, getProfileUrl} from "../../../common/Navigation";
import ProfileImg from "../../User/ProfileImg";
import {EVENT, logEventWithProperties, PROP} from "../../../amplitude";
import {getCdnUri} from "../../../common/Media";
import { isEmptyString } from '../../../common/util';

class PostLightbox extends Component {
  constructor(props) {
    super(props);

    // Handle back button presses & navigation sharing
    window.location = `#${props.post.id}-${props.postItem ? props.postItem.id : props.post.items[0].id}`;

    // Analytics
    logEventWithProperties(EVENT.POST_VIEWED, {
      [PROP.ORIGIN]: props.origin,
    });

    this.state = {
      hasShared: false,
      hasEmbeded: false, 
    };
  }

  componentDidMount() {
    // Close on Back from #video
    window.onhashchange = () => {
      if (!window.location.hash) {
        this.onClose();
      }
    }
  }

  onClose = () => {
    const {
      onClose,
    } = this.props;

    // Remove #video
    window.location = '#';

    if (onClose) {
      onClose();
    }

  }

  renderShare = (climb, post, postItem) => {
    return (
      <div
        className="share"
        onClick={() => {
          navigator.clipboard.writeText(`${getPostUrl(climb.slug, post.id, postItem.id)}`);
          this.setState({
            hasShared: true,
          }, () => {
            setTimeout(() => {
              this.setState({
                hasShared: false,
              });
            }, 5000);
          })

          // Analytics
          logEventWithProperties(EVENT.POST_SHARED);
        }}
      >
        <div className="icon-send-outlined" />
        { !this.state.hasShared && 'Share Link' }
        { this.state.hasShared &&
          <div className="copied">
            Copied Url
          </div>
        }
      </div>
    )
  }
  
  renderEmbed = (post, postItem) => {
    const embedCode = getEmbedCode(post?.id, postItem?.id); 
    
    return (
      <div
        className="share"
      >
        <div className="icon-link" />
          <div 
            className="copied"
            onClick={() => {
              this.setState({
                hasEmbeded: !this.state.hasEmbeded,
              }, () => {
                setTimeout(() => {
                  this.setState({
                    hasEmbeded: false, 
                  });
                }, 8000);
              })
            }}
          >
              Embed Video
          </div>
        { this.state.hasEmbeded &&
          <div className="code-box-container">
            <p className='embed-code'>{embedCode}</p>
            <div 
              className="copy-code-button" 
              onClick={() => {
                navigator.clipboard.writeText(`${embedCode}`);
                // Analytics
                logEventWithProperties(EVENT.VIDEO_EMBED_COPIED);
                
                this.setState({
                  hasCopied: true,
                }, () => {
                  setTimeout(() => {
                    this.setState({
                      hasCopied: false,
                      hasEmbeded: false, 
                    });
                  }, 2000);
                })
              }}
            >
              <p>{this.state.hasCopied ? 'Copied' : 'Copy'}</p>
            </div>
          </div>
        }
      </div>
    )
  }

  render() {
    const {
      post,
      postItem,
    } = this.props;

    const selectedPostItem = postItem || post.items[0];
    const climb = postItemClimb(selectedPostItem);

    return (
      <Modal className="post-lightbox">
        <div className="post-lightbox-content wrap">
          <div className='share-wrapper'>
            <div
              onClick={this.onClose}
              className="btn-close"
            >
              <div className="icon-clear-circle-outlined" />
              Close
            </div>
            <div className="right">
              {this.renderShare(climb, post, selectedPostItem)}
              {this.renderEmbed(post, selectedPostItem)}
            </div>
          </div>  
          <div className="media">
            <ClimbVideo
              video={{
                video_url: postItemVideoUrl(selectedPostItem),
                thumb_url: getCdnUri(postItemVideoThumbnailUrl(selectedPostItem) || postItemImageUrl(selectedPostItem)),
              }}
              image={postItemImageUrl(selectedPostItem)}
              climb={climb}
              hideUserMeta
              shouldPlay={!isEmptyString(postItemVideoUrl(selectedPostItem))}
            />
          </div>
          <div className="post-meta">
            <div 
              className="user"
              onClick={() => {
                if (post.user?.username && !isEmptyString(post.user?.username)) {
                  window.open(getProfileUrl(post.user?.username));
                }
            
                // Analytics
                logEventWithProperties(EVENT.POST_USER_CLICKED);
              }}
            >
              <ProfileImg
                src={post.user.thumbnail_url || post.user.photo_url}
                size={"small"}
                placeholderIconSize={"2x"}
              />
              <div className="user-meta">
                <div className="name">
                  {post.user.fname} {post.user.lname}
                </div>
                <div className="username">
                  @{post.user.username}
                </div>
              </div>
            </div>
            <div className="post-caption">
              {postItemCaption(selectedPostItem)}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default PostLightbox;
