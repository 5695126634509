import gql from 'graphql-tag';

export default gql`
    fragment WebFilterDistributionFields on WebFilterDistribution {
      data {
        grade {
          id
          name
        }
        climb_count
        climb_with_video_count
        ascent_count
        redpoint_count
        flash_count
        onsight_count
      }
    }

`;
