// react
import React, { Component } from 'react';
import DownloadBadges from '../Climb/DownloadBadges';


// components
import Modal from '../Common/Layout/Modal';

class UserProfileInfoModal extends Component {
  constructor(props) {
    super(props);

    // Handle back button presses
    window.location = '#modal';

    this.state = {
      hasShared: false,
    };
  }

  componentDidMount() {
    // Close on Back from #modal
    window.onhashchange = () => {
      if (!window.location.hash) {
        this.onClose();
      }
    }
  }

  onClose = () => {
    const {
      onClose,
    } = this.props;

    // Remove #video
    window.location = '#';

    if (onClose) {
      onClose();
    }

  }

  render() {
    // JENN TODO render for mobile
    return (
      <Modal className="user-profile-info-modal">
        <div className="modal-content wrap">
          <div
            onClick={this.onClose}
            className="icon-clear-circle-outlined"
          />
          <div className='modal-card-container'>
            <div className='modal-card-text'>
              <div className='title'>Get the app to view this climbers profile and more. </div>
              <div className='subtitle'>See climbers ascent history, beta videos, climb list and more on KAYA. </div>
              <DownloadBadges />
            </div>
            <div className='modal-card-img'/>
          </div>
        </div>
      </Modal>
    );
  }
}

export default UserProfileInfoModal;