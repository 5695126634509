import React from "react";

const DOWNLOAD_KAYA_BUTTON_TEXT = 'Download KAYA';

export function MainHeader(props) {
  return (
    <div className="main-view-header">
      <div className="wrap">
        <a href="https://kayaclimb.com">
          <img className="logo" src={require('../../../lib/img/kaya-logo.svg')} alt="KAYA the climber's app" />
        </a>

        <div
          onClick={() => window.open(`https://kayaclimb.com/download`)}
          className="download-kaya-button">
          <p>{DOWNLOAD_KAYA_BUTTON_TEXT}</p>
        </div>
      </div>
    </div>
  )
}
