// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { throttle } from 'lodash';
import { getFullDate } from '../../common/Date';
import { getChallengeUrl } from '../../common/Navigation';
import { ApolloFetchPolicy } from '../../common/Definitions';
import { logPageView, VIEW, EVENT, logEvent } from '../../amplitude';

import GET_RECEIPTS from '../../graphql/query/receipt/receiptsForUser';

// components
import MainViewContent from '../../component/App/MainViewContent';
import Loading from '../../component/Common/Misc/LoadingView';
import LoadingInline from '../../component/Common/Misc/LoadingInline';

const COUNT_PER_PAGE = 20;

class ReceiptsView extends Component {
  constructor(props) {
    super(props);

    logPageView(VIEW.RECEIPTS);

    this.state = {
      offset: 0,
      moreToLoad: true,
      fetchingMore: false,
    };
  }

  componentDidMount() {
    // Scroll happens at the .app node instead of window
    const appNodes = document.getElementsByClassName('app');

    if (appNodes.length) {
      this._appNode = appNodes[0];
      this._throttleHandler = throttle(this.onScroll, 500); // throttle onScroll event
      this._appNode.addEventListener('scroll', this._throttleHandler);
    }
  }

  componentWillUnmount() {
    if (this._appNode) {
      this._appNode.removeEventListener('scroll', this._throttleHandler);
    }
  }

  isCloseToBottom(el) {
    return el.scrollTop >= (el.scrollHeight - window.innerHeight - 100)
  }

  onScroll = (e) => {
    if (this.isCloseToBottom(e.target) && this.state.moreToLoad && !this.state.fetchingMore) {
      this.setState({
        offset: this.state.offset + COUNT_PER_PAGE,
        fetchingMore: true,
      }, () => {
        this.props.receiptsForUser.fetchMore({
          variables: { offset: this.state.offset },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            const moreToLoad = fetchMoreResult.receiptsForUser.length > 0;

            this.setState({
              fetchingMore: false,
              moreToLoad,
            });

            const receiptsForUser = [...prev.receiptsForUser, ...fetchMoreResult.receiptsForUser];

            // analytics
            logEvent(EVENT.RECEIPT_LOAD_MORE);

            // Append new data to old data
            return Object.assign({}, prev, {
              receiptsForUser,
            });
          },
        }).catch(error => {
          console.log(error);
          this.setState({
            fetchingMore: false,
            error,
          });
        });
      });
    }
  };

  render() {
    if (this.props.receiptsForUser.loading) {
      return <Loading />;
    }

    return (
      <MainViewContent
        className="receipts-view"
        title="Your Receipts"
        error={this.state.error}
        onCloseError={() => this.setState({ error: null })}
      >
        <div className="wrap">
          <div className="section">
            <div className="wrap padding-y-1">
              <table>
                <thead>
                  <th>Date</th>
                  <th>Reference #</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Details</th>
                  <th></th>
                </thead>
                <tbody>
                  {this.props.receiptsForUser.receiptsForUser.map(receipt => (
                    <tr
                      key={receipt.id}
                      onClick={() => {
                        logEvent(EVENT.RECEIPT_CLICKED);
                        this.props.history.push(getChallengeUrl(receipt.challenge.id));
                      }}
                    >
                      <td>
                        {getFullDate(receipt.date)}
                      </td>
                      <td>
                        #{receipt.id}
                      </td>
                      <td>
                        ${receipt.charge_amount && receipt.charge_amount.toFixed(2)}
                      </td>
                      <td>
                        { receipt.status }
                      </td>
                      <td>
                        {receipt.challenge ? receipt.challenge.name : 'N/A'}
                      </td>
                      <td>
                        <FontAwesomeIcon icon="chevron-right" />
                      </td>
                    </tr>
                  ))}
                  {this.props.receiptsForUser.receiptsForUser.length === 0 &&
                    <div className="padding-y-1">No Receipts Found</div>
                  }
                </tbody>
              </table>
              { this.state.fetchingMore && <LoadingInline className="center" /> }
            </div>
          </div>
        </div>
      </MainViewContent>
    );
  }
}

const withReceipts = graphql(GET_RECEIPTS, {
  name: 'receiptsForUser',
  options: props => ({
    variables: { offset: 0, count: COUNT_PER_PAGE },
    fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
  }),
});

export default withRouter(compose(
  withReceipts,
)(ReceiptsView));
