import { AuthActionType } from '../action/AuthAction';
import { combine } from './util';

const defaultState = {
  isLoggedIn: false,
  isLoading: false,
  isCheckingAuth: true,
  isRefreshingToken: true,
  isCodeReset: false,
  isPasswordReset: false,
  message: '',
  user: null,
  error: '',
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case AuthActionType.LOGGING_IN:
      return combine(defaultState, {
        isLoading: true,
        isLoggedIn: false,
        isCheckingAuth: false,
        error: '',
      });

    case AuthActionType.LOGIN_SUCCESS:
      return combine(defaultState, {
        isLoading: false,
        isLoggedIn: true,
        user: action.user,
        isCheckingAuth: false,
        error: '',
      });

    case AuthActionType.LOGIN_FAILURE:
      return combine(defaultState, {
        isLoading: false,
        isLoggedIn: false,
        error: action.error,
        isCheckingAuth: false,
      });

    case AuthActionType.LOGOUT:
      return combine(defaultState, {
        isLoading: false,
        isLoggedIn: false,
        isCheckingAuth: false,
        isRefreshingToken: false,
        error: '',
      });

    case AuthActionType.TOKEN_REFRESHING:
      return combine(state, {
        isRefreshingToken: true,
        error: '',
      });

    case AuthActionType.TOKEN_REFRESH_COMPLETE:
      return combine(state, {
        isRefreshingToken: false,
        error: '',
      });
      
    default:
      return state;
  }
}
