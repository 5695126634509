import gql from 'graphql-tag';
import POST_FRAGMENT from '../../fragment/webPost';

export default gql`

query webPostsForUser($user_id: ID!,
  $offset: Int!,
  $count: Int!) {
    webPostsForUser(user_id: $user_id,
    offset: $offset,
    count: $count) {
    ...WebPostFields
  }
}

${POST_FRAGMENT}

`;

