// react
import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CheckedList({ items, title }) {
  // items structure:
  // items = ['item1', 'item2', 'item3']
  return (
    <div className="checked-list-container" >    
      { title && 
        <h2>{title}</h2>
      }
      { items.map((item, idx) => (
        <div key={idx} className={classnames('checked-list-row', idx % 2 === 0 && 'highlight')}>
          <FontAwesomeIcon icon={"check"} color={"#AFFF00"} className="checked-list-icon" />
          <p>{item}</p>
        </div>
      ))
      }
    </div>
  );
}
