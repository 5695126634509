import gql from 'graphql-tag';

export default gql`

    fragment WebLocationFields on WebLocation {
        id
        slug
        name
        latitude
        longitude
        description
        location_type {
            id
            name
        }
        parent_location {
            id
            slug
            name
            latitude
            longitude
            description
            location_type {
                id
                name
            }
        }
        is_gb_moderated_bouldering
        is_gb_moderated_routes
        description_short
        access_description
    }

`;
