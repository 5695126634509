// react
import * as React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// proptypes
import PropTypes from 'prop-types';

export default function ProfileImg({ src, size, placeholderIconSize = "2x", onClick }) {
  let content = <FontAwesomeIcon icon='user' size={placeholderIconSize} />;
  if (src) {
    content = <div className="img" style={{ backgroundImage: `url(${src})` }} />;
  }

  if (onClick) {
    return (
      <div className={classnames('profile-img', size)} role="button" onClick={onClick}>
        {content}
      </div>
    );
  }
  return (
    <div className={classnames('profile-img', size)}>
      {content}
    </div>
  );
}

ProfileImg.propTypes = {
  placeholderIconSize: PropTypes.string,
  size: PropTypes.string,
  src: PropTypes.string,
  onClick: PropTypes.func,
};
