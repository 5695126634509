// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';

// queries
import GET_MODERATORS from '../../graphql/query/location/webModeratorsForDestination';
import {ApolloFetchPolicy} from "../../common/Definitions";

class GBModeratedBanner extends Component {

  render() {
    const data = this.props.webModeratorsForDestination.webModeratorsForDestination;

    if (!data || data.length === 0) {
      return <div />;
    }

    return (
      <div className="gb-moderated-banner">
        <div className="wrap">
          <span>Moderated by </span>
          {data.map(moderator => (
            `${moderator.fname} ${moderator.lname}`
          )).join(' & ')}
        </div>
      </div>
    );
  }
}

// QUERIES
const withModerators = graphql(GET_MODERATORS, {
  name: 'webModeratorsForDestination',
  options: (props) => {
    return {
      variables: {
        destination_id: props.destinationId,
      },
    };
  },
});

export default compose(
  withModerators,
)(GBModeratedBanner);
