import gql from 'graphql-tag';
import DISTRIBUTION_FRAGMENT from '../../fragment/webLocationAscentsDistribution';

export default gql`

    query webLocationAscentsDistribution($location_id: ID!) {
        webLocationAscentsDistribution(location_id: $location_id) {
            ...WebLocationAscentsDistributionFields
        }
    }

    ${DISTRIBUTION_FRAGMENT}
`;
