// Google fonts
import Fonts from 'google-fonts';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';

// Solids Icons
import {
  faAnchor,
  faArchway,
  faArrowsAltV,
  faAward,
  faBars,
  faCamera,
  faCalendar,
  faChartBar,
  faChartLine,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faCog,
  faCreditCard,
  faDiceFive,
  faDownload,
  faEdit,
  faEllipsisV,
  faExclamationTriangle,
  faExclamationCircle,
  faGem,
  faFilm,
  faFilter,
  faList,
  faHome,
  faImage,
  faImages,
  faMap,
  faMapMarker,
  faMapMarkerAlt,
  faMinus,
  faPrint,
  faPlay,
  faPlus,
  faQuestionCircle,
  faSearch,
  faShareAlt,
  faSpinner,
  faSquare,
  faSyncAlt,
  faTag,
  faTimes,
  faToolbox,
  faTrophy,
  faUndo,
  faUser,
  faUsers,

  // Icon Picker Icons
  faCertificate,
  faRibbon,
  faCircle,
  faHeart,
  faBookmark,
  faStar,

  faShoePrints,
  faChild,
  faHandLizard,
  faHandPaper,
  faHandRock,
  faHandSpock,

  faArrowUp,
  faArrowDown,
  faDove,
  faDumbbell,
  faBrain,
  faBomb,

  faScrewdriver,
  faCommentDots,
  faClock,
  faBug,
  faCheck,
  faBolt,  
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';

// Regular Icons
import {
  faCircle as faCircleRegular,
  faClock as faClockRegular,
  faImage as faImageRegular,
  faSquare as faSquareRegular,
  faUser as faUserRegular,
  faEnvelope,
} from '@fortawesome/free-regular-svg-icons';

// Load Fonts
library.add(
  faAnchor,
  faArchway,
  faArrowsAltV,
  faAward,
  faBars,
  faCamera,
  faCalendar,
  faChartBar,
  faChartLine,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleRegular,
  faCircleNotch,
  faClockRegular,
  faCog,
  faCreditCard,
  faDiceFive,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faFilm,
  faFilter,
  faGem,
  faList,
  faHome,
  faImage,
  faImageRegular,
  faImages,
  faQuestionCircle,
  faMap,
  faMapMarker,
  faMapMarkerAlt,
  faMinus,
  faPlay,
  faVideoSlash,
  faPlus,
  faPrint,
  faQuestionCircle,
  faSearch,
  faShareAlt,
  faSpinner,
  faSquare,
  faSquareRegular,
  faSyncAlt,
  faTag,
  faTimes,
  faToolbox,
  faTrophy,
  faUndo,
  faUser,
  faUserRegular,
  faUsers,

  // Icon Picker Icons
  faCertificate,
  faRibbon,
  faCircle,
  faHeart,
  faBookmark,
  faStar,

  faShoePrints,
  faChild,
  faHandLizard,
  faHandPaper,
  faHandRock,
  faHandSpock,

  faArrowUp,
  faArrowDown,
  faDove,
  faDumbbell,
  faBrain,
  faBomb,

  faScrewdriver,
  faCommentDots,
  faClock,
  faBug,
  faCheck,
  faBolt,  
);
Fonts.add({
  Inter: ['400', '500', '700'],
  Lato: ['400', '500', '700'],
});
