import gql from 'graphql-tag';
import GRADE_FRAGMENT from '../../fragment/grade';

export default gql`

query allGrades($climb_type_id: ID, $grade_type_id: ID) {
  allGrades(climb_type_id: $climb_type_id, grade_type_id: $grade_type_id) {
    ...GradeFields
  }
}

${GRADE_FRAGMENT}

`;
