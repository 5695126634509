import gql from 'graphql-tag';
import USER_FRAGMENT from '../../fragment/user';

export default gql`

query currentUser {
  currentUser {
    ...UserFields
  }
}

${USER_FRAGMENT}

`;
