// react
import React, { Component } from 'react';
import { graphql, compose, withApollo } from 'react-apollo';
import classnames from 'classnames';

// queries
import CLIMB_SEARCH from '../../graphql/query/climb/webSearchForClimb';
import LOCATION_SEARCH from '../../graphql/query/location/webSearchForLocation';
import USER_SEARCH from '../../graphql/query/user/webSearchForUser';
import {ApolloFetchPolicy, LocationType} from "../../common/Definitions";
import {_levDist} from "../../common/Search";
import {getClimbUrl, getLocationUrl, getProfileUrl} from "../../common/Navigation";
import {getClimbLocationName, getClimbName} from "../../common/Climb";
import LoadingInline from "../../component/Common/Misc/LoadingInline";
import {EVENT, logEvent, logEventWithProperties, PROP} from "../../amplitude";
import { isEmptyString } from '../../common/util';

const PAGE_COUNT = 30;
const TABS = {
  ALL: 'ALL',
  CLIMBS: 'CLIMBS',
  DESTINATIONS: 'DESTINATIONS',
  AREAS: 'AREAS',
  USERS: 'USERS',
};

class GlobalAutocompleteSearch extends Component {
  constructor(props) {
    super(props);
    
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    
    this.state = {
      term: '',
      climbResults: [],
      destinationResults: [],
      areaResults: [],
      userResults: [], 
      selectedTab: TABS.ALL,
      hideResults: false, 
    };
  }
  
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // Reset term and close results list when clicked outside 
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !isEmptyString(this.state.term)) {
      this.setState({ term: '' }); 
    }
  }
  
  search = (term) => {
    if (this._searchTimeout) {
      clearTimeout(this._searchTimeout);
    }

    this.setState({
      term,
      isSearching: true,
    }, () => {
      this._searchTimeout = setTimeout(async () => {
        const [
          climbResults,
          destinationResults,
          areaResults,
          userResults,
        ] = await Promise.all([
          this._fetchClimbs(term),
          this._fetchLocations(term, LocationType.DESTINATION),
          this._fetchLocations(term, LocationType.AREA),
          this._fetchUsers(term),
        ]);

        this.setState({
          climbResults,
          destinationResults,
          areaResults,
          userResults,
          isSearching: false,
        });

        // Analytics
        logEvent(EVENT.SEARCH_INITIATED);
      }, 1000);
    });
  }

  _fetchClimbs = async (name) => {
    const { data } = await this.props.client.query({
      query: CLIMB_SEARCH,
      variables: {
        name,
        offset: 0,
        count: PAGE_COUNT,
      },
    });

    return data.webSearchForClimb || [];
  }

  _fetchLocations = async (name, locationTypeId) => {
    const { data } = await this.props.client.query({
      query: LOCATION_SEARCH,
      variables: {
        name,
        location_type_id: locationTypeId,
        offset: 0,
        count: PAGE_COUNT,
      },
    });

    return data.webSearchForLocation || [];
  }
  
  _fetchUsers = async (name) => {
    const { data } = await this.props.client.query({
      query: USER_SEARCH,
      variables: {
        term: name,
        offset: 0,
        count: PAGE_COUNT,
      },
    });

    return data.webSearchForUser || [];
  }
  
  renderTab = (name, tabId) => {
    return (
      <div
        className={classnames('tab', this.state.selectedTab === tabId && 'selected')}
        onClick={() => {
          this.setState({ selectedTab: tabId });

          // Analytics
          logEventWithProperties(EVENT.SEARCH_TAB_CLICKED, {
            [PROP.TYPE]: tabId,
          });
        }}
      >
        {name}
      </div>
    );
  }

  renderItem = (item) => {
    let iconMarkup;
    let href;
    let title;
    let subtitle;
    let type;
    if (item.__typename.includes('Climb')) {
      iconMarkup = (
        <div
          className='icon'
          style={{ backgroundImage: `url(${require('../../lib/img/climb-icon.png')})` }}
        />
      );
      href = getClimbUrl(item.slug);
      title = getClimbName(item, true);
      subtitle = getClimbLocationName(item);
      type = 'climb';
    } else if (item.__typename.includes('Location')) {
      iconMarkup = (
        <div className='icon-poi-outlined' />
      );
      href = getLocationUrl(item.slug);
      title = item.name;
      if (item.location_type?.id === LocationType.DESTINATION) {
        subtitle = 'Destination';
        type = 'destination';
      } else if (item.location_type?.id === LocationType.AREA) {
        subtitle = item.parent_location ? item.parent_location.name : 'Area';
        type = 'area';
      }
    } else if (item.__typename.includes('User')) {
      iconMarkup = (
        <div
          className='icon'
          style={{ backgroundImage: `url(${require('../../lib/img/person-icon.png')})` }}
        />
      );
      href = getProfileUrl(item.username);
      title = `${item.fname} ${item.lname}`;
      subtitle = item.username;
      type = 'user';
    } 

    return (
      <a
        className='results-item'
        target="_top"
        href={href}
        onClick={() => {
          // Analytics
          logEventWithProperties(EVENT.SEARCH_ITEM_CLICKED, {
            [PROP.TYPE]: type,
          });
        }}
      >
        <div className="results-item-icon">
          {iconMarkup}
        </div>
        <div className="results-item-meta">
          <div className="title">
            {title}
          </div>
          <div className="subtitle">
            {subtitle}
          </div>
        </div>
      </a>
    )
  }

  renderResults = () => {
    const {
      climbResults,
      destinationResults,
      areaResults,
      userResults,
    } = this.state;

    const allData = [
      ...climbResults,
      ...destinationResults,
      ...areaResults,
      ...userResults,
    ];
    const sortedData = allData.map(d => ({
      dist: _levDist(this.state.term, d.name || d.fname),
      data: d,
    })).sort((a, b) => {
      return a.dist - b.dist;
    }).map(d => d.data);

    let listData = sortedData;
    if (this.state.selectedTab === TABS.CLIMBS) {
      listData = climbResults;
    } else
    if (this.state.selectedTab === TABS.DESTINATIONS) {
      listData = destinationResults;
    } else
    if (this.state.selectedTab === TABS.AREAS) {
      listData = areaResults;
    } else 
    if (this.state.selectedTab === TABS.USERS) {
      listData = userResults;
    }

    return (
      <div className="results">
        <div className="results-tabs">
          {this.renderTab('All', TABS.ALL)}
          {this.renderTab('Climbs', TABS.CLIMBS)}
          {this.renderTab('Destinations', TABS.DESTINATIONS)}
          {this.renderTab('Areas', TABS.AREAS)}
          {this.renderTab('Users', TABS.USERS)}
        </div>
        <div className="results-items">
          {!this.state.isSearching &&
            listData.map(d => this.renderItem(d))
          }
          {!this.state.isSearching && listData.length === 0 &&
            <div className="no-results">
              <div className="empty">No Results Found</div>
            </div>
          }
          {this.state.isSearching &&
            <div className="no-results">
              <LoadingInline />
            </div>
          }
        </div>
      </div>
    )
  }

  render() {
    return (
      <div 
        className="global-search-autocomplete" 
        id="global-search-autocomplete"
        ref={this.wrapperRef}
      >
        <div className="search-input">
          <input
            type='text'
            value={this.state.term}
            placeholder="Search climbs, locations, & users"
            onChange={e => this.search(e.target.value)}
          />
          <div className="search-icon">
            <div className="icon-search" />
          </div>
        </div>
        { this.state.term &&
          this.renderResults()
        }
      </div>
    );
  }
}

export default withApollo(GlobalAutocompleteSearch);
