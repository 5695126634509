// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';
import { Breakpoints } from '../../common/LayoutStyle';
import {ApolloFetchPolicy, ClimbType, FilterAscentLocations} from "../../common/Definitions";

// relative 
import { VictoryBar, VictoryStack, VictoryChart, VictoryAxis, VictoryLegend, VictoryVoronoiContainer, VictoryTooltip } from 'victory';

import {getClimbUrl} from "../../common/Navigation";
import {getClimbName, getClimbLocationName, getClimbStiffnessString} from "../../common/Climb";
import {EVENT, logEventWithProperties} from "../../amplitude";

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import GET_FILTER_DISTRIBUTION_FOR_ASCENTS from '../../graphql/query/user/webFilterDistributionForAscents';

// components
import LoadingInline from "../../component/Common/Misc/LoadingInline";
import { isEmpty } from 'lodash';

const RECOMMENDED_CLIMBS_STRING = 'CLIMBS RELATED TO';

class UserAscentsPyramid extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  
  _maxY = (data) => {    
    const yPrimaryAccessor = 'redpoint_count';
    const ySecondaryAccessor = 'flash_and_onsight_count';
    
    // Prevent hitting the bounds to avoid android crashes
    const bufferScale = 1.3;

    // Find Max Y
    let maxY = 0;
    data.forEach(d => {
      if ((d[yPrimaryAccessor] + d[ySecondaryAccessor]) > maxY) {
        maxY = Math.ceil((d[yPrimaryAccessor] + d[ySecondaryAccessor]) * bufferScale);
      }
    });
    return maxY;
  }
  
  _tickValues = (data) => {
    // minY used when flip prop is used. Negative values are required to flip Y axis 
    const maxY = this._maxY(data);

    if (maxY <= 1.1) {
      return [0, 1, 2];
    } else if (maxY > 1 && maxY <= 10) {
      return [0, Math.round(maxY / 2), maxY];
    } else if (maxY > 10) {
      return [0, Math.round(maxY / 4), 2 * Math.round(maxY / 4), 3 * Math.round(maxY / 4), maxY];
    }
    
    return null;
  }
  
  _getMinMaxGrades = (data) => {
    if (!data || data.length === 0) {
      return [null, null];
    }
    
    const minGrade = data[0].grade;
    const maxGrade = data[data.length - 1].grade;
    return [minGrade, maxGrade];
  }
  
  _formatStatsUserAscentData = (distribution_stats = []) => {
    let maxGrade;
    let minGrade;
    distribution_stats.forEach(d => {
      if (d.redpoint_count + d.flash_count + d.onsight_count > 0) {
        if (!maxGrade || d.grade.ordering > maxGrade) {
          maxGrade = d.grade.ordering;
        }
        if (!minGrade || d.grade.ordering < minGrade) {
          minGrade = d.grade.ordering;
        }
      }
    });
  
    if (maxGrade && minGrade) {
      // Remove data for everything but +/- 2 grades from max/min for x axis scaling
      const filteredStats = distribution_stats.filter(g => g.grade.ordering >= (minGrade - 20) && g.grade.ordering <= (maxGrade + 20));
      return filteredStats.map(fs => {
        return {
          ...fs,
          flash_and_onsight_count: fs.flash_count + fs.onsight_count,
        };
      });
    }
  
    // Duplicate new array instance
    return distribution_stats.slice().map(fs => {
      return {
        ...fs,
        flash_and_onsight_count: fs.flash_count + fs.onsight_count,
      };
    });
  }
  
  _hasAscents = (data) => {
    if (!data) {
      return false; 
    }
    const climbCount = data.reduce((prev, curr) => { return curr.ascent_count + prev; }, 0);

    return climbCount > 0;
  }
  
  renderEmpty = () => {
    return (
      <div className='empty-chart'>
        <p>There are currently no logged ascents for this user.</p>
      </div>
    );
  }
  
  renderLegend = () => {
    return (
      <div className='legend'>
        <div className="vertical-line" />
        <p>GRADES</p>
        <div className='horizontal-line'/>
        <p>CLIMBS</p>
      </div>
    )
  }
  
  render() {
    const {
      user,
      climbTypeId,
      filterBy,
      gradeId, 
      isMobile,
    } = this.props;
    
    const ascentDistributionData = this.props.pyramidDistributionAscents?.webFilterDistributionForAscents?.data;
    if (!ascentDistributionData) {
      return <LoadingInline />
    }
    
    const xAccessor = 'grade.name';
    const yPrimaryAccessor = 'redpoint_count';
    const ySecondaryAccessor = 'flash_and_onsight_count';
    
    let chartData = [];
    const [minGrade, maxGrade] = this._getMinMaxGrades(ascentDistributionData);

    if (!minGrade || !maxGrade) {
      return null;
    }

    if (ascentDistributionData) {
      chartData = this._formatStatsUserAscentData(ascentDistributionData).filter(d => {
        return d.grade.ordering >= minGrade.ordering && d.grade.ordering <= maxGrade.ordering;
      });
    }
    
    return (
      <div className={classnames('user-ascents-pyramid', this.props.isMobile && 'mobile')}>
        {!this._hasAscents(ascentDistributionData) && this.renderEmpty()}
        <div className="title">
          <h2>{`${climbTypeId === ClimbType.BOULDERING ? "Bouldering" : "Route"} Pyramid`}</h2>
          {!isEmpty(filterBy) && <p>{`(${filterBy})`}</p>}
        </div>
        <VictoryChart
          horizontal
          height={500}
          width={400}
          padding={{ right: 20, left: 40, top: 40, bottom: 40 }}
          containerComponent={
            <VictoryVoronoiContainer
              style={isMobile && {
                pointerEvents: "auto",
                userSelect: "auto",
                touchAction: "auto"
              }}
              labelComponent={
                <VictoryTooltip 
                  constrainToVisibleArea 
                />
              }
              labels={({ datum }) => `${datum?.grade?.name}: ${datum[yPrimaryAccessor] + datum[ySecondaryAccessor]} total, ${datum[ySecondaryAccessor]} flash/onsight`}
            />
          }
        >
          <VictoryLegend 
            x={0} 
            y={0}
            orientation="horizontal"
            gutter={20}
            data={[
              { name: "Sends", symbol: { fill: "#020202", stroke: "black" }, labels: { fontFamily: "Inter" } },
              { name: "Flash + Onsight", symbol: { fill: "#AFFF00", stroke: "black" }, labels: { fontFamily: "Inter" } },
            ]}
          />
          <VictoryAxis
            standalone={false}
            offsetX={40}
            style={{
              tickLabels: {
                fill: 'black',
                fontSize: climbTypeId === ClimbType.ROUTES ? 12 : 14,
                fontFamily: 'Inter',
              },
              axis: {
                stroke: 'transparent',
                fill: 'black',
              },
            }}
            orientation={'left'}
          />
          
          {this._hasAscents(ascentDistributionData) && 
            <VictoryAxis
              standalone={false}
              tickFormat={(t) => `${Math.round(t)}`}
              fixLabelOverlap
              style={{
                tickLabels: {
                  fill: 'black',
                  fontFamily: 'Inter',
                },
                axis: {
                  stroke: 'transparent',
                  fill: 'black',
                },
                grid: { stroke: "gray" }
              }}            
              dependentAxis
            />
          }
          <VictoryStack
            horizontal
          >
            {/* Redpoint */}
            <VictoryBar
              data={chartData}
              x={xAccessor}
              y={yPrimaryAccessor}
              barWidth={4}
              cornerRadius={2}
              style={{
                data: {
                  fill: ({ datum }) => {
                    // Inactive column
                    if (!isEmpty(gradeId)) {
                      if (gradeId == datum.grade.id) {
                        return "#020202";
                      }
                      return "#9fa6b4";
                    }
                    return "#020202";
                  },
                  stroke: "#020202",
                  strokeWidth: 1,
                },
                labels: { fontFamily: "Inter" }
              }}
            />
            {/* Flash + Onsight */}
            <VictoryBar
              data={chartData}
              x={xAccessor}
              y={ySecondaryAccessor}
              barWidth={4}
              cornerRadius={2}
              style={{
                data: {
                  fill: ({ datum }) => {
                    // Inactive column
                    if (!isEmpty(gradeId)) {
                      if (gradeId == datum.grade.id) {
                        return "#AFFF00";
                      }
                      return "#9fa6b4";
                    }
                    return "#AFFF00";
                  },
                  stroke: "#020202",
                  strokeWidth: 1,
                },
                labels: { fontFamily: "Inter" }
              }}
            />
          </VictoryStack>
        </VictoryChart>
        { this.renderLegend() }
      </div>
    )
  }
}

// QUERIES
const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

const withWebFilterDistributionForAscents = graphql(GET_FILTER_DISTRIBUTION_FOR_ASCENTS, {
  name: 'pyramidDistributionAscents',
  options: (props) => {
    return {
      variables: {
        user_id: props.user?.id,
        climb_type_id: props.climbTypeId || ClimbType.BOULDERING,
        filter_by: props.filterBy || FilterAscentLocations.OUTDOOR,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

export default compose(
  withWebFilterDistributionForAscents,
  withGrades,
)(UserAscentsPyramid);
  