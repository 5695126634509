// react
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';
import { checkAuth, logout } from '../../action/AuthAction';
import { getLoginUrl } from '../../common/Navigation';
import { LocalStorageKey } from '../../common/Definitions';

import LoadingView from '../../component/Common/Misc/LoadingView';
import ErrorAlert from '../../component/Common/Alert/ErrorAlert';
import InfoAlert from '../../component/Common/Alert/InfoAlert';
import SuccessAlert from '../../component/Common/Alert/SuccessAlert';
import WarningAlert from '../../component/Common/Alert/WarningAlert';

// queries
import GET_CURRENT_USER from '../../graphql/query/user/getCurrentUser';

import Routes from './Routes';

class App extends Component {
  componentDidMount() {
    // ReactGA.pageview(window.location.pathname);

    this.props.checkAuth();
  }

  componentDidUpdate(prevProps) {
    // Refetch User on Log In
    if (!prevProps.Auth.isLoggedIn && this.props.Auth.isLoggedIn) {
      this.props.user.refetch();
    }
  }

  onLogout = () => {
    this.props.logout(() => {
      this.props.history.push(getLoginUrl());
      this.props.client.clearStore();
    });
  }

  onReload = () => {
    localStorage.removeItem(LocalStorageKey.UPDATE_VERSION);
    window.location.reload();
  }

  render() {
    if (this.props.user.loading ||
      this.props.user.networkStatus === 4 ||
      this.props.Auth.isLoading) {
      return <LoadingView />;
    }

    return (
      <div className="app ios-scroll">
        <Routes
          isLoggedIn={this.props.Auth.isLoggedIn}
          onLogout={this.onLogout}
          user={this.props.user.currentUser}
          userRefetch={this.props.user.refetch}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  Auth: state.Auth,
});

const mapDispatchToProps = dispatch => ({
  checkAuth: () => {
    dispatch(checkAuth());
  },
  logout: (callback) => {
    dispatch(logout(callback));
  },
});

const withCurrentUser = graphql(GET_CURRENT_USER, {
  name: 'user',
  notifyOnNetworkStatusChange: true,
});

export default withRouter(withApollo(compose(
  withCurrentUser,
  connect(mapStateToProps, mapDispatchToProps)
)(App)));
