import gql from 'graphql-tag';
import CLIMB_FRAGMENT from '../../fragment/webClimb';

export default gql`

query recommendedClimbsForWeb($slug: String!) {
  recommendedClimbsForWeb(slug: $slug) {
    ...WebClimbFields
  }
}

${CLIMB_FRAGMENT}

`;
