import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import config from '../../../config';
import { logEventWithProperties, logEvent, EVENT, PROP } from '../../../amplitude';

import FormCheckbox from '../Form/FormCheckbox';

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(config.stripeKey);

const CheckoutForm = ({
  paymentIntentToken,
  setupIntentToken,
  onPaymentComplete,
  onSetupComplete,
  onProcessing,
  onError,
  showSaveCardOption,
  submitText = 'SUBMIT',
  validationPromise,
}) => {
  const [error, setError] = useState(null);
  const [saveCard, setSaveCard] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  // Submit Card Effect
  useEffect(() => {
    // Scoped async
    async function processCardSubmit() {
      if (processing) {
        const card = elements.getElement(CardElement);
        if (onProcessing) {
          onProcessing();
        };

        // Validation
        if (validationPromise) {
          const isValid = await validationPromise();
          if (!isValid) {
            if (onError) {
              onError();
            }
            setProcessing(false);
            return;
          }
        }
        
        if (setupIntentToken) {
          handleSetup(card);
        } else {
          handlePayment(card);
        }
      }
    }

    // Execute async func
    processCardSubmit();
  }, [processing]);

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!processing) {
      setProcessing(true);
    }
  };

  const handlePayment = async (card) => {
    let paymentOptions = {
      payment_method: {
        card,
      },
    };
    // Save card for future use if applicable
    if (saveCard && paymentIntentToken) {
      paymentOptions = Object.assign({}, paymentOptions, {
        setup_future_usage: 'off_session',
      });
    }

    const result = await stripe.confirmCardPayment(paymentIntentToken, paymentOptions);

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
      if (onError) {
        onError();
      }
      setError(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        onPaymentComplete(result.paymentIntent);
        if (saveCard) {
          logEventWithProperties(EVENT.PAYMENT_METHOD_ADDED);
        }
      }
    }
    setProcessing(false);
  }

  const handleSetup = async (card) => {
    const result = await stripe.confirmCardSetup(setupIntentToken, {
      payment_method: {
        card,
      }
    });

    if (result.error) {
      // Display result.error.message in your UI.
      console.log(result.error.message);
      if (onError) {
        onError();
      }
      setError(result.error.message);
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
      onSetupComplete(result.setupIntent);
      logEventWithProperties(EVENT.PAYMENT_METHOD_ADDED);
    }
    setProcessing(false);
  }

  return (
    <form className="stripe-cc-form" onSubmit={handleSubmit}>
      <div>
        <label for="card-element">
          Credit or debit card
        </label>
        <CardElement
          id="card-element"
          onChange={handleChange}
        />
        <div className="card-errors" role="alert">{error}</div>
      </div>
      { showSaveCardOption &&
        <div className="padding-y-1">
          <FormCheckbox
            label="Save card for future use"
            checked={saveCard}
            onClick={() => setSaveCard(!saveCard)}
          />
        </div>
      }
      <button className="btn-green full-width center" type="submit" disabled={processing}>{submitText}</button>
    </form>
  );
}

export default function(props) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
}
