// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';
import moment from 'moment';
import { VictoryLine, VictoryChart, VictoryAxis, VictoryLegend } from 'victory';
import {EVENT, PROP, logEventWithProperties} from "../../amplitude";

// queries
import GET_ASCENT_DISTRIBUTION from '../../graphql/query/location/webLocationAscentsDistribution';
import {ApolloFetchPolicy, MonthIdMap} from "../../common/Definitions";

// components
import WeatherWidget from "./WeatherWidget";

const TABS = {
  WEATHER: 1,
  GRAPH: 2,
  APPROACH: 3,
};

class LocationInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: TABS.GRAPH,
    };
  }
  
  _formatDistributionData = (data) => {
    const formattedData = [];
    
    if (data && data.length > 0) {
      data.forEach(d => {
        formattedData.push({
          x: MonthIdMap[d.month].toUpperCase(), 
          y: d.ascent_count, 
        }); 
      })
    }
    
    return formattedData; 
  }

  renderHeader = () => {
    const {
      isMobile,
      origin,
    } = this.props;
    
    return (
      <div className="location-info-header">
        <div
          className={classnames('option', this.state.selectedTab === TABS.WEATHER && 'selected')}
          onClick={() => {
            this.setState({ selectedTab: TABS.WEATHER });

            // Analytics
            logEventWithProperties(EVENT.LOCATION_INFO_WEATHER_CLICKED, {
              [PROP.ORIGIN]: origin,
            });
          }}
        >
          {!isMobile &&
            <div className='icon-container'>
              <div className='icon-cloud-outlined'></div>
            </div>
          }
          <div>WEATHER</div>
        </div>
        <div
          className={classnames('option', this.state.selectedTab === TABS.GRAPH && 'selected')}
          onClick={() => {
            this.setState({ selectedTab: TABS.GRAPH });

            // Analytics
            logEventWithProperties(EVENT.LOCATION_INFO_ASCENTSCHART_CLICKED, {
              [PROP.ORIGIN]: origin,
            });
          }}
        >
          {!isMobile &&
            <div className='icon-container'>
              <div className='icon-chart-bar-1'></div>
            </div>
          }
          <div>ASCENTS CHART</div>
        </div>
        <div
          className={classnames('option', this.state.selectedTab === TABS.APPROACH && 'selected')}
          onClick={() => {
            this.setState({ selectedTab: TABS.APPROACH });

            // Analytics
            logEventWithProperties(EVENT.LOCATION_INFO_APPROACH_CLICKED, {
              [PROP.ORIGIN]: origin,
            });
          }}
        >
          {!isMobile && 
            <div className='icon-container'>
              <div className='icon-location-search'></div>
            </div>
          }
          <div>APPROACH</div>
        </div>
      </div>
    )
  }

  renderWeather = () => {
    const {
      location,
    } = this.props;

    return (
      <div className='weather-container'>
        <div className='weather-title'>
          {`Forecast for ${this.props.location?.name}`}
        </div>
        <div className='weather-widget-container'>
          <WeatherWidget
            latitude={location && location.latitude}
            longitude={location && location.longitude}
          />
        </div>
      </div>
    )
  }

  renderGraph = () => {
    const data = this.props.webLocationAscentsDistribution.webLocationAscentsDistribution;
    const formattedData = this._formatDistributionData(data); 
    const chartWidth =  this.props.isMobile ? window.innerWidth : window.innerWidth * 0.65; 
    const chartHeight = window.innerHeight * 0.40; 
    
    return (
      <div style={{ width: '100%', height: '40vh', paddingBottom: '60px' }}>
        <div className='chart-title'>
          <div className='title-text'>Climber activity across the year</div>
          <div className='subtitle-text'>{`${this.props.location?.name} - Last updated ${moment().format('MMM YYYY')}`}</div>
        </div>
        <VictoryChart 
          domainPadding={{y: 20, x: 20 }}
          width={chartWidth}
          height={chartHeight}
        >
          <VictoryAxis 
            dependentAxis
            style={{
              tickLabels: { fontSize: 12, fill: "#a2a2a3" },
              grid: {stroke: "#dddddd"},
              axis: {stroke: "none"},
            }}
          />
          <VictoryAxis 
            style={{
              tickLabels: { fontSize: 12, fill: "#323944" },
              axis: {stroke: "#a2a2a3"},
            }}
            fixLabelOverlap
          />
          <VictoryLine
            style={{
              data: { stroke: "#323944" },
            }}
            data={formattedData}
            interpolation="catmullRom"
          />
          <VictoryLegend 
            x={chartWidth - 100} y={20}
            orientation="horizontal"
            symbolSpacer={5}
            gutter={20}
            data={[{ name: "Ascents" }]}
            style={{
              border: { stroke: "none" },
              labels: { fill: "#323944", fontSize: 12 },
            }}
          />
        </VictoryChart>
      </div>
    );
  }

  renderApproach = () => {
    
    return (
      <div className='approach-container'>
        <div className={classnames('img-container', this.props.isMobile && 'mobile')} /> 
        <div className={classnames('info', this.props.isMobile && 'mobile')}>
          <div className="info-title">NEVER GET LOST OUTSIDE AGAIN</div>
          <div className="info-subtitle">Always find the climb. KAYA verified guides contain GPS coordinates and topo images for every climb, detailed approach information, and real-time location tracking.</div>
          <div className="info-sub-subtitle">For the most accurate approach information and gps coordinates, subscribe to KAYA PRO to access select verified destinations. </div>
          <div className="download-button">
            <a
              href="https://kayaclimb.com/download"
              className="download-button-text"
              target="_blank"
            >
              Download KAYA
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="location-info">
        <div className="wrap">
          {this.renderHeader()}
          {this.state.selectedTab === TABS.WEATHER && this.renderWeather()}
          {this.state.selectedTab === TABS.GRAPH && this.renderGraph()}
          {this.state.selectedTab === TABS.APPROACH && this.renderApproach()}
        </div>
      </div>
    );
  }
}

// QUERIES
const withAscentDistribution = graphql(GET_ASCENT_DISTRIBUTION, {
  name: 'webLocationAscentsDistribution',
  options: (props) => {
    return {
      variables: {
        location_id: props.location && props.location.id,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

export default compose(
  withAscentDistribution,
)(LocationInfo);
