import gql from 'graphql-tag';
import USER_FRAGMENT from '../../fragment/user';

export default gql`

mutation validateUSACId($usac_id: String!, $challenge_id: ID) {
  validateUSACId(usac_id: $usac_id, challenge_id: $challenge_id) {
    ...UserFields
  }
}

${USER_FRAGMENT}

`;
