import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFullDate } from '../../common/Date.js'
import ProfileImg from '../User/ProfileImg.js';
import { isEmptyString } from '../../common/util.js';

// strings
const NO_VIDEO_PLACEHOLDER_TITLE = 'No beta video'; 
const NO_VIDEO_PLACEHOLDER_TEXT = 'Download KAYA from the app store and be the first to upload beta for this climb.';

export default class ClimbVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoIsPlaying: false,
    };
  }

  componentDidMount() {
    if (this.props.shouldPlay) {
      this.playVideo();
    }
  }

  playVideo = () => {
    this.setState({
      videoIsPlaying: true,
    }, () => {
      this.refs.vidRef.play();
    });
  }

  formatUserProfileImg = (uri) => {
    if (!uri) {
      return;
    }

    const resizeString = 'https://plastick.rocks/cdn-cgi/image/width=80,quality=75/';

    return resizeString.concat(uri)
  }

  render() {
    const {
      video, 
      videoCount, 
      climb, 
      hideUserMeta, 
      image,
    } = this.props;
    
    if (!video || !climb) {
      return (
        <div className="climb-video">
          <div className="video-thumbnail video-placeholder">
            <div className={"no-beta-video-container"}>
              <div className={"play-icon-container"}>
                <FontAwesomeIcon 
                  icon={"video-slash"}
                  size="2x"
                  className="play-icon"
                />
              </div>
              <h3>{NO_VIDEO_PLACEHOLDER_TITLE}</h3>
              <p>{NO_VIDEO_PLACEHOLDER_TEXT}</p>
            </div>
          </div>
      </div>
      );
    }

    return (
      <div className="climb-video">
        {this.state.videoIsPlaying && video?.video_url ? 
          <video 
            controls 
            className="video"
            ref="vidRef"
            controlsList="nodownload" // removes download option from controls 
            title={`Beta video for ${climb.name} ${climb.grade.name} in ${climb.destination && climb.destination.name} - KAYA | the climber's app`}
            onEnded={() => {this.setState({ videoIsPlaying: false })}}
            onPlay={() => {this.setState({ videoIsPlaying: true })}}
          >
            <source src={video.video_url} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          :
          <div >
            <img 
              className="video-thumbnail" 
              onClick={!isEmptyString(image) ? null : () => this.playVideo()}
              src={image || video.thumb_url}
              alt={`Climbing Beta video of climbers on ${climb.name} ${climb.grade.name} in ${climb.destination && climb.destination.name} with ${videoCount} beta videos in KAYA, the climber's app - where climbers can log and track their ascents, progress, and connect with their local gym and outdoor climbing community.`} 
            />
            { !image && video?.video_url &&
              <FontAwesomeIcon
                icon={"play"}
                size="4x"
                className="play-icon"
                onClick={() => this.playVideo()}
              />
            }
            { !hideUserMeta &&
              <div className="video-user-info-container">
                <div className="user-thumbnail-container">
                  <ProfileImg 
                    src={this.formatUserProfileImg(video.user_photo_url)}
                    size={"user-thumbnail"}
                    placeholderIconSize={"2x"}
                  />
                </div>
                <div className="user-info">
                  <p style={{ fontWeight: "bold", fontSize: 17 }}>{video.username}</p>
                  <p>{getFullDate(video.ascent_date)}</p>
                </div>
              </div>
            }
          </div>
        }
      </div>
    )	
  }
}
