import gql from 'graphql-tag';

export default gql`

    fragment WebSetterFields on WebSetter {
        id
        fname
        lname
        photo_url
        thumbnail_url
    }

`;
