import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export default function IconButton(props) {
  return (
    <div className="icon-btn" onClick={props.onClick} role="button">
      <FontAwesomeIcon icon={props.icon} />
    </div>
  );
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
};
