import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SidebarLink from './SidebarLink';

class Sidebar extends Component {
  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }
  
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }
  
  handleClick = ({ target }) => {
    if (this.props.onHide && !this.node.contains(target)) {
      this.props.onHide();
    }
  }

  render() {
    const { user, onLogout, style } = this.props;
 
    return (
      <div id="sidebar" style={style} ref={node => { this.node = node; }}>
        <div className="inner">
          <nav>
            <SidebarLink to="/purchase" text="Purchase" />
            <SidebarLink to="/account" text="My Account" />
            <SidebarLink to="/receipts" text="Receipts" />
          </nav>
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
