// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router';
import { ApolloFetchPolicy } from '../../common/Definitions';
import { logPageView, logEventWithProperties, VIEW, EVENT } from '../../amplitude';
import { Breakpoints } from '../../common/LayoutStyle';
import { isSafari, isEmptyString } from '../../common/util';
import classnames from 'classnames';

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import GET_CLIMB from '../../graphql/query/climb/getWebClimb';
import GET_POST from '../../graphql/query/post/getWebPost';

// components
import CheckedSectionList from '../../component/Common/Layout/CheckedSectionList';
import CheckedList from '../../component/Common/Layout/CheckedList';
import ClimbVideo from '../../component/Climb/ClimbVideo';
import ClimbMeta from '../../component/Climb/ClimbMeta';
import ClimbOffensiveChip from '../../component/Climb/ClimbOffensiveChip';
import LoadingView from '../../component/Common/Misc/LoadingView';
import DownloadBadges from '../../component/Climb/DownloadBadges';
import Footer from '../../component/App/Footer';
import PostUserMeta from '../../component/Post/PostUserMeta';
import {MainHeader} from "../../component/Common/Layout/MainHeader";
import {postItemCaption, postItemVideoThumbnailUrl, postItemVideoUrl} from "../../common/PostItem";
import {getClimbName} from "../../common/Climb";

// text 
const DOWNLOAD_KAYA_TEXT = `Download KAYA to view more beta, log ascents, follow locations, bump videos and discover more climbs.`;
const DOWNLOAD_KAYA_BUTTON_TEXT = 'Download KAYA';
const KAYA_FEATURES_TITLE = `What you'll get with KAYA`;
const FEATURES_TEXT = 'FEATURES';
const PRO_TEXT = 'PRO';
const FREE_TEXT = 'FREE';

const proFeatureList = [
  "Basic climb info",
  "Beta video library",
  "Climb logging",
  "Climbing community network",
  "Challenges",
  "Outdoor Guidebooks and Maps",
  "Offline Beta Videos",
  "New set notifications",
  "Advanced search tools",
  "Advanced session tracking",
  "Performance analytics",
  "Achievements",
];

const freeFeatureList = [
  "Basic climb info",
  "Beta video library",
  "Climb logging",
  "Climbing community network",
  "Challenges",
];

const combinedFeatureList = [
  { name: 'Basi climb info', values: [ true, true ]},
  { name: 'Beta video library', values: [ true, true ] },
  { name: 'Climb logging', values: [ true, true ] },
  { name: 'Climbing community network', values: [ true, true ]},
  { name: 'Challenges', values: [ true, true ]},
  { name: 'Outdoor Guidebooks and Maps', values: [ false, true ]},
  { name: 'Offline Beta Videos', values: [ false, true ]},
  { name: 'New set notifications', values: [ false, true ]},
  { name: 'Advanced search tools', values: [ false, true ]},
  { name: 'Advanced session tracking', values: [ false, true ]},
  { name: 'Performance analytics', values: [ false, true ]},
  { name: 'Achievements', values: [ false, true ]},
];


class PostView extends Component {
  constructor(props) {
    super(props);

    logPageView(VIEW.POST);

    this.state = {
      climbId: null,
      error: null,
      isMobile: window.innerWidth < Breakpoints.DESKTOP,
      checkMobileTimeout: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkMobile);
  }

  checkMobile = () => {
    // Throttle Set State calls on Resize 
    if (!this.state.checkMobileTimeout) {
      this.setState({
        checkMobileTimeout: setTimeout(() => {
          this.setState({
            isMobile: window.innerWidth < Breakpoints.DESKTOP,
            checkMobileTimeout: null,
          });
        }, 200),
      });
    }
  }

  _getVideoCount = (videos, mediaLinks) => {
    if (!videos && !mediaLinks) {
      return 0; 
    }
    const numVideos = videos.length || 0; 
    const numMediaLinks = mediaLinks.length || 0; 

    return numVideos + numMediaLinks; 
  }

  _getPageTitle = (climb) => {
    if (!climb) {
      return;
    }
    return `Beta for ${getClimbName(climb, false, true, true)} - KAYA | The Climber's App`;
  }

  _getPageDescription = (climb, post) => {
    if (!climb || !post) {
      return
    }
    
    if (post) {
      return `Post by ${post.user.username} - "${postItemCaption(post.items[0])}". ${climb.rating >= 0 ? parseFloat(climb.rating).toFixed(1) : 0}/5 rating - View more beta videos and information about ${getClimbName(climb, false, true, true)} on KAYA.`;
    }

    return '';
  }

  downloadKayaButton = () => {
    return (
      <div 
        onClick={() => window.open(`https://kayaclimb.com/download`)} 
        className="download-kaya-button">
        <p>{DOWNLOAD_KAYA_BUTTON_TEXT}</p>
      </div>
    )
  }

  _renderClimbKayaLink = () => {
    const climb = this.props.webClimb.webClimb;
    if (!climb) {
      return (
        <p>No climb returned</p>
      );
    }

    return (
      <div className="kaya-deep-link-container">
        <h2>See more on the KAYA app</h2>
        <a href={`https://app.kayaclimb.com/share/climb?climb_id=${climb.id}`} className="kaya-deep-link">
          View {climb.name}
        </a>
      </div>
    )
  }
  _renderMainContent = () => {
    const climb = this.props.webClimb.webClimb;
    const post = this.props.webPost.webPost;
    const allGrades = this.props.grades.allGrades || []; 
    const videos = this.props.webClimb.webClimb && this.props.webClimb.webClimb.top_videos || []; 
    const mediaLinks = this.props.webClimb.webClimb && this.props.webClimb.webClimb.media_links || []; 

    const videoCount = this._getVideoCount(videos, mediaLinks) || 0; 

    if (!climb || !allGrades.length || !post) {
      return <LoadingView />
    }

    return (
      <div className={`climb-main-content ${this.state.isMobile ? "mobile" : "desktop"}`} > 

        <div className="climb-video-container">
          <ClimbVideo 
            video={{
              video_url: postItemVideoUrl(post.items[0]),
              thumb_url: postItemVideoThumbnailUrl(post.items[0]),
            }}
            videoCount={videoCount} 
            climb={climb} 
            hideUserMeta
          />
        </div>

        <div className={`climb-meta-container ${this.state.isMobile ? "mobile" : "desktop"}`}>
          <p className="climb-location-header">
            {climb && climb.destination ? climb.destination.name.toUpperCase() : '-'}
          </p>
          <h1 className="climb-title-text">
            {climb ? `${climb.name} ${climb.grade.name}` : '---'}
          </h1>
          <ClimbMeta 
            climb={climb} 
            videoCount={videoCount}
            allGrades={allGrades} 
            isMobile={this.state.isMobile}
          />

          { climb.is_offensive && 
            <div>
              <ClimbOffensiveChip />
            </div>
          }

          <hr className="climb-meta-divider" />

          <PostUserMeta 
            userPhotoUrl={post.user.photo_url}
            username={post.user.username}
            date={post.date_created}
            caption={postItemCaption(post.items[0])}
          />

          <hr className="climb-meta-divider" />

          <p className="download-kaya-text">
            {DOWNLOAD_KAYA_TEXT}
          </p>
          <DownloadBadges isMobile={this.state.isMobile} />
        </div>
      </div>
    )
  }

  _renderFeatureList = () => {
    let content; 

    if (this.state.isMobile) {
      content = (
        <div>
          <CheckedList title={PRO_TEXT} items={proFeatureList} />
          <CheckedList title={FREE_TEXT} items={freeFeatureList} />
        </div>
      )
    } else if (!this.state.isMobile) {
      content = (
        <div className="checked-section-list-container">
          <div className="checked-section-list-titles">
            <h2>{FEATURES_TEXT}</h2>
            <h3>{FREE_TEXT}</h3>
            <h3>{PRO_TEXT}</h3>
          </div>
          <CheckedSectionList items={combinedFeatureList} />
        </div>
      )
    }
    return (
      <div className="feature-list-container">
        <div className="feature-title-container">
          <div className={!this.state.isMobile && "feature-title"}>
            <h2 className={!this.state.isMobile && "feature-title-large"}>
              {KAYA_FEATURES_TITLE}
            </h2>
          </div>
        </div>
        {content}
      </div>
    )
  }

  render() {
    const climb = this.props.webClimb.webClimb;
    const post = this.props.webPost.webPost;


    if (!climb || !post || post.items?.length === 0) {
      return (
        <div className="container main-view">
          <MainHeader />
          <LoadingView className="climb-view-loading"  />
          {this._renderFeatureList()}
      </div>
      )
    }

    // Currently causes infinite redirects 
    // // Attempt to open climb page in app - ignore if Safari due buggy redirect issues 
    // if (post && post.post_id && post.post_item_id && !isSafari()) {
    //   window.location = `https://app.kayaclimb.com/share/post?id=${post.post_id}&childId=${post.post_item_id}&source=web`;
    // }

    return (
      <div className="container main-view" >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this._getPageTitle(climb)}</title>
          <meta name="description" content={this._getPageDescription(climb, post)} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={this._getPageTitle(climb)} />
          <meta property="og:description" content={this._getPageDescription(climb)} />
          <meta property="og:image" content={post && post.thumb_url} />
          <meta property="og:image:url" content={post && post.thumb_url} />
          <meta property="og:url" content={`https://kaya-app.kayaclimb.com/post/${this.props.match.params.climb_slug}/${post.id}/${post.items[0].id}`} />
          <meta property="og:site_name" content="KAYA" />
          <meta property="og:image:width" content="1500" />
          <meta property="og:image:height" content="1013" />
          <meta property="og:video" content={post && postItemVideoUrl(post.items[0])}  />
        </Helmet>

        <MainHeader />

        <div className="climb-view-content-container">
          {this._renderMainContent()}
        </div>

        {this._renderFeatureList()}

        <Footer /> 
      </div>
    );
  }
}

// QUERIES
const withClimb = graphql(GET_CLIMB, {
  name: 'webClimb',
  options: (props) => {
    return {
      variables: { 
        slug: props.match.params.climb_slug,
      }, 
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

const withWebPost = graphql(GET_POST, {
  name: 'webPost',
  options: (props) => {
    return {
      variables: { 
        post_id: props.match.params.post_id,
        post_item_id: props.match.params.post_item_id,
      }, 
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

export default withRouter(compose(
  withClimb,
  withGrades,
  withWebPost,
)(PostView));
