// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import * as moment from 'moment';
import classNames from 'classnames';

// Relative
import { isEmptyString } from '../../common/util';
import {getAscentStiffnessString, getClimbName, getClimbStiffnessString} from "../../common/Climb";
import {getClimbUrl, getProfileUrl} from "../../common/Navigation";
import {formatReleventDate, getFullDate} from "../../common/Date";
import {ApolloFetchPolicy} from "../../common/Definitions";
import {EVENT, logEvent, logEventWithProperties} from "../../amplitude";

// queries
import GET_ASCENTS_CLIMB from '../../graphql/query/climb/webAscentsForClimb';
import GET_ASCENTS_LOCATION from '../../graphql/query/location/webAscentsForLocation';
import GET_ASCENTS_GYM from '../../graphql/query/gym/webAscentsForGym';

// components 
import ProfileImg from '../../component/User/ProfileImg';
import UserProfileInfoModal from '../../component/User/UserProfileInfoModal';
import ClimbRating from '../../component/Climb/ClimbRating';
import LoadingInline from "../../component/Common/Misc/LoadingInline";

const DISPLAY_COUNT = 5;
const PAGE_COUNT = DISPLAY_COUNT * 3;

class AscentList extends Component {
  constructor(props) {
    super(props);

    this.queryName = props.location ? 'webAscentsForLocation' 
                    : props.gym ? 'webAscentsForGym'
                    : 'webAscentsForClimb';

    this.state = {
      currentPageIdx: 0,
      offset: 0,
    };
  }
  
  goToPage = (idx) => {
    if (idx < 0) {
      idx = 0;
    }

    const data = this._getData();

    // fetchmore 1 page before needed 
    if ((idx + 2) * DISPLAY_COUNT >= data.length) {
      this._fetchMore();
    }
    this.setState({
      currentPageIdx: idx,
    });

    // Analytics
    logEventWithProperties(EVENT.ASCENT_LOAD_MORE);
  }

  _getData = () => {
    const data = this.props[this.queryName][this.queryName];
    return data;
  }
  
  _getCurrentPageData = () => {
    const data = this._getData();
    
    return data.slice(this.state.currentPageIdx * DISPLAY_COUNT, (this.state.currentPageIdx * DISPLAY_COUNT) + DISPLAY_COUNT); 
  }
  
  _fetchMore = () => {
    this.setState({
      fetchingMore: true,
      offset: this.state.offset + PAGE_COUNT,
    }, () => {
      this.props[this.queryName].fetchMore({
        variables: { offset: this.state.offset },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) {
            this.setState({ moreToLoad: false })
            return prev;
          }

          const moreToLoad = fetchMoreResult[this.queryName].length > 0;

          this.setState({
            fetchingMore: false,
            moreToLoad,
          });

          const ascents = [...prev[this.queryName], ...fetchMoreResult[this.queryName]];

          // Append new data to old data
          return Object.assign({}, prev, {
            [this.queryName]: ascents,
          });
        },
      }).catch(error => {
        console.log(error);
        this.setState({
          fetchingMore: false,
          error,
        });
      });
    });
  }
  
  renderUser = (user) => {
    return (
      <div className="user-container">
        <div className="user-img">
          { (user.photo_url || user.thumbnail_url) && !user.is_private ?
            <ProfileImg 
              src={user.photo_url || user.thumbnail_url}
              size={"small"}
              placeholderIconSize={"md"}
            />
            :
            <div className='icon-container'>            
              <div className='icon-user'/> 
            </div>
          }
        </div>
        <div className="user-meta">
          <div className="name">{user?.is_private ? 'Private User': `${user?.fname?.toUpperCase()} ${user?.lname?.toUpperCase()}`}</div>

          <div className="username">{user?.is_private ? '---' : `@${user?.username}`}</div>
        </div>
      </div>
    )
  }
  
  renderPageCounter = () => {
    const data = this._getData();
    const currentPageData = this._getCurrentPageData(); 
    
    const shouldShowNext = currentPageData.length === DISPLAY_COUNT ? this.state.currentPageIdx <= (data.length / DISPLAY_COUNT) : false;
    const shouldShowPrev = this.state.currentPageIdx > 0;
    
    const ascentNumStart = (this.state.currentPageIdx * DISPLAY_COUNT) + 1; 
    const ascentNumEnd = (ascentNumStart + DISPLAY_COUNT) - 1; 
    
    return (
      <div className="pagination">
        <div className="page-arrow-container">
          <div 
            className={classNames('page-arrow', !shouldShowPrev && 'disabled')}
            onClick={shouldShowPrev ? () => this.goToPage(this.state.currentPageIdx - 1) : null}
          >
            <div
              className={'icon-arrow-backward'}
            />
          </div>
          <div 
            className={classNames('page-arrow', !shouldShowNext && 'disabled')}
            onClick={shouldShowNext ? () => this.goToPage(this.state.currentPageIdx + 1) : null}
          >
            <div
              className="icon-arrow-forward"
            />
          </div>
        </div>
        <div className="page-current">
          {`Showing results: ${ascentNumStart} - ${ascentNumEnd}`}
        </div>
      </div>
    )
  }
  
  renderForMobile = (currentPageData) => {
    return (
      <div>
        {currentPageData.map(ascent => (
          <div className="ascent row mobile" key={ascent.id}>
            <a 
              className="user" 
              href={!isEmptyString(ascent.user?.username) && !ascent.user.is_private && getProfileUrl(ascent.user?.username)}
              target='_top'
              onClick={() => {
              // Analytics
              logEventWithProperties(EVENT.ASCENT_USER_CLICKED);
            }}>
              {this.renderUser(ascent.user)}
            </a>
            <div className="comment">
              {!this.props.climb && 
                <div className="climb">
                  {getClimbName(ascent.climb, true)}
                </div>
              }
              {ascent.comment &&
                <div className="text">{ascent.comment}</div>
              }
              <div>
                {ascent.rating &&
                  <span className="rating">★ {ascent.rating}</span>
                }
                <span className="grade">
                  ({ascent.grade.name}, {getAscentStiffnessString(ascent)})
                </span>
              </div>
              <div className="date mobile">
                {moment(ascent.date).format('M.D.Y')}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  renderForDesktop = (currentPageData) => {
    return (
      <div>
        <div className="header row">
          <div className="user-title">
            <div style={{ width: '58px' }} />
            KAYA CLIMBER
          </div>
          {!this.props.climb && 
            <div className="climb-title">
              CLIMB
            </div>
          }
          <div className="comment-title">
            DETAILS
          </div>
          <div className="date-title">
            DATE
          </div>
        </div>
        {currentPageData.map(ascent => (
          <div className="ascent row" key={ascent.id}>
            <a 
              className="user" 
              href={!isEmptyString(ascent.user?.username) && !ascent.user.is_private && getProfileUrl(ascent.user?.username)}
              target='_top'
              onClick={() => {
              // Analytics
              logEventWithProperties(EVENT.ASCENT_USER_CLICKED);
            }}>
              {this.renderUser(ascent.user)}
            </a>
            {!this.props.climb && 
              <a
                className="climb"
                href={getClimbUrl(ascent.climb?.slug)}
                target="_blank"
                onClick={() => {
                  // Analytics
                  logEventWithProperties(EVENT.ASCENT_CLIMB_CLICKED);
                }}
              >
                {getClimbName(ascent.climb, true)}
              </a>
            }
            <div className="comment">
              {ascent.comment &&
                <div className="text">{ascent.comment}</div>
              }
              <div>
                {ascent.rating &&
                  <span className="rating">★ {ascent.rating}</span>
                }
                <span className="grade">
                  ({ascent.grade.name}, {getAscentStiffnessString(ascent)})
                </span>
              </div>
            </div>
            <div className="date">
              {formatReleventDate(ascent.date).replaceAll('/', '.')}
            </div>
          </div>
        ))}
      </div>
    )
  }
  render() {
    const {
      climb,
      location,
    } = this.props;

    let title = "Recent Ascents";
    if (climb) {
      title += ` for ${getClimbName(climb, true)}`;
    } else if (location) {
      title += ` in ${location.name}`;
    }

    const data = this._getData();

    if (!data) {
      return (
        <div style={{ height: '500px', width: '100%', textAlign: 'center', marginTop: '200px', marginBottom: '200px' }}>
          <LoadingInline />
        </div>
      );
    }

    if (data.length === 0) {
      return <div />;
    }

    const currentPageData = this._getCurrentPageData();

    return (
      <div className="ascents-list" >
        <div className="wrap">
          <h2 className="title">
            {title}
          </h2>
          {/* Render condensed list if mobile */}
          {this.props.isMobile ? 
            this.renderForMobile(currentPageData)
            :
            this.renderForDesktop(currentPageData)
          }
          {this.renderPageCounter()}
        </div>
        {this.state.showProfileInfoModal &&
          <UserProfileInfoModal 
            onClose={() => this.setState({ showProfileInfoModal: false })}
          />
        }
      </div>
    );
  }
}

// QUERIES
const withAscentsForClimb = graphql(GET_ASCENTS_CLIMB, {
  name: 'webAscentsForClimb',
  options: (props) => {
    return {
      variables: {
        climb_id: props.climb.id,
        offset: 0,
        count: PAGE_COUNT,
      },
    };
  },
  skip: (props) => {
    return !props.climb;
  },
});

const withAscentsForLocation = graphql(GET_ASCENTS_LOCATION, {
  name: 'webAscentsForLocation',
  options: (props) => {
    return {
      variables: {
        location_id: props.location.id,
        offset: 0,
        count: PAGE_COUNT,
      },
    };
  },
  skip: (props) => {
    return !props.location;
  },
});

const withAscentsForGym = graphql(GET_ASCENTS_GYM, {
  name: 'webAscentsForGym',
  options: (props) => {
    return {
      variables: {
        gym_id: props.gym.id,
        offset: 0,
        count: PAGE_COUNT,
      },
    };
  },
  skip: (props) => {
    return !props.gym;
  },
});

export default compose(
  withAscentsForClimb,
  withAscentsForLocation,
  withAscentsForGym,
)(AscentList);
