import gql from 'graphql-tag';
import CHALLENGE_FRAGMENT from '../../fragment/challenge';

export default gql`

query searchForCurrentAndUpcomingChallenges($term: String!, $offset: Int!, $count: Int!) {
  searchForCurrentAndUpcomingChallenges(term: $term, paid_only: true, offset: $offset, count: $count) {
    ...ChallengeFields
  }
}

${CHALLENGE_FRAGMENT}
`;
