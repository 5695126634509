import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingInline from '../Common/Misc/LoadingInline';

// strings 
const ASCENTS_STRING = 'Ascents';
const ASCENT_STRING = 'Ascent';
const VIDEOS_STRING = 'Videos';
const VIDEO_STRING = 'Video';
const REVIEWS_STRING = 'Reviews';
const REVIEW_STRING = 'Review';
export default class ClimbMeta extends Component {
  avgGradeName = (climb, allGrades) => {
    const avgOrdering = climb && climb.avg_grade_ordering; 
    if (!avgOrdering) {
      return 'N/A'
    }
    const roundedOrdering = Math.round(avgOrdering / 10) * 10;
    const avgGrade = allGrades.find(grade => grade.ordering === roundedOrdering); 

    return avgGrade ? avgGrade.name : '-';
  }

  render () {
    const {climb, allGrades, videoCount, isMobile} = this.props;

    if (!climb || !allGrades) {
      return <LoadingInline />
    } 

    if (isMobile) {
      return (
        <div className="climb-meta-container">
          <div className="climb-meta">
            {/* Num ascents  */}
            <p>{`${climb.ascent_count || 'N/A'} ${climb.ascent_count === 1 ? ASCENT_STRING : ASCENTS_STRING}`}</p>

            {/* Video count in KAYA */}
            { videoCount > 0 && 
              <p>{`| ${videoCount} ${videoCount === 1 ? VIDEO_STRING : VIDEOS_STRING} in KAYA`}</p>
            }
          </div>
          <div className="climb-meta">
            {/* Star rating */}
            <div className="climb-meta-star-rating"> 
              <FontAwesomeIcon icon={"star"} className="climb-meta-star-icon" />
              <p>{`${climb? Math.round(climb.rating * 10) / 10 : `N/A`},`}</p>
            </div>

            {/* User grade rating */}
            <p>
              {`Solid ${this.avgGradeName(climb, allGrades)} (${climb.num_reviews || 'N/A'} ${climb.num_reviews === 1 ? REVIEW_STRING : REVIEWS_STRING})`}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="climb-meta-container">
        <div className="climb-meta">
          {/* Num ascents  */}
          <p>{`${climb.ascent_count || 'N/A'} ${climb.ascent_count === 1 ? ASCENT_STRING : ASCENTS_STRING}`}</p>

          {/* Video count in KAYA */}
          { videoCount > 0 && 
            <p>{`| ${videoCount} ${videoCount === 1 ? VIDEO_STRING : VIDEOS_STRING} in KAYA`}</p>
          }

          <p>{" | "}</p>

          {/* Star rating */}
          <div className="climb-meta-star-rating"> 
            <FontAwesomeIcon icon={"star"} className="climb-meta-star-icon" />
            <p>{`${climb? Math.round(climb.rating * 10) / 10 : `N/A`},`}</p>
          </div>

          {/* User grade rating */}
          <p>
            {`Solid ${this.avgGradeName(climb, allGrades)} (${climb.num_reviews || 'N/A'} ${climb.num_reviews === 1 ? REVIEW_STRING : REVIEWS_STRING})`}
          </p>
        </div>
      </div>
    );
  }
}