import * as Amplitude from '@amplitude/analytics-browser';

export function initAmplitude() {
  Amplitude.init(process.env.REACT_APP_AMPLITUDE);
};

export function setAmplitudeUserDevice(deviceId) {
  Amplitude.setDeviceId(deviceId);
};

export function setAmplitudeUserId(userId) {
  Amplitude.setUserId(userId);
};

export function logEvent(eventType) {
  Amplitude.logEvent(eventType);
};

export function logEventWithProperties(eventType, eventProperties = {}) {
  Amplitude.logEvent(eventType, eventProperties);
};

export function logPageView(viewName, extraProperties = {}) {
  logEventWithProperties(EVENT.PAGE_VIEW, {
    [PROP.VIEW]: viewName,
    ...extraProperties,
  })
}

export const EVENT = {
  PAGE_VIEW: 'PAGE_VIEW',

  PAYMENT_METHOD_ADDED: 'PAYMENT_METHOD_ADDED',
  PAYMENT_METHOD_REMOVED: 'PAYMENT_METHOD_REMOVED',

  PURCHASE_COMPLETED: 'PURCHASE_COMPLETED',

  USAC_MEMBERSHIP_VERIFIED: 'USAC_MEMBERSHIP_VERIFIED',
  USAC_MEMBERSHIP_DECLINED: 'USAC_MEMBERSHIP_DECLINED',

  RECEIPT_CLICKED: 'RECEIPT_CLICKED',
  RECEIPT_LOAD_MORE: 'RECEIPT_LOAD_MORE',

  EVENTS_SEARCHED: 'EVENTS_SEARCHED',
  EVENTS_LOAD_MORE: 'EVENTS_LOAD_MORE',
  EVENT_OPEN_KAYA: 'EVENT_OPEN_KAYA',

  // Climb Page
  CLIMB_PAGE_SHARED: 'CLIMB_PAGE_SHARED',
  CLIMB_POST_LOAD_MORE: 'CLIMB_POST_LOAD_MORE',
  CLIMB_RELATED_CLIMB_CLICKED: 'CLIMB_RELATED_CLIMB_CLICKED',

  // Location Page
  LOCATION_PAGE_SHARED: 'LOCATION_PAGE_SHARED',
  LOCATION_POST_LOAD_MORE: 'LOCATION_POST_LOAD_MORE',
  LOCATION_AD_CLICKED: 'LOCATION_AD_CLICKED',
  LOCATION_CLIMB_SEARCH_CLIMB_CLICKED: 'LOCATION_CLIMB_SEARCH_CLIMB_CLICKED',
  LOCATION_CLIMB_SEARCH_FILTERED: 'LOCATION_CLIMB_SEARCH_FILTERED',
  LOCATION_CLIMB_SEARCH_SORTED: 'LOCATION_CLIMB_SEARCH_SORTED',
  LOCATION_CLIMB_SEARCH_SEARCHED: 'LOCATION_CLIMB_SEARCH_SEARCHED',
  LOCATION_CLIMB_SEARCH_LOAD_MORE: 'LOCATION_CLIMB_SEARCH_LOAD_MORE',
  LOCATION_AREA_CLICKED: 'LOCATION_AREA_CLICKED',
  LOCATION_OFFICIAL_CLICKED: 'LOCATION_OFFICIAL_CLICKED',

  // Ascent Section
  ASCENT_USER_CLICKED: 'ASCENT_USER_CLICKED',
  ASCENT_LOAD_MORE: 'ASCENT_LOAD_MORE',
  ASCENT_CLIMB_CLICKED: 'ASCENT_CLIMB_CLICKED',

  // Location Info
  LOCATION_INFO_WEATHER_CLICKED: 'LOCATION_INFO_WEATHER_CLICKED',
  LOCATION_INFO_ASCENTSCHART_CLICKED: 'LOCATION_INFO_ASCENTSCHART_CLICKED',
  LOCATION_INFO_APPROACH_CLICKED: 'LOCATION_INFO_APPROACH_CLICKED',

  // Post Lightbox
  POST_VIEWED: 'POST_VIEWED',
  POST_SHARED: 'POST_SHARED',
  POST_USER_CLICKED: 'POST_USER_CLICKED',
  
  // Media Link Lightbox
  MEDIALINK_VIEWED: 'MEDIALINK_VIEWED',

  // Global Search
  SEARCH_INITIATED: 'SEARCH_INITIATED',
  SEARCH_ITEM_CLICKED: 'SEARCH_ITEM_CLICKED',
  SEARCH_TAB_CLICKED: 'SEARCH_TAB_CLICKED',
  VIDEO_EMBED_COPIED: 'VIDEO_EMBED_COPIED',
  
  // User Profile
  USER_POST_LOAD_MORE: 'USER_POST_LOAD_MORE',
  USER_ASCENTS_FILTERED: 'USER_ASCENTS_FILTERED',
  USER_PROFILE_CLICK: 'USER_PROFILE_CLICK',
};

export const VIEW = {
  LOGIN: 'LOGIN',
  USER_ACCOUNT: 'USER_ACCOUNT',
  PURCHASE_EVENTS: 'PURCHASE_EVENTS',
  PURCHASE_EVENTS_PRODUCT: 'PURCHASE_EVENTS_PRODUCT',
  RECEIPTS: 'RECEIPTS',
  POST: 'POST',
  LOCATION: 'LOCATION',
  GYM: 'GYM',
  CLIMB: 'CLIMB',
  VIDEO_EMBED: 'VIDEO_EMBED',
  VIDEO_EMBED_COPIED: 'VIDEO_EMBED_COPIED',
};

export const PROP = {
  NAME: 'NAME',
  VIEW: 'VIEW',
  TYPE: 'TYPE',
  OFFSET: 'OFFSET',
  ORIGIN: 'ORIGIN',
  LOCATION: 'LOCATION',
};
