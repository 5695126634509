import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ClimbOffensiveChip() {
  return (
    <div className={"offensive-chip-container"} onClick={() => window.open(`https://kayaclimb.com/community-standards`)}>
      <FontAwesomeIcon 
        icon={"exclamation-circle"}
        className={"offensive-chip-icon"}
      />
      <p>This name violates community guidelines</p>
      <FontAwesomeIcon 
        icon={"chevron-right"}
        className={"offensive-chip-icon"}
      />
    </div>
  );
}