import gql from 'graphql-tag';
import POST_FRAGMENT from '../../fragment/webPost';

export default gql`

query webPost($post_id: ID!) {
  webPost(post_id: $post_id) {
    ...WebPostFields
  }
}

${POST_FRAGMENT}

`;
