import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFullDate } from '../../common/Date';
import { calcFinalCost } from '../../common/Stripe';

export default function ChallengeCard({ challenge, onClick, hideCTA }) {
  return (
    <div className="challenge-card" onClick={onClick}>
      <div className="img" style={{ backgroundImage: `url(${challenge.banner_photo_url})` }} />
      <div className="info">
        <div className="meta">     
          <h3>{challenge.name}</h3>
          <div className="bottom">
            <div className="date">
                <FontAwesomeIcon icon="calendar" />
                {getFullDate(challenge.date_start)} - {getFullDate(challenge.date_end)}
              </div>
            <div className="gym">
              {challenge.gym &&
                <div className="gym-name"><FontAwesomeIcon icon="map-marker-alt" /> <span>{challenge.gym.name}</span></div>
              }
            </div>
          </div>
        </div>
        <div className="payment">
        <div className="cost">
          {challenge.has_paid ?
            <span>PAID</span>
            :
            <span>${(calcFinalCost(challenge.entry_cost * 100) / 100).toFixed(2)}</span>
          }
        </div>
        {!hideCTA && !challenge.has_paid &&
          <div className="cta">
            <div className="btn-outline">BUY NOW</div>
          </div>
        }
        </div>
      </div>
    </div>
  )
}
