// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router';
import {ApolloFetchPolicy, LocationType} from '../../common/Definitions';
import {logPageView, logEventWithProperties, VIEW, EVENT, PROP} from '../../amplitude';
import { Breakpoints } from '../../common/LayoutStyle';
import { isSafari } from '../../common/util';
import classnames from 'classnames';

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import GET_GYM from '../../graphql/query/gym/webGym';

// components
import LoadingView from '../../component/Common/Misc/LoadingView';
import Footer from '../../component/App/Footer';
import {MainHeader} from "../../component/Common/Layout/MainHeader";
import MainNav from "../../container/Common/MainNav";
import AscentList from "../../container/Common/AscentList";
import LocationHeader from "../../container/Location/LocationHeader";
import GBModeratedDestinations from "../../container/Location/GBModeratedDestinations";
import LocationAdCard from "../../component/Location/LocationAdCard";
import {getGymUrl} from "../../common/Navigation";

class GymView extends Component {
  constructor(props) {
    super(props);

    this.isLoaded = false;

    this.state = {
      error: null,
      isMobile: window.innerWidth < Breakpoints.DESKTOP,
      checkMobileTimeout: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkMobile);
  }

  componentDidUpdate() {
    // Analytics
    if (this.props.webGym.webGym && !this.isLoaded) {
      this.isLoaded = true;
      logPageView(VIEW.GYM, {
        [PROP.NAME]: this.props.webGym.webGym.name,
      });
    }
  }

  checkMobile = () => {
    // Throttle Set State calls on Resize
    if (!this.state.checkMobileTimeout) {
      this.setState({
        checkMobileTimeout: setTimeout(() => {
          this.setState({
            isMobile: window.innerWidth < Breakpoints.DESKTOP,
            checkMobileTimeout: null,
          });
        }, 200),
      });
    }
  }

  _getVideoCount = (videos, mediaLinks) => {
    if (!videos && !mediaLinks) {
      return 0;
    }
    const numVideos = videos.length || 0;
    const numMediaLinks = mediaLinks.length || 0;

    return numVideos + numMediaLinks;
  }

  _getPageTitle = (location) => {
    if (!location) {
      return;
    }
    return `${location.name} - KAYA | The Climber's App`;
  }

  _getPageDescription = (location) => {
    if (!location) {
      return
    }

    return `View more beta videos and information about ${location.name} on KAYA. Connect with climbers in your community and globally and track your climbing with KAYA.`;
  }
  render() {
    const gym = this.props.webGym.webGym;

    // // Attempt to open climb page in app - ignore if Safari due buggy redirect issues
    // if (climb && !isSafari()) {
    //   window.location = `kaya://kaya-app.kayaclimb.com/share/climb?climb_id=${climb.id}`;
    // }

    if (!gym) {
      return (
        <div className="container gym-view main-view">
          <MainHeader />
          <LoadingView className="main-view-loading"  />
        </div>
      )
    }

    return (
      <div className="container gym-view main-view" >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this._getPageTitle(gym)}</title>
          <meta name="description" content={this._getPageDescription(gym)} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={this._getPageTitle(gym)} />
          <meta property="og:description" content={this._getPageDescription(gym)} />
          <meta property="og:image" content={gym.photo_url} />
          <meta property="og:image:url" content={gym.photo_url} />
          <meta property="og:url" content={`https://kaya-app.kayaclimb.com/gym/${this.props.match.params.gym_slug}`} />
          <meta property="og:site_name" content="KAYA" />
          <meta property="og:image:width" content="1500" />
          <meta property="og:image:height" content="1013" />
          <link rel="canonical" href={getGymUrl(gym.slug)} />
        </Helmet>

        <MainHeader />

        <MainNav
          gym={gym}
        />

        <LocationHeader
          gym={gym}
          isMobile={this.state.isMobile}
        />

        <AscentList
          gym={gym}
          isMobile={this.state.isMobile}
        />

        <LocationAdCard mobile={this.state.isMobile} />

        <GBModeratedDestinations />

        <Footer />
      </div>
    );
  }
}

// QUERIES
const withGym = graphql(GET_GYM, {
  name: 'webGym',
  options: (props) => {
    return {
      variables: {
        slug: props.match.params.gym_slug,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

export default withRouter(compose(
  withGym,
  withGrades,
)(GymView));
