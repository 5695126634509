import axios from 'axios';
import { action, axiosReq } from './util';
import { validateEmail, validatePassword } from '../common/Validator';
import { LocalStorageKey } from '../common/Definitions';
import { setAuthToken, clearAuthToken } from '../common/Auth';
import config from '../config';

export const AuthActionType = {
  LOGIN: 'LOGIN',
  LOGGING_IN: 'LOGGING_IN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  CHECK_AUTH: 'CHECK_AUTH',
  CHECKING_AUTH: 'CHECKING_AUTH',
  TOKEN_REFRESHING: 'TOKEN_REFRESHING',
  TOKEN_REFRESH_COMPLETE: 'TOKEN_REFRESH_COMPLETE',
};

export function checkAuth() {
  return async dispatch => {
    dispatch(checkingAuth());
    try {
      const { data: { message, user, version } } =
        await axiosReq().post('/api/user/checkauth');
      if (message === 'ok') {
        dispatch(loginSuccess(user));
      } else {
        dispatch(logout());
      }

      // Client Server version check
      try {
        const { data: newVersion } = await axios.get('/version.txt');
        const lastRefreshedVersion = localStorage.getItem(LocalStorageKey.LAST_REFRESHED_VERSION);
        // If haven't refreshed this version, reload
        if (lastRefreshedVersion !== newVersion) {
          localStorage.setItem(LocalStorageKey.LAST_REFRESHED_VERSION, newVersion);
          // Bust cache if version mismatch and previous version was set
          if (lastRefreshedVersion) {
            if (caches) {
              caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
              });
            }

            // delete browser cache and hard reload
            window.location.reload(true);
          }
        }
      } catch (error2) {
        console.log(error2);
        // continue
      }
    } catch (error) {
      console.log(error);
      dispatch(logout());
    }
  };
}

export function checkingAuth() {
  return action(AuthActionType.CHECKING_AUTH);
}

export function login(email, password) {
  let validateError;
  if (!validateEmail(email)) {
    validateError = `Invalid Email Format: ${email}`;
  } else if (!validatePassword(password)) {
    validateError = 'Invalid Password Format. Must be 6-36 characters long.';
  }
  if (validateError) {
    return loginError(validateError);
  }

  return async dispatch => {
    dispatch(loginLoading());
    try {
      const { data } = await axiosReq().post('/api/user/login', {
          email,
          password,
        });
      
      if (data.error) {
        // Login Error
        dispatch(loginError(data.error));
      } else {
        _handleLogin(dispatch, data);
      }
    } catch (error) {
      console.log(error);
      const message = error.response && error.response.data ? error.response.data.error : 'Error occurred during Login';
      dispatch(loginError(message));
    }
  };
}


export function fbLogin(fbToken) {
  return async dispatch => {
    dispatch(loginLoading());

    // If no auth token provided, login via FB
    let authToken = fbToken;
    
    // Login user with auth token; otherwise, throw error if none provided
    if (authToken) {
      try {
        const axios = await axiosReq();
        const { data } =
          await axios.post('/api/user/login', {
            token: authToken,
          });

        if (data.error) {
          // Login Error
          dispatch(loginError(data.error));
        } else if (data.message === 'register') {
        // User attempted to login with unregistered account -- redirect to Registration; otherwise, success
          dispatch(loginError('User with this email does not exist yet. Please first create a new user on the KAYA app to login here.'));
        } else {
          _handleLogin(dispatch, data);
        }
      } catch (error) {
        console.log(error);
        const message = error.response && error.response.data ? error.response.data.error : 'Error occurred during Login';
        dispatch(loginError(message));
      }
    } else {
      // Handle errors here.
      dispatch(loginError('Invalid Facebook Login'));
    }
  };
}

export function googleLogin(gToken) {
  return async dispatch => {
    dispatch(loginLoading());

    // If no auth token provided, login via Google
    let authToken = gToken;

    // Login user with auth token; otherwise, throw error if none provided
    if (authToken) {
      try {
        const axios = await axiosReq();
        const { data } =
          await axios.post('/api/user/login', {
            token: authToken,
          });
          
        if (data.error) {
          // Login Error
          dispatch(loginError(data.error));
        } else if (data.message === 'register') {
        // User attempted to login with unregistered account -- redirect to Registration; otherwise, success
          dispatch(loginError('User with this email does not exist yet. Please first create a new user on the KAYA app to login here.'));
        } else {
          _handleLogin(dispatch, data);
        }
      } catch (error) {
        console.log(error);
        const message = error.response && error.response.data ? error.response.data.error : 'Error occurred during Login';
        dispatch(loginError(message));
      }
    } else {
      // Handle errors here.
      dispatch(loginError('Invalid Google Login'));
    }
  };
}

export function appleLogin(token) {
  return async dispatch => {
    dispatch(loginLoading());

    try {
      if (token) {
        try {
          const axios = await axiosReq();
          const { data } =
            await axios.post('/api/user/login-apple-web', {
              token,
            });
            
          if (data.error) {
            // Login Error
            dispatch(loginError(data.error));
          } else if (data.message === 'register') {
          // User attempted to login with unregistered account -- redirect to Registration; otherwise, success
            dispatch(loginError('User with this email does not exist yet. Please first create a new user on the KAYA app to login here.'));
          } else {
            _handleLogin(dispatch, data);
          }
        } catch (error) {
          console.log(error);
          const message = error.response && error.response.data ? error.response.data.error : 'Error occurred during Apple Login';
          dispatch(loginError(message));
        }
      } else {
        // Handle errors here.
        dispatch(loginError('Invalid Apple Login. We could not obtain credentials from Apple that are required for login.'));
      }
    } catch (err) {
      // continue  
      console.log(err);
    }
  };
}

export function loginLoading() {
  return action(AuthActionType.LOGGING_IN);
}

export function loginSuccess(user) {
  return action(AuthActionType.LOGIN_SUCCESS, {
    user,
  });
}

export function loginError(error) {
  return action(AuthActionType.LOGIN_FAILURE, {
    error,
  });
}

export function logout(callback) {
  return async dispatch => {
    // invalidate refresh token
    const refresh_token = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);
    if (refresh_token) {
      try {
        await axiosReq().post('/api/user/logout', {
          refresh_token,
        });
      } catch (err) {
        // continue
      }
    }

    // Remove local storage keys
    localStorage.removeItem(LocalStorageKey.USER_ID);
    localStorage.removeItem(LocalStorageKey.USER_SLUG);
    localStorage.removeItem(LocalStorageKey.REFRESH_TOKEN);
    clearAuthToken();

    // Legacy storage key removal of auth_token
    localStorage.removeItem(LocalStorageKey.TOKEN);

    dispatch(action(AuthActionType.LOGOUT));

    // onLogout callback
    if (callback) {
      callback();
    }
  };
}

export function refreshingToken() {
  return action(AuthActionType.TOKEN_REFRESHING);
}

export function refreshTokenComplete() {
  return action(AuthActionType.TOKEN_REFRESH_COMPLETE);
}

export function refreshToken() {
  return async dispatch => {
    dispatch(refreshingToken());
    try {
      // Refresh auth_token using refresh_token jwt
      const refresh_token = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);
      if (refresh_token) {
        const { data: { message, token } } =
          await axiosReq().post('/api/user/refresh-token', {
            refresh_token,
          });

        // Save refreshed auth_token in-memory
        if (message === 'ok' && token) {
          setAuthToken(token);
        }
      }
      dispatch(refreshTokenComplete());
    } catch (error) {
      // Logout for unauthorized response; otherwise, continue polling
      if (error.response && error.response.status === 401) {
        dispatch(logout());
      } else {
        dispatch(refreshTokenComplete());
      }
    }
  };
}

async function _handleLogin(dispatch, { message, token, refresh_token, user }) {
  if (message === 'ok' && token && user) {
    localStorage.setItem(LocalStorageKey.USER_ID, user.id);
    localStorage.setItem(LocalStorageKey.USER_SLUG, user.slug);
    localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, refresh_token);
    // save auth_token in-memory
    setAuthToken(token);
    dispatch(loginSuccess(user));
  } else {
    dispatch(loginError('Unsuccessful Login'));
  }
}