// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';
import * as moment from 'moment';

// queries
import GET_MODERATORS from "../../graphql/query/location/webModeratorsForDestination";
import GET_VIDEOS_LOCATION from "../../graphql/query/location/webPostsForLocation";
import GET_VIDEOS_GYM from "../../graphql/query/gym/webPostsForGym";
import GET_MEDIALINKS from "../../graphql/query/location/webMediaLinksForLocation";
import GET_MEDIALINKS_fOR_GYM from "../../graphql/query/gym/webMediaLinksForGym";

// relative
import {ApolloFetchPolicy} from "../../common/Definitions";
import {postItemClimb, postItemImageUrl, postItemVideoThumbnailUrl} from "../../common/PostItem";
import {getClimbName, getClimbLocationName} from "../../common/Climb";
import {getClimbUrl, getGymUrl, getLocationUrl, getPostUrl, getProfileUrl} from "../../common/Navigation";
import config from '../../config';

// components
import PostLightbox from "../../component/Common/Media/PostLightbox";
import GET_POST from "../../graphql/query/post/getWebPost";
import ProfileImg from "../../component/User/ProfileImg";
import {EVENT, logEventWithProperties, PROP} from "../../amplitude";
import MediaLinkLightbox from "../../component/Common/Media/MediaLinkLightbox";
import {getCdnUri} from "../../common/Media";
import LoadingInline from "../../component/Common/Misc/LoadingInline";

const MOBILE_DISPLAY_COUNT = 2;
const DISPLAY_COUNT = 4;
const PAGE_COUNT = 12;

class LocationHeader extends Component {
  constructor(props) {
    super(props);
    
    this.queryName = props.location ? 'webPostsForLocation' : 'webPostsForGym';
    this.mediaLinksQueryName = props.location ? 'webClimbMediaLinksForLocation' : 'webClimbMediaLinksForGym';

    this.state = {
      currentPageIdx: 0,
      offset: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.webPost && prevProps.webPost) {
      if (!prevProps.webPost.webPost && this.props.webPost.webPost) {
        const hash = window.location.hash?.split('-') || [];
        const postItemId = hash[1];
        this.setState({
          selectedPost: this.props.webPost.webPost,
          selectedPostItem: this.props.webPost.webPost.items.find(pi => pi.id === postItemId),
        });
      }
    }
  }

  goToPage = (idx) => {
    this._fetchMore();

    this.setState({
      currentPageIdx: idx,
    });

    // Analytics
    logEventWithProperties(EVENT.LOCATION_POST_LOAD_MORE);
  }

  _fetchMore = () => {
    this.setState({
      fetchingMore: true,
      offset: this.state.offset + PAGE_COUNT,
    }, () => {
      this.props[this.queryName].fetchMore({
        variables: { offset: this.state.offset },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;

          const moreToLoad = fetchMoreResult[this.queryName].length > 0;

          this.setState({
            fetchingMore: false,
            moreToLoad,
          });

          const posts = [...prev[this.queryName], ...fetchMoreResult[this.queryName]];

          // Append new data to old data
          return Object.assign({}, prev, {
            [this.queryName]: posts,
          });
        },
      }).catch(error => {
        console.log(error);
        this.setState({
          fetchingMore: false,
          error,
        });
      });

      this.props[this.mediaLinksQueryName].fetchMore({
        variables: { offset: this.state.offset },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!fetchMoreResult) return prev;

          const moreMediaLinksToLoad = fetchMoreResult[this.mediaLinksQueryName].length > 0;

          this.setState({
            moreMediaLinksToLoad,
          });

          const posts = [...prev[this.mediaLinksQueryName], ...fetchMoreResult[this.mediaLinksQueryName]];

          // Append new data to old data
          return Object.assign({}, prev, {
            [this.mediaLinksQueryName]: posts,
          });
        },
      }).catch(error => {
        console.log(error);
        this.setState({
          error,
        });
      });
    });
  }

  _getPosts = () => {
    const data = this.props[this.queryName][this.queryName];
    return data || [];
  }

  _getMediaLinks = () => {
    const data = this.props[this.mediaLinksQueryName][this.mediaLinksQueryName];
    return data || [];
  }

  _getDisplayCount = () => {
    const {
      isMobile,
    } = this.props;

    return isMobile ? MOBILE_DISPLAY_COUNT : DISPLAY_COUNT;
  }

  renderMediaLink = (mediaLink) => {
    const climb = mediaLink.climb;
    return (
      <div
        key={mediaLink.id}
        className="post"
      >
        <div
          className="post-thumb"
          onClick={() => this.setState({ selectedMediaLink: mediaLink })}
        >
          <img
            src={mediaLink.thumbnail_url}
            alt={`Beta Video for climb ${getClimbName(climb, false, true, true)}`}
          />
          <div className="overlay">
            <div />
            <div className='icon-play-circle-outlined' />
            <div className="user" />
          </div>
        </div>
        <a
          href={getClimbUrl(climb.slug)}
          target="_blank"
          className="climb-info"
        >
          <div className="climb-name">{getClimbName(climb, true)}</div>
          <div className="climb-area">{getClimbLocationName(climb)}</div>
        </a>
      </div>
    );
  }

  renderPost = (post, itemsInRow) => {
    const climb = postItemClimb(post.items[0]);

    // Leverage CDN to display smallest thumbnail as possible
    const imgWidth = 1600 / itemsInRow;

    return (
      <div
        key={post.id}
        className="post"
      >
        <div
          className="post-thumb"
          onClick={() => this.setState({ selectedPost: post })}
        >
          <img
            src={getCdnUri(postItemVideoThumbnailUrl(post.items[0]) || postItemImageUrl(post.items[0]), imgWidth)}
            alt={`Beta Video from ${post.user.username} for climb ${getClimbName(postItemClimb(post.items[0]), false, true, true)}`}
          />
          <a className="post-link" href={getPostUrl(climb.slug, post.id, post.items[0].id)} />
          <div className="overlay">
            <div />
            <div className='icon-play-circle-outlined' />
            <a 
              className="user"
              target="_top"
              href={getProfileUrl(post.user.username)}
              onClick={() => {
                // Analytics
                logEventWithProperties(EVENT.USER_PROFILE_CLICK);
              }}
            >
              <ProfileImg
                src={getCdnUri(post.user.thumbnail_url || post.user.photo_url, 80)}
                size={"small"}
                placeholderIconSize={"2x"}
              />
              <div className="user-meta">
                <div className="name">
                  {post.user.fname} {post.user.lname}
                </div>
                <div className="username">
                  @{post.user.username}
                </div>
              </div>
            </a>
          </div>
        </div>
        <a
          href={getClimbUrl(climb.slug)}
          target="_blank"
          className="climb-info"
        >
          <div className="climb-name">{getClimbName(climb, true)}</div>
          <div className="climb-area">{getClimbLocationName(climb)}</div>
        </a>
      </div>
    );
  }

  renderVideoCarousel = () => {
    const displayCount = this._getDisplayCount();
    const rowsPerPage = 2;
    const posts = this._getPosts();
    const mediaLinks = this._getMediaLinks();
    const totalCount = (this.state.currentPageIdx * displayCount * rowsPerPage) + (displayCount * rowsPerPage);
    const postsToDisplay = posts.slice(0, totalCount);
    const mediaLinksToDisplay = totalCount > postsToDisplay.length ? mediaLinks.slice(0, totalCount - postsToDisplay.length) : [];
    const formattedData = [];
    postsToDisplay.forEach(p => {
      formattedData.push({
        id: p.id,
        post: p,
        mediaLink: null,
      });
    });
    mediaLinksToDisplay.forEach(m => {
      formattedData.push({
        id: m.id,
        post: null,
        mediaLink: m,
      });
    });
    const isLoadMoreInactive = formattedData.length < totalCount;

    const rowsMarkup = [];
    for (let rowIdx = 0; rowIdx < Math.ceil(formattedData.length / displayCount); rowIdx++) {
      const items = formattedData.slice(rowIdx * displayCount, (rowIdx * displayCount) + displayCount);
      const placeholderCount = displayCount - items.length;
      let placeholders = []; 
      
      if (placeholderCount > 0) {
        for (let i = 0; i < placeholderCount; i++) {
          placeholders.push(i); 
        }
      }
      
      const rowMarkup = (
        <div
          className="row"
          key={rowIdx}
        >
          {
            items.map(item => {
              return item.post ? this.renderPost(item.post, items.length) : this.renderMediaLink(item.mediaLink)
            })
          }
          { 
            placeholders.map(p => {
              return <div className='placeholder'/>;
            })
          }
        </div>
      );
      rowsMarkup.push(rowMarkup);
    }

    return (
      <div className="post-carousel">
        <div className="wrap">
          {rowsMarkup.length === 0 && (this.props.webPostsForLocation?.loading || this.props.webPostsForGym?.loading) &&
            <div style={{ height: '750px', textAlign: 'center', marginTop: '200px' }}>
              <LoadingInline />
            </div>
          }
          {rowsMarkup}
          { !isLoadMoreInactive &&
            <div className="load-more-container">
              <div
                className={'load-more-cta'}
                onClick={() => this.goToPage(this.state.currentPageIdx + 1)}
              >
                LOAD MORE
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  renderRating = (climb) => {
    let rating = 'N/A';
    if (climb.rating) {
      rating = parseFloat(climb.rating).toFixed(1);
    }
    return (
      <span className="rating group-title">
        <div className="icon-star" /> {rating}
      </span>
    );
  }

  renderAscentsCount = (climb) => {
    let ascentString = 'Ascents';
    if (climb.ascent_count === 1) {
      ascentString = 'Ascent';
    }
    return (
      <span className="ascent-count">
        {climb.ascent_count} Ascents
      </span>
    )
  }

  renderShare = (url) => {
    return (
      <div
        className="share"
        onClick={() => {
          navigator.clipboard.writeText(url);
          this.setState({
            hasShared: true,
          }, () => {
            setTimeout(() => {
              this.setState({
                hasShared: false,
              });
            }, 5000);
          })

          // Analytics
          logEventWithProperties(EVENT.LOCATION_PAGE_SHARED, {
            [PROP.NAME]: url,
          });
        }}
      >
        <div className="icon-send-outlined" />
        { !this.state.hasShared && 'Share' }
        { this.state.hasShared &&
          <div className="copied">
            Copied
          </div>
        }
      </div>
    )
  }

  renderLocationMeta = (location) => {
    const webModeratorsForDestination = this.props.webModeratorsForDestination.webModeratorsForDestination;
    const hasLongLocation = (location.description + location.access_description)?.length > 250;

    return (
      <div className="location-header-meta">
        <div className="title">
          <h1>{location.name}</h1>
          {(location.is_gb_moderated_bouldering || location.is_gb_moderated_routes) &&
            <img
              src={require('../../lib/img/verified-icon.png')}
              className="verified"
            />
          }
        </div>
        <div className="meta">
          <div className="group">
            <span className="group-title">Last Updated:</span> {moment().format('MMM, YYYY')}
          </div>
          { this.renderShare(`${config.uri}${getLocationUrl(location.slug)}`) }
        </div>
        <div className="info">
          { webModeratorsForDestination && webModeratorsForDestination.length > 0 &&
            <div className="moderators">
              Moderated by {webModeratorsForDestination.map(m => `${m.fname} ${m.lname}`).join(' & ')}
            </div>
          }
          { location.description &&
            <div className="description">
              <pre>
                { hasLongLocation && !this.state.shouldShowFullDescription ? `${location.description.slice(0, 250)}... ` : `${location.description} ` }
                { hasLongLocation && this.state.shouldShowFullDescription && `${location.access_description} ` }
                <span onClick={() => this.setState({ shouldShowFullDescription: !this.state.shouldShowFullDescription })}>{this.state.shouldShowFullDescription ? 'Show less' : 'Show more'}</span>
              </pre>
            </div>
          }
        </div>
        <div className="cta">
          <a
            href="https://kayaclimb.com/download"
            className="cta-download"
            target="_blank"
          >
            Download KAYA
          </a>
        </div>
      </div>
    );
  }

  renderGymMeta = (gym) => {
    return (
      <div className="location-header-meta">
        <div className="title">
          <h1>{gym.name}</h1>
          <img
            src={require('../../lib/img/verified-icon.png')}
            className="verified"
          />
        </div>
        <div className="meta">
          { this.renderShare(`${config.uri}${getGymUrl(gym.slug)}`) }
        </div>
        <div className="cta">
          <a
            href="https://kayaclimb.com/download"
            className="cta-download"
            target="_blank"
          >
            Download KAYA for more info
          </a>
        </div>
      </div>
    );
  }

  render() {
    const {
      location,
      gym,
    } = this.props;

    return (
      <div className="location-header" >
        <div className="multi-post-banner">
          <div className="wrap">
            {location && this.renderLocationMeta(location)}
            {gym && this.renderGymMeta(gym)}
          </div>
          {this.renderVideoCarousel()}
        </div>

        {this.state.selectedPost &&
          <PostLightbox
            post={this.state.selectedPost}
            postItem={this.state.selectedPostItem}
            onClose={() => this.setState({ selectedPost: null, selectedPostItem: null })}
            origin={'location'}
          />
        }

        {this.state.selectedMediaLink &&
          <MediaLinkLightbox
            mediaLink={this.state.selectedMediaLink}
            onClose={() => this.setState({ selectedMediaLink: null })}
            origin={'location'}
          />
        }
      </div>
    );
  }
}

function getPostVariables() {
  const hash = window.location.hash;
  if (hash) {
    const hashSplit = hash.replace('#', '').split('-');
    if (hashSplit.length === 2) {
      const post_id = hashSplit[0];
      const post_item_id = hashSplit[1];
      return {
        post_id,
        post_item_id,
      };
    }
  }
  return null
}

// QUERIES
const withVideosForLocation = graphql(GET_VIDEOS_LOCATION, {
  name: 'webPostsForLocation',
  options: (props) => {
    return {
      variables: {
        location_id: props.location.id,
        offset: 0,
        count: PAGE_COUNT,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
  skip: (props) => !props.location,
});

const withVideosForGym = graphql(GET_VIDEOS_GYM, {
  name: 'webPostsForGym',
  options: (props) => {
    return {
      variables: {
        gym_id: props.gym.id,
        offset: 0,
        count: PAGE_COUNT,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
  skip: (props) => !props.gym,
});

const withModerators = graphql(GET_MODERATORS, {
  name: 'webModeratorsForDestination',
  options: (props) => {
    return {
      variables: {
        destination_id: props.location.id,
      },
    };
  },
  skip: (props) => !props.location,
});

const withMediaLinks = graphql(GET_MEDIALINKS, {
  name: 'webClimbMediaLinksForLocation',
  options: (props) => {
    return {
      variables: {
        location_id: props.location.id,
        offset: 0,
        count: PAGE_COUNT,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
  skip: (props) => !props.location,
});

const withMediaLinksForGym = graphql(GET_MEDIALINKS_fOR_GYM, {
  name: 'webClimbMediaLinksForGym',
  options: (props) => {
    return {
      variables: {
        gym_id: props.gym.id,
        offset: 0,
        count: PAGE_COUNT,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
  skip: (props) => !props.gym,
});

const withWebPost = graphql(GET_POST, {
  name: 'webPost',
  options: (props) => {
    const variables = getPostVariables();
    return {
      variables,
    };
  },
  skip: (props) => !getPostVariables(),
});

export default compose(
  withVideosForGym,
  withVideosForLocation,
  withModerators,
  withMediaLinks,
  withMediaLinksForGym,
  withWebPost,
)(LocationHeader);
