// react
import React from 'react';
import classnames from 'classnames';

const STAR_FULL_CLASSNAME = 'icon-star';
const STAR_HALF_CLASSNAME = 'icon-star-half';
const STAR_EMPTY_CLASSNAME = 'icon-star-outlined';

function getStarClassname(rating, starCount) {
  if (rating >= starCount) {
    return STAR_FULL_CLASSNAME;
  }
  if (rating >= starCount - 0.5) {
    return STAR_HALF_CLASSNAME;
  }
  return STAR_EMPTY_CLASSNAME;
}

export default function ClimbRating({ className, dark, rating, ascentCount, stiffness, small }) {
  const stars = [1, 2, 3, 4, 5];

  return (
    <div className={classnames('climb-rating', dark && 'dark', small && 'small', className)}>
      <div className="stars">
        {stars.map(star => (
          <div
            key={star}
            className={getStarClassname(rating, star)}
          />
        ))}
      </div>
      <div className="climb-rating-meta">
        <div className="rating">{parseFloat(rating) >= 0 ? parseFloat(rating).toFixed(1) : 'N/A'}</div>
        {ascentCount > 0 &&
          <div className="count">({ascentCount})</div>
        }
        {stiffness &&
          <div className="stiffness">{stiffness}</div>
        }
      </div>
    </div>
  );
}
