import gql from 'graphql-tag';
import LINK_FRAGMENT from '../../fragment/climbMediaLink';

export default gql`

    query webClimbMediaLinksForGym($gym_id: ID!, $offset: Int!, $count: Int!) {
        webClimbMediaLinksForGym(gym_id: $gym_id, offset: $offset, count: $count) {
            ...WebClimbMediaLinkFields
        }
    }

    ${LINK_FRAGMENT}

`;
