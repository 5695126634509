import { isEmptyString } from './util';

export function postItemCaption(postItem) {
  if (!postItem) {
    return;
  }

  return (!isEmptyString(postItem.caption) && postItem.caption) ||
    (postItem.ascent && !isEmptyString(postItem.ascent.comment) && postItem.ascent.comment);
}

export function postItemImageUrl(postItem) {
  if (!postItem) {
    return;
  }

  return (postItem.photo_url && postItem.photo_url) ||
    (postItem.ascent && postItem.ascent.photo?.photo_url) ||
    (postItem.poster && postItem.poster.photo_url);
}

export function postItemVideoUrl(postItem) {
  if (!postItem) {
    return;
  }

  return (postItem.video_url && postItem.video_url) ||
    (postItem.ascent && postItem.ascent.video?.video_url);
}

export function postItemVideoThumbnailUrl(postItem) {
  if (!postItem) {
    return;
  }

  return (postItem.video_thumbnail_url && postItem.video_thumbnail_url) ||
    (postItem.ascent && postItem.ascent.video?.thumb_url);
}

export function postItemClimb(postItem) {
  if (!postItem) {
    return;
  }

  return postItem.climb || (postItem.ascent && postItem.ascent.climb);
}

// Unfortunately video thumbnail on postItem is video_thumbnail_url and
// on other fragments it's video_thumb_url..
export function getPostItemProperty(property) {
  let postItemProperty = property;
  if (property === 'video_thumb_url') {
    postItemProperty = 'video_thumbnail_url';
  }
  return postItemProperty;
}
