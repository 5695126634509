// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';
import { ApolloFetchPolicy } from '../../common/Definitions';
import { getLocationUrl } from '../../common/Navigation';
import {EVENT, PROP, logEventWithProperties} from "../../amplitude";

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import LOCATIONS_FOR_LOCATION from '../../graphql/query/location/webLocationsForLocation';

class AreaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  
  _renderSpacers = (spacerNum) => {
    const spacers= [];
    for (let i = 0; i < spacerNum; i++) {
      spacers.push(<div className='area-link' />);
    }
    return spacers; 
  }
  
  render() {
    const locations = this.props.webLocationsForLocation.webLocationsForLocation; 

    if (!this.props.location || !locations) {
      return <div /> 
    }
    const spacerDivsToRender = locations.length % 3; 
    
    return (
      <div className={classnames('area-list', 'wrap', this.props.isMobile && 'mobile')}>
        <div className={classnames('area-list-title', this.props.isMobile && 'mobile')}>
          <div className='title'>{`Areas in ${this.props.location.name}`}</div>
          <div className='subtitle'>{`Explore ${this.props.location.name} from all levels with KAYA PRO. Drill down to the area, subarea, boulder and climb levels. Get approach information for each, and a variety of beta. `}</div>
          <div 
            className='download-kaya-button'
            onClick={() => window.open("https://kayaclimb.com/download")}
          >
            <div
              className="download-kaya-button-text"
            >
              Download KAYA
            </div>
          </div>       
        </div>
        <div className='area-list-links'>
          { locations.map(location => {
            return (
              <a
                className="area-link"
                href={getLocationUrl(location.slug)}
                target="_blank"
                onClick={() => {
                  // Analytics
                  logEventWithProperties(EVENT.LOCATION_AREA_CLICKED, {
                    [PROP.LOCATION]: this.props.location ? this.props.location.name : null,
                  });}
                }
              >
                <div>{location.name}</div>
              </a>
            );
            })
          }
          {this._renderSpacers(spacerDivsToRender) } 
        </div>
      </div>
    );
  }
}

// QUERIES
const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

const withLocationsForLocation = graphql(LOCATIONS_FOR_LOCATION, {
  name: 'webLocationsForLocation',
  options: (props) => {
    return {
      variables: {
        location_id: props.location?.id,
        offset: 0, 
        count: 50, // Should capture all areas for most locations  
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

export default compose(
  withGrades,
  withLocationsForLocation,
)(AreaList);
