/**
 * In-memory getter/setter for Auth Token.
 * Auth tokens contain user-sensitive data and shouldn't persist beyond the session.
 */

let authToken;

export function setAuthToken(token) {
  authToken = token;
}

export function getAuthToken() {
  return authToken;
}

export function clearAuthToken() {
  authToken = null;
}
