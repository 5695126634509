import gql from 'graphql-tag';
import ASCENT_FRAGMENT from '../../fragment/webAscent';

export default gql`

    query webAscentsForClimb($climb_id: ID!, $count: Int!, $offset: Int!) {
        webAscentsForClimb(climb_id: $climb_id, count: $count, offset: $offset) {
            ...WebAscentFields
        }
    }

    ${ASCENT_FRAGMENT}

`;
