import {ASSETS_BASE_URL} from "./Definitions";

/**
 * Convert media asset URI from assets.plastick.rocks to a compressed version via Cloudflare CDN.
 * @param uri
 * @param width
 * @param quality
 * @returns {string} URI of Cloudflare CDN compressed version of media asset.
 */
export function getCdnUri(uri, width = 200, quality = 70) {
  // Prepend cdn relevant prefix to leverage cdn resizing/caching
  if (uri && uri.includes(ASSETS_BASE_URL)) {
    return `${uri}?w=${parseInt(width, 10)}&q=${quality}`;
  }
  return uri;
}
