import { UnitType } from './Definitions';

export const INCHES_PER_FOOT = 12;
export const CENTIMETERS_PER_INCH = 2.54;
export const N_A_STRING = 'N/A';

export const DEFAULT_HEIGHT_FT = CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5;
export const DEFAULT_HEIGHT_IN = CENTIMETERS_PER_INCH * 5;
export const DEFAULT_HEIGHT = (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 5);

// equivalent to 3ft to 8ft range
export const UserHeightCentimeters = getCentimeterIncrementsList(90, 243);

// equivalent to -2ft to +2ft
export const UserReachCentimeters = getCentimeterIncrementsList(-60, 60, true);

export const UserHeightsFeetInches = [
  { id: 1, name: '3\'0\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) },
  { id: 2, name: '3\'1\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH) },
  { id: 3, name: '3\'2\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 2) },
  { id: 4, name: '3\'3\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 3) },
  { id: 5, name: '3\'4\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 4) },
  { id: 6, name: '3\'5\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 5) },
  { id: 7, name: '3\'6\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 6) },
  { id: 8, name: '3\'7\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 7) },
  { id: 9, name: '3\'8\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 8) },
  { id: 10, name: '3\'9\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 9) },
  { id: 11, name: '3\'10\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 10) },
  { id: 12, name: '3\'11\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3) + (CENTIMETERS_PER_INCH * 11) },
  { id: 13, name: '4\'0\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) },
  { id: 14, name: '4\'1\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH) },
  { id: 15, name: '4\'2\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 2) },
  { id: 16, name: '4\'3\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 3) },
  { id: 17, name: '4\'4\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 4) },
  { id: 18, name: '4\'5\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 5) },
  { id: 19, name: '4\'6\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 6) },
  { id: 20, name: '4\'7\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 7) },
  { id: 21, name: '4\'8\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 8) },
  { id: 22, name: '4\'9\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 9) },
  { id: 23, name: '4\'10\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 10) },
  { id: 24, name: '4\'11\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4) + (CENTIMETERS_PER_INCH * 11) },
  { id: 25, name: '5\'0\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) },
  { id: 26, name: '5\'1\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH) },
  { id: 27, name: '5\'2\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 2) },
  { id: 28, name: '5\'3\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 3) },
  { id: 29, name: '5\'4\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 4) },
  { id: 30, name: '5\'5\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 5) },
  { id: 31, name: '5\'6\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 6) },
  { id: 32, name: '5\'7\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 7) },
  { id: 33, name: '5\'8\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 8) },
  { id: 34, name: '5\'9\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 9) },
  { id: 35, name: '5\'10\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 10) },
  { id: 36, name: '5\'11\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5) + (CENTIMETERS_PER_INCH * 11) },
  { id: 37, name: '6\'0\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) },
  { id: 38, name: '6\'1\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH) },
  { id: 39, name: '6\'2\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 2) },
  { id: 40, name: '6\'3\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 3) },
  { id: 41, name: '6\'4\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 4) },
  { id: 42, name: '6\'5\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 5) },
  { id: 43, name: '6\'6\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 6) },
  { id: 44, name: '6\'7\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 7) },
  { id: 45, name: '6\'8\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 8) },
  { id: 46, name: '6\'9\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 9) },
  { id: 47, name: '6\'10\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 10) },
  { id: 48, name: '6\'11\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6) + (CENTIMETERS_PER_INCH * 11) },
  { id: 49, name: '7\'0\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) },
  { id: 50, name: '7\'1\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH) },
  { id: 51, name: '7\'2\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 2) },
  { id: 52, name: '7\'3\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 3) },
  { id: 53, name: '7\'4\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 4) },
  { id: 54, name: '7\'5\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 5) },
  { id: 55, name: '7\'6\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 6) },
  { id: 56, name: '7\'7\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 7) },
  { id: 57, name: '7\'8\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 8) },
  { id: 58, name: '7\'9\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 9) },
  { id: 59, name: '7\'10\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 10) },
  { id: 60, name: '7\'11\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7) + (CENTIMETERS_PER_INCH * 11) },
  { id: 61, name: '8\'0\'\'', value: (CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 8) },
];

// TODO: Move heights to the server?
export const UserHeightsFeet = [
  { name: '1\'', value: CENTIMETERS_PER_INCH * INCHES_PER_FOOT },
  { name: '2\'', value: CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 2 },
  { name: '3\'', value: CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 3 },
  { name: '4\'', value: CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 4 },
  { name: '5\'', value: CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 5 },
  { name: '6\'', value: CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 6 },
  { name: '7\'', value: CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 7 },
  { name: '8\'', value: CENTIMETERS_PER_INCH * INCHES_PER_FOOT * 8 },
];

export const UserHeightsInches = [
  { name: '0\'\'', value: 0 },
  { name: '1\'\'', value: CENTIMETERS_PER_INCH },
  { name: '2\'\'', value: CENTIMETERS_PER_INCH * 2 },
  { name: '3\'\'', value: CENTIMETERS_PER_INCH * 3 },
  { name: '4\'\'', value: CENTIMETERS_PER_INCH * 4 },
  { name: '5\'\'', value: CENTIMETERS_PER_INCH * 5 },
  { name: '6\'\'', value: CENTIMETERS_PER_INCH * 6 },
  { name: '7\'\'', value: CENTIMETERS_PER_INCH * 7 },
  { name: '8\'\'', value: CENTIMETERS_PER_INCH * 8 },
  { name: '9\'\'', value: CENTIMETERS_PER_INCH * 9 },
  { name: '10\'\'', value: CENTIMETERS_PER_INCH * 10 },
  { name: '11\'\'', value: CENTIMETERS_PER_INCH * 11 },
];

export const UserApeIndicesInches = [
  { id: -1, name: '-', value: null },
  { id: 1, name: '-24\'\'', value: -(CENTIMETERS_PER_INCH * 24) },
  { id: 2, name: '-23\'\'', value: -(CENTIMETERS_PER_INCH * 23) },
  { id: 3, name: '-22\'\'', value: -(CENTIMETERS_PER_INCH * 22) },
  { id: 4, name: '-21\'\'', value: -(CENTIMETERS_PER_INCH * 21) },
  { id: 5, name: '-20\'\'', value: -(CENTIMETERS_PER_INCH * 20) },
  { id: 6, name: '-19\'\'', value: -(CENTIMETERS_PER_INCH * 19) },
  { id: 7, name: '-18\'\'', value: -(CENTIMETERS_PER_INCH * 18) },
  { id: 8, name: '-17\'\'', value: -(CENTIMETERS_PER_INCH * 17) },
  { id: 9, name: '-16\'\'', value: -(CENTIMETERS_PER_INCH * 16) },
  { id: 10, name: '-15\'\'', value: -(CENTIMETERS_PER_INCH * 15) },
  { id: 11, name: '-14\'\'', value: -(CENTIMETERS_PER_INCH * 14) },
  { id: 12, name: '-13\'\'', value: -(CENTIMETERS_PER_INCH * 13) },
  { id: 13, name: '-12\'\'', value: -(CENTIMETERS_PER_INCH * 12) },
  { id: 14, name: '-11\'\'', value: -(CENTIMETERS_PER_INCH * 11) },
  { id: 15, name: '-10\'\'', value: -(CENTIMETERS_PER_INCH * 10) },
  { id: 16, name: '-9\'\'', value: -(CENTIMETERS_PER_INCH * 9) },
  { id: 17, name: '-8\'\'', value: -(CENTIMETERS_PER_INCH * 8) },
  { id: 18, name: '-7\'\'', value: -(CENTIMETERS_PER_INCH * 7) },
  { id: 19, name: '-6\'\'', value: -(CENTIMETERS_PER_INCH * 6) },
  { id: 20, name: '-5\'\'', value: -(CENTIMETERS_PER_INCH * 5) },
  { id: 21, name: '-4\'\'', value: -(CENTIMETERS_PER_INCH * 4) },
  { id: 22, name: '-3\'\'', value: -(CENTIMETERS_PER_INCH * 3) },
  { id: 23, name: '-2\'\'', value: -(CENTIMETERS_PER_INCH * 2) },
  { id: 24, name: '-1\'\'', value: -(CENTIMETERS_PER_INCH) },
  { id: 25, name: '0\'\'', value: 0 },
  { id: 26, name: '+1\'\'', value: CENTIMETERS_PER_INCH },
  { id: 27, name: '+2\'\'', value: CENTIMETERS_PER_INCH * 2 },
  { id: 28, name: '+3\'\'', value: CENTIMETERS_PER_INCH * 3 },
  { id: 29, name: '+4\'\'', value: CENTIMETERS_PER_INCH * 4 },
  { id: 30, name: '+5\'\'', value: CENTIMETERS_PER_INCH * 5 },
  { id: 31, name: '+6\'\'', value: CENTIMETERS_PER_INCH * 6 },
  { id: 32, name: '+7\'\'', value: CENTIMETERS_PER_INCH * 7 },
  { id: 33, name: '+8\'\'', value: CENTIMETERS_PER_INCH * 8 },
  { id: 34, name: '+9\'\'', value: CENTIMETERS_PER_INCH * 9 },
  { id: 35, name: '+10\'\'', value: CENTIMETERS_PER_INCH * 10 },
  { id: 36, name: '+11\'\'', value: CENTIMETERS_PER_INCH * 11 },
  { id: 37, name: '+12\'\'', value: CENTIMETERS_PER_INCH * 12 },
  { id: 38, name: '+13\'\'', value: CENTIMETERS_PER_INCH * 13 },
  { id: 39, name: '+14\'\'', value: CENTIMETERS_PER_INCH * 14 },
  { id: 40, name: '+15\'\'', value: CENTIMETERS_PER_INCH * 15 },
  { id: 41, name: '+16\'\'', value: CENTIMETERS_PER_INCH * 16 },
  { id: 42, name: '+17\'\'', value: CENTIMETERS_PER_INCH * 17 },
  { id: 43, name: '+18\'\'', value: CENTIMETERS_PER_INCH * 18 },
  { id: 44, name: '+19\'\'', value: CENTIMETERS_PER_INCH * 19 },
  { id: 45, name: '+20\'\'', value: CENTIMETERS_PER_INCH * 20 },
  { id: 46, name: '+21\'\'', value: CENTIMETERS_PER_INCH * 21 },
  { id: 47, name: '+22\'\'', value: CENTIMETERS_PER_INCH * 22 },
  { id: 48, name: '+23\'\'', value: CENTIMETERS_PER_INCH * 23 },
  { id: 49, name: '+24\'\'', value: CENTIMETERS_PER_INCH * 24 },
];

/**
 * getCentimeterIncrementsList Generates a list of formatted centimeter measurements for a given range.
 * @param {Int} start - start value in centimeters
 * @param {Int} end - end value in centimeters
 * @param {Boolean} isReach - if true applies a + to positive values
 * @returns object of centimeters in intervals of 1cm with formatted name strings.
 */

function getCentimeterIncrementsList(start, end, isReach) {
  const heightObjArray = [];
  for (let i = 0; i < (end - start); i++) {
    if (isReach) {
      heightObjArray.push(
        {
          id: i + 1,
          name: (start + i) > 0 ? `+${start + i} cm` : `${start + i} cm`,
          value: start + i,
        }
      );
    } else {
      heightObjArray.push(
        {
          id: i + 1,
          name: `${start + i} cm`,
          value: start + i,
        }
      );
    }
  }
  if (isReach) {
    heightObjArray.unshift({ id: -1, name: '-', value: null });
  }
  return heightObjArray;
}

/**
 * Formats height according to imperial/metric unit type.
 *
 * @param {Float} centimeters
 * @param {ID} measurement_unit_id
 * @returns String representation of Height.
 */
export function formatHeight(centimeters, measurement_unit_id) {
  if (measurement_unit_id === UnitType.IMPERIAL) {
    const inches = remainingInchesFromCentimeters(centimeters);
    const feet = feetFromCentimeters(centimeters);
    if (inches === 0) {
      return `${feet}'0"`;
    }
    return `${feet}'${inches}"`;
  }
  return `${Math.round(centimeters)}cm`;
}

/**
 * Formats reach accoring to imperial/metric unit type.
 *
 * @param {Float} centimeters
 * @param {ID} measurement_unit_id
 * @returns String representation of Ape Index.
 */
export function formatReach(centimeters, measurement_unit_id) {
  if (centimeters === null || centimeters === undefined) {
    return '';
  }

  if (measurement_unit_id === UnitType.IMPERIAL) {
    const inches = centimetersToInches(centimeters);
    if (inches < 0) {
      return `${inches}"`;
    }
    return `+${inches}"`;
  }

  if (centimeters < 0) {
    return `${Math.round(centimeters)}cm`;
  }

  return `+${Math.round(centimeters)}cm`;
}

// Number of whole feet in some amount of centimeters
export function feetInCentimeters(heightCentimeters) {
  return feetFromCentimeters(heightCentimeters) * INCHES_PER_FOOT * CENTIMETERS_PER_INCH;
}

// Number of integer feet in some amount of centimeters
export function feetFromCentimeters(heightCentimeters) {
  const totalHeightInches = Math.round(heightCentimeters / CENTIMETERS_PER_INCH);
  const heightPortionFeet = Math.floor(totalHeightInches / INCHES_PER_FOOT);
  return heightPortionFeet;
}

// Centimeter remainder relative to feet -> heightCentimeters % (footInCentimeters)
export function remainderCentimeters(heightCentimeters) {
  return remainingInchesFromCentimeters(heightCentimeters) * CENTIMETERS_PER_INCH;
}

// Inches remainder relative to feet
export function remainingInchesFromCentimeters(heightCentimeters) {
  const totalHeightInches = Math.round(heightCentimeters / CENTIMETERS_PER_INCH);
  const heightPortionInches = totalHeightInches % INCHES_PER_FOOT;
  return heightPortionInches;
}

// Fixes float loss of accuracy
export function apeCentimeters(apeInCentimeters) {
  return Math.round(apeInCentimeters / CENTIMETERS_PER_INCH) * CENTIMETERS_PER_INCH;
}

export function centimetersToInches(centimeters) {
  return Math.round(centimeters / CENTIMETERS_PER_INCH);
}
