import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFullDate } from '../../common/Date.js'
import ProfileImg from '../User/ProfileImg.js';
import { isEmptyString } from '../../common/util.js';

// strings

export default class PostUserMeta extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  formatUserProfileImg = (uri) => {
    if (!uri) {
      return;
    }

    const resizeString = 'https://plastick.rocks/cdn-cgi/image/width=80,quality=75/';

    return resizeString.concat(uri)
  }

  render() {
    const { username, date, userPhotoUrl, caption } = this.props;

    return (
			<div>
				<div className="post-user-meta-container">
					<div className="user-thumbnail-container">
						<ProfileImg 
							src={this.formatUserProfileImg(userPhotoUrl)}
							size={"post-user-thumbnail"}
							placeholderIconSize={"2x"}
						/>
					</div>
					<div className="post-user-info">
						<p style={{ fontWeight: "bold", fontSize: 17 }}>{username}</p>
						<p>{getFullDate(date)}</p>
					</div>
				</div>
				{!isEmptyString(caption) &&
					<div className="post-user-caption">
						<p>
							{`" ${caption} "`}
						</p>
					</div>
				}
			</div>
    )	
  }
}