// react
import React, { Component } from 'react';


// components
import Loading from '../Misc/Loading';
import Modal from '../Layout/Modal';
import ModalButton from '../Button/ModalButton';
import {
  postItemCaption,
  postItemClimb,
  postItemImageUrl,
  postItemVideoThumbnailUrl,
  postItemVideoUrl
} from "../../../common/PostItem";
import ClimbVideo from "../../Climb/ClimbVideo";
import config from "../../../config";
import {getEmbedCode, getPostUrl} from "../../../common/Navigation";
import ProfileImg from "../../User/ProfileImg";
import {EVENT, logEventWithProperties, PROP} from "../../../amplitude";
import {MediaLinkType} from "../../../common/Definitions";

class MediaLinkLightbox extends Component {
  constructor(props) {
    super(props);

    // Analytics
    logEventWithProperties(EVENT.MEDIALINK_VIEWED, {
      [PROP.ORIGIN]: props.origin,
    });

    this.state = {
    };
  }

  onClose = () => {
    const {
      onClose,
    } = this.props;

    if (onClose) {
      onClose();
    }

  }

  renderIGVideo = (url) => {
    // Format to embed url
    let embedUrl = url.split('?')[0];
    if (embedUrl.indexOf('embed') < 0) {
      if (embedUrl[embedUrl.length - 1] === '/') {
        embedUrl = embedUrl.slice(0, embedUrl.length - 1);
      }
      embedUrl += '/embed';
    }
    return (
      <iframe width="320" height="620" src={embedUrl} frameBorder="0"></iframe>
    );
  }

  renderYoutubeVideo = (url) => {
    // Format to embed url
    let embedUrl = url;
    if (url.indexOf('watch?v=') >= 0) {
      embedUrl = url.replace('watch?v=', 'embed/');
    }
    return (
      <iframe width="560" height="315" src={embedUrl}
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen></iframe>
    );
  }

  renderVimeoVideo = (url) => {
    // Format to embed url
    let embedUrl = url;
    if (url.indexOf('//vimeo.com') >= 0) {
      embedUrl = url.replace('//vimeo.com', '//player.vimeo.com/video');
    } else if (url.indexOf('www.vimeo.com') >= 0) {
      embedUrl = url.replace('www.vimeo.com', 'player.vimeo.com/video');
    }
    return (
      <iframe title="vimeo-player" src={embedUrl} width="640" height="360"
              frameBorder="0" allowFullScreen></iframe>
    );
  }

  render() {
    const {
      mediaLink,
    } = this.props;

    const {
      url,
      climb,
      rp_climb_media_link_type_id,
    } = mediaLink;

    let embed;
    if (rp_climb_media_link_type_id === MediaLinkType.INSTAGRAM) {
      embed = this.renderIGVideo(url);
    } else if (rp_climb_media_link_type_id === MediaLinkType.YOUTUBE) {
      embed = this.renderYoutubeVideo(url);
    } else if (rp_climb_media_link_type_id === MediaLinkType.VIMEO) {
      embed = this.renderVimeoVideo(url);
    }

    return (
      <Modal className="post-lightbox">
        <div className="post-lightbox-content wrap">
          <div className='share-wrapper'>
            <div
              onClick={this.onClose}
              className="btn-close"
            >
              <div className="icon-clear-circle-outlined" />
              Close
            </div>
            <div className="right">
              <a href={url} target="_blank">Source Link</a>
            </div>
          </div>
          <div className="media">
            {embed}
          </div>
        </div>
      </Modal>
    );
  }
}

export default MediaLinkLightbox;
