import gql from 'graphql-tag';
import ASCENT_FRAGMENT from '../../fragment/webAscent';

export default gql`

    query webAscentsForLocation($location_id: ID!, $count: Int!, $offset: Int!) {
        webAscentsForLocation(location_id: $location_id, offset: $offset, count: $count) {
            ...WebAscentFields
        }
    }

    ${ASCENT_FRAGMENT}

`;
