import React from 'react';

import { getError } from '../../common/Error';
import Header from './Header';
import ErrorAlert from '../Common/Alert/ErrorAlert';
import SuccessAlert from '../Common/Alert/SuccessAlert';

export default function ({
  error,
  onCloseError,
  message,
  onCloseMessage,
  className,
  title,
  pageType,
  subTitle,
  buttons,
  children,
}) {
  let errorBlock;
  if (error) {
    const errorMessages = getError(error);
    if (errorMessages.length) {
      errorBlock = (
        <ErrorAlert onClose={onCloseError} text={errorMessages} />
      );
    }
  }

  let messageBlock;
  if (message) {
    errorBlock = (
      <SuccessAlert onClose={onCloseMessage} text={message} />
    );
  }

  let alertBlock;
  if (messageBlock || errorBlock) {
    alertBlock = (
      <div className="alert-pane">
        {errorBlock}
        {messageBlock}
      </div>
    );
  }

  return (
    <div id="content-inner" className={className}>
      <Header title={title} pageType={pageType} subTitle={subTitle} buttons={buttons} />
      {alertBlock}
      <main>
        <div id="main-inner">
          {children}
        </div>
      </main>
    </div>
  );
}
