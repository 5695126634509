import gql from 'graphql-tag';

export default gql`

fragment ChallengeBasicFields on Challenge {
    id
    name
    photo_url
    banner_photo_url
}

`;
