import React, { Component } from 'react';

// components
import GlobalAutocompleteSearch from "../../container/Common/GlobalAutocompleteSearch";

export default class EmbedSearchBarView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ display: 'inline-block', margin: 'auto' }}>
          <GlobalAutocompleteSearch />
        </div>
      </div>
    );
  }
}
