import gql from 'graphql-tag';

export default gql`

fragment ChallengeFields on Challenge {
    id
    name
    climb_type {
      id
      name
    }
    challenge_type {
      id
      name
    }
    photo_url
    banner_photo_url
    description_long
    description_short
    rules
    prize
    date_start
    date_end
    registration_start
    registration_end
    entry_currency
    entry_cost
    team_min_size
    team_max_size
    climb_expiry_days
    repeats_allowed
    has_categories
    has_shirts
    token_required
    has_access_code
    global
    date_created
    registered_count
    use_circuits
    has_notes
    max_participants
    payment_url
    auto_assign_category
    usac_id_required
    has_paid
    gym {
      id
      name
      photo_url
    }
}

`;
