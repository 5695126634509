import gql from 'graphql-tag';
import RECEIPT_FRAGMENT from '../../fragment/receipt';

export default gql`

query receiptsForUser($offset: Int!, $count: Int!) {
  receiptsForUser(offset: $offset, count: $count) {
    ...ReceiptFields
  }
}

${RECEIPT_FRAGMENT}
`;
