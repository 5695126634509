// Apollo Client
import ApolloClient from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { setContext } from 'apollo-link-context';
import { Hermes } from 'apollo-cache-hermes';
import { getMainDefinition } from 'apollo-utilities';
import { withClientState } from 'apollo-link-state';
import { LocalStorageKey } from './common/Definitions';
import { getAuthToken } from './common/Auth';

// App Config
import config from './config';

// Apollo Client Initialize Function
// Requires Redux Store for Websocket status events
export default (store) => {
  const httpLink = new HttpLink({
    uri: config.graphqluri,
    credentials: config.apolloClientOpts.credentials,
  });
  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getAuthToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  
  const cache = new Hermes({
    addTypename: true,
    entityIdForNode: (result) => {
      if (result.id && result.__typename) {
        return result.__typename + result.id;
      }
      return result.id || result._id;
    },
  });
  
  const stateLink = withClientState({
    cache,
  });
  
  const link = ApolloLink.from([stateLink, authLink, httpLink]);

  return new ApolloClient({
    link,
    cache,
  });
};
