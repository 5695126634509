// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router';
import {ApolloFetchPolicy, LocationType} from '../../common/Definitions';
import {logPageView, logEventWithProperties, VIEW, EVENT, PROP} from '../../amplitude';
import { Breakpoints } from '../../common/LayoutStyle';
import { isSafari } from '../../common/util';
import classnames from 'classnames';

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import GET_LOCATION from '../../graphql/query/location/webLocation';

// components
import LoadingView from '../../component/Common/Misc/LoadingView';
import Footer from '../../component/App/Footer';
import {MainHeader} from "../../component/Common/Layout/MainHeader";
import GBModeratedBanner from "../../container/Common/GBModeratedBanner";
import MainNav from "../../container/Common/MainNav";
import AscentList from "../../container/Common/AscentList";
import LocationInfo from "../../container/Location/LocationInfo";
import LocationHeader from "../../container/Location/LocationHeader";
import LocationClimbSearch from "../../container/Location/LocationClimbSearch";
import AreaList from '../../container/Location/AreaList';
import LocationAdCard from '../../component/Location/LocationAdCard';
import GBModeratedDestinations from "../../container/Location/GBModeratedDestinations";
import {getGymUrl, getLocationUrl} from "../../common/Navigation";


class LocationView extends Component {
  constructor(props) {
    super(props);

    this.isLoaded = false;

    this.state = {
      locationId: null,
      error: null,
      isMobile: window.innerWidth < Breakpoints.DESKTOP,
      checkMobileTimeout: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkMobile);
  }

  componentDidUpdate() {
    // Analytics
    if (this.props.webLocation.webLocation && !this.isLoaded) {
      this.isLoaded = true;
      logPageView(VIEW.LOCATION, {
        [PROP.NAME]: this.props.webLocation.webLocation.name,
      });
    }
  }

  checkMobile = () => {
    // Throttle Set State calls on Resize
    if (!this.state.checkMobileTimeout) {
      this.setState({
        checkMobileTimeout: setTimeout(() => {
          this.setState({
            isMobile: window.innerWidth < Breakpoints.DESKTOP,
            checkMobileTimeout: null,
          });
        }, 200),
      });
    }
  }

  _getVideoCount = (videos, mediaLinks) => {
    if (!videos && !mediaLinks) {
      return 0;
    }
    const numVideos = videos.length || 0;
    const numMediaLinks = mediaLinks.length || 0;

    return numVideos + numMediaLinks;
  }

  _getPageTitle = (location) => {
    if (!location) {
      return;
    }
    return `${location.name} Climbing - KAYA | The Climber's App`;
  }

  _getPageDescription = (location) => {
    if (!location) {
      return
    }

    if (location.description_short) {
      return location.description_short;
    }

    return location.description ? `Explore climbs in ${location.name}. ${location.description}.` : `${location.name}. Explore climbs, detailed trail mapping, offline GPS, topo lines, and thousands of beta videos on KAYA.`;
  }

  _getDestination = (location) => {
    if (location?.location_type?.id === LocationType.DESTINATION) {
      return location;
    }

    if (location?.location_type?.id === LocationType.AREA) {
      return location.parent_location;
    }

    if (location?.location_type?.id === LocationType.BOARD) {
      return location;
    }
  }

  _getArea = (location) => {
    if (location?.location_type?.id === LocationType.AREA) {
      return location;
    }
  }

  render() {
    const location = this.props.webLocation.webLocation;

    // // Attempt to open climb page in app - ignore if Safari due buggy redirect issues
    // if (climb && !isSafari()) {
    //   window.location = `kaya://kaya-app.kayaclimb.com/share/climb?climb_id=${climb.id}`;
    // }

    if (!location) {
      return (
        <div className="container location-view main-view">
          <MainHeader />
          <LoadingView className="main-view-loading"  />
        </div>
      )
    }

    return (
      <div className="container location-view main-view" >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this._getPageTitle(location)}</title>
          <meta name="description" content={this._getPageDescription(location)} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={this._getPageTitle(location)} />
          <meta property="og:description" content={this._getPageDescription(location)} />
          {location.photo_url && <meta property="og:image" content={location.photo_url} />}
          {location.photo_url && <meta property="og:image:url" content={location.photo_url} />}
          <meta property="og:url" content={`https://kaya-app.kayaclimb.com/location/${this.props.match.params.location_slug}`} />
          <meta property="og:site_name" content="KAYA" />
          <meta property="og:image:width" content="1500" />
          <meta property="og:image:height" content="1013" />
          <link rel="canonical" href={getLocationUrl(location.slug)} />
        </Helmet>

        <MainHeader />

        { location &&
          <GBModeratedBanner
            destinationId={location.id}
          />
        }

        <MainNav
          destination={this._getDestination(location)}
          area={this._getArea(location)}
          isMobile={this.state.isMobile}
          origin={'location'}
        />

        <LocationHeader
          location={location}
          isMobile={this.state.isMobile}
        />

        <AscentList
          location={location}
          isMobile={this.state.isMobile}
        />

        <LocationAdCard mobile={this.state.isMobile} />

        { location.location_type.id !== LocationType.BOARD &&
          <LocationInfo
            location={location}
            isMobile={this.state.isMobile}
            origin={'location'}
          />
        }

        <LocationClimbSearch
          location={location}
          isMobile={this.state.isMobile}
        />

        { location.location_type.id !== LocationType.BOARD &&
          <AreaList
            location={this._getDestination(location)}
            isMobile={this.state.isMobile}
          />
        }

        <GBModeratedDestinations />
        
        <Footer />
      </div>
    );
  }
}

// QUERIES
const withLocation = graphql(GET_LOCATION, {
  name: 'webLocation',
  options: (props) => {
    return {
      variables: {
        slug: props.match.params.location_slug,
      },
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

export default withRouter(compose(
  withLocation,
  withGrades,
)(LocationView));
