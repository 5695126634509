import gql from 'graphql-tag';
import WEB_USER_FRAGMENT from '../../fragment/webUser';

export default gql`

query webUserById($id: ID!) {
  webUserById(id: $id) {
    ...WebUserFields
  }
}

${WEB_USER_FRAGMENT}

`;
