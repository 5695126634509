import gql from 'graphql-tag';
import WEB_CLIMB from './webClimb';

export default gql`

    fragment WebClimbMediaLinkFields on WebClimbMediaLink {
        id
        url
        thumbnail_url
        timecode
        rp_climb_media_link_type_id
        climb {
            ...WebClimbFields
        }
    }

    ${WEB_CLIMB}
`;
