import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CreditCardOption({ last4, onClick, selected, onRemove }) {
  let removeOption;
  let selectOption;
  if (selected) {
    selectOption = <FontAwesomeIcon icon="check-circle" className="selected" />;
  } else if (onClick) {
    selectOption = <FontAwesomeIcon icon={['far', 'circle']} />;
  } else if (onRemove) {
    removeOption = <div className="option remove" onClick={onRemove}>REMOVE</div>;
  }
  return (
    <div className="cc-option" onClick={onClick}>
      <div>
        {selectOption}
        <span className="number">xxxx xxxx xxxx {last4}</span>
      </div>
      {removeOption}
    </div>
  )
}
