// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router';
import { ApolloFetchPolicy } from '../../common/Definitions';
import {logPageView, logEventWithProperties, VIEW, EVENT, PROP} from '../../amplitude';
import { Breakpoints } from '../../common/LayoutStyle';

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import GET_CLIMB from '../../graphql/query/climb/getWebClimb';
import GET_RECOMMENDED_CLIMBS from '../../graphql/query/climb/recommendedClimbsForWeb';

// components
import LoadingView from '../../component/Common/Misc/LoadingView';
import Footer from '../../component/App/Footer';
import {MainHeader} from "../../component/Common/Layout/MainHeader";
import RelatedClimbList from "../../container/Climb/RelatedClimbList";
import GBModeratedBanner from "../../container/Common/GBModeratedBanner";
import MainNav from "../../container/Common/MainNav";
import ClimbHeader from "../../container/Climb/ClimbHeader";
import LocationClimbSearch from "../../container/Location/LocationClimbSearch";
import AscentList from "../../container/Common/AscentList";
import LocationInfo from "../../container/Location/LocationInfo";
import {getClimbLocationName, getClimbName} from "../../common/Climb";
import GET_POST from "../../graphql/query/post/getWebPost";
import {
  postItemVideoUrl,
  postItemVideoThumbnailUrl,
} from "../../common/PostItem";
import LocationAdCard from "../../component/Location/LocationAdCard";
import GBModeratedDestinations from "../../container/Location/GBModeratedDestinations";
import {getClimbUrl, getLocationUrl} from "../../common/Navigation";

class ClimbView extends Component {
  constructor(props) {
    super(props);      

    this.isLoaded = false;

    this.state = {
      climbId: null,
      error: null,
      isMobile: window.innerWidth < Breakpoints.DESKTOP,
      checkMobileTimeout: null,
      selectedPost: null,
      selectedPostItem: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkMobile);
  }

  componentDidUpdate(prevProps) {
    if (this.props.webPost && prevProps.webPost) {
      // For updating video meta tags in the case of a post share link open 
      if (!prevProps.webPost.webPost && this.props.webPost.webPost && !this.state.selectedPost) {
        const hash = window.location.hash?.split('-') || [];
        const postItemId = hash[1];
        this.setState({
          selectedPost: this.props.webPost.webPost,
          selectedPostItem: this.props.webPost.webPost.items.find(pi => pi.id === postItemId),
        });
      }
    }
    
    // Analytics
    if (this.props.webClimb.webClimb && !this.isLoaded) {
      this.isLoaded = true;
      logPageView(VIEW.CLIMB, {
        [PROP.NAME]: this.props.webClimb.webClimb.name,
        [PROP.LOCATION]: getClimbLocationName(this.props.webClimb.webClimb),
      });
    }
  }
  
  checkMobile = () => {
    // Throttle Set State calls on Resize 
    if (!this.state.checkMobileTimeout) {
      this.setState({
        checkMobileTimeout: setTimeout(() => {
          this.setState({
            isMobile: window.innerWidth < Breakpoints.DESKTOP,
            checkMobileTimeout: null,
          });
        }, 200),
      });
    }
  }

  _getVideoCount = (videos, mediaLinks) => {
    if (!videos && !mediaLinks) {
      return 0; 
    }
    const numVideos = videos.length || 0; 
    const numMediaLinks = mediaLinks.length || 0; 

    return numVideos + numMediaLinks; 
  }

  _getPageTitle = (climb) => {
    if (!climb) {
      return;
    }
    return `${getClimbName(climb, false, true, true)} - KAYA | The Climber's App`;
  }

  _getPageDescription = (climb) => {
    if (!climb) {
      return
    }

    return `${getClimbName(climb, false, true, true)}. ${climb.ascent_count} ascents logged, ${climb.rating >= 0 ? parseFloat(climb.rating).toFixed(1) : 0}/5 rating - ${climb.description} - View more climbs and beta videos for ${getClimbLocationName(climb)} on KAYA.`;
  }

  render() {
    const climb = this.props.webClimb.webClimb;
    const postItem = this.state.selectedPostItem;
    // // Attempt to open climb page in app - ignore if Safari due buggy redirect issues 
    // if (climb && !isSafari()) {
    //   window.location = `kaya://kaya-app.kayaclimb.com/share/climb?climb_id=${climb.id}`;
    // }
    
    if (!climb) {
      return (
        <div className="container climb-view main-view">
          <MainHeader />
          <LoadingView className="main-view-loading"  />
      </div>
      )
    }

    return (
      <div 
        className="container climb-view main-view" 
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this._getPageTitle(climb)}</title>
          <meta name="description" content={this._getPageDescription(climb)} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={this._getPageTitle(climb)} />
          <meta property="og:description" content={this._getPageDescription(climb)} />
          {postItem ? 
            <meta property="og:image" content={postItemVideoThumbnailUrl(postItem)} />
            :
            <meta property="og:image" content={climb && climb.top_videos.length && climb.top_videos[0].thumb_url} />
          }
          {postItem ? 
            <meta property="og:image:url" content={postItemVideoThumbnailUrl(postItem)} />
            :
            <meta property="og:image:url" content={climb && climb.top_videos.length && climb.top_videos[0].thumb_url} />
          }
          <meta property="og:url" content={`https://kaya-app.kayaclimb.com/climb/${this.props.match.params.climb_slug}`} />
          <meta property="og:site_name" content="KAYA" />
          <meta property="og:image:width" content="1500" />
          <meta property="og:image:height" content="1013" />
          {postItem && 
            <meta property="og:video" content={postItemVideoUrl(postItem)} />
          }
          {climb && climb.top_videos.length && climb.top_videos[0].video_url && !postItem && 
            <meta property="og:video" content={climb && climb.top_videos.length && climb.top_videos[0].video_url} />
          }
          { climb && climb.slug &&
            <link rel="canonical" href={getClimbUrl(climb.slug)} />
          }
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org/",
                "@type": "LocalBusiness",
                "name": "${getClimbName(climb, false, true, true)}",
                "description": "${climb.grade.name} ${climb.climb_type.name} ${climb.description ? `- ${climb.description}` : ''}",
                "image": "${postItem ? postItemVideoThumbnailUrl(postItem) : climb && climb.top_videos.length && climb.top_videos[0].thumb_url}",
                ${climb.destination && climb.destination.latitude ?
                  `"geo": {
                        "@type": "GeoCoordinates",
                        "latitude": "${climb.destination.latitude}",
                        "longitude": "${climb.destination.longitude}"
                      },`
                    :
                    ''
                  }
                  ${climb.rating ?
                    `"aggregateRating": {
                          "@type": "AggregateRating",
                          "ratingValue": "${climb.rating}",
                          "reviewCount": "${climb.ascent_count}"
                        }`
                    :
                    ''
                  }
              }
            `}
          </script>
        </Helmet>

        <MainHeader />

        { climb.destination &&
          <GBModeratedBanner
            destinationId={climb.destination.id}
          />
        }

        <MainNav
          gym={climb.gym}
          board={climb.board}
          destination={climb.destination}
          area={climb.area}
          climb={climb}
          isMobile={this.state.isMobile}
          origin={'climb'}
        />

        <ClimbHeader
          climb={climb}
          isMobile={this.state.isMobile}
        />

        <AscentList
          climb={climb}
          isMobile={this.state.isMobile}
        />

        <LocationAdCard mobile={this.state.isMobile} />

        { climb.destination &&
          <LocationInfo
            location={climb.destination}
            isMobile={this.state.isMobile}
            origin={'climb'}
          />
        }

        <RelatedClimbList
          climb={climb}
          slug={climb.slug}
          isMobile={this.state.isMobile}
        />
        
        {climb.destination && 
          <LocationClimbSearch
            location={climb.destination}
            isMobile={this.state.isMobile}
          />
        }

        <GBModeratedDestinations />

        <Footer /> 
      </div>
    );
  }
}

function getPostVariables() {
  const hash = window.location.hash;
  if (hash) {
    const hashSplit = hash.replace('#', '').split('-');
    if (hashSplit.length === 2) {
      const post_id = hashSplit[0];
      const post_item_id = hashSplit[1];
      return {
        post_id,
        post_item_id,
      };
    }
  }
  return null
}

// QUERIES
const withClimb = graphql(GET_CLIMB, {
  name: 'webClimb',
  options: (props) => {
    return {
      variables: { 
        slug: props.match.params.climb_slug,
      }, 
      fetchPolicy: ApolloFetchPolicy.NETWORK_ONLY,
    };
  },
});

const withWebPost = graphql(GET_POST, {
  name: 'webPost',
  options: (props) => {
    const variables = getPostVariables();
    return {
      variables,
    };
  },
  skip: (props) => !getPostVariables(),
});

const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

export default withRouter(compose(
  withClimb,
  withGrades,
  withWebPost,
)(ClimbView));
