import gql from 'graphql-tag';
import POST_FRAGMENT from '../../fragment/webPost';

export default gql`

    query webPostsForLocation($location_id: ID!, $count: Int!, $offset: Int!) {
        webPostsForLocation(location_id: $location_id, count: $count, offset: $offset) {
            ...WebPostFields
        }
    }

    ${POST_FRAGMENT}

`;
