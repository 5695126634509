import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// relative
import { getFullDate } from '../../common/Date.js'
import {
  formatHeight,
  formatReach,
} from '../../common/UserHeight';
import { FilterAscentLocations, UnitType } from '../../common/Definitions.js';
import ProfileImg from '../User/ProfileImg.js';
import { isEmptyString } from '../../common/util.js';

// strings

export default class UserProfileHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // placeholder
    };
  }
  
  _topGradeText = (user) => {
    if (!user) {
      return '-';
    }
    const topBoulderingGrade = user.limit_grade_bouldering;

    const topRoutesGrade = user.limit_grade_routes;

    // Top Grades
    if (topBoulderingGrade && topRoutesGrade) {
      return `${topBoulderingGrade.name} / ${topRoutesGrade.name}`;
    }
    if (topBoulderingGrade) {
      return topBoulderingGrade.name;
    }
    if (topRoutesGrade) {
      return topRoutesGrade.name;
    }
    return '-';
  }
  
  _totalSendsText = () => {
    const { boulderingData, routesData } = this.props; 
    if (
      !boulderingData?.length ||
      !routesData?.length
    ) {
      return '-';
    }

    const boulderingCount = boulderingData.reduce((prev, curr) => { return curr.ascent_count + prev; }, 0);
    const routesCount = routesData.reduce((prev, curr) => { return curr.ascent_count + prev; }, 0);

    return boulderingCount + routesCount;
  }
  
  _sendsTypeText = () => {
    const { filterBy } = this.props; 
    
    switch (filterBy) {
      case FilterAscentLocations.OUTDOOR:
        return 'Outdoor';
      case FilterAscentLocations.GYM:
        return 'Gym';
      case FilterAscentLocations.BOARD:
        return 'Board';
      case FilterAscentLocations.ALL:
        return 'Total';
      default:
        return 'Total';
    }
  }
  
  _formatHeightReach = () => {
    const user = this.props.user;
    let height = formatHeight(user.height, UnitType.IMPERIAL); // Default to imperial site-wite for now 

    if (user.ape_index !== null && user.ape_index !== undefined) {
      height = `${height} / ${formatReach(user.ape_index, UnitType.IMPERIAL)}`;
    }
    return height;
  }

  formatUserProfileImg = (uri) => {
    if (!uri) {
      return;
    }

    const resizeString = 'https://plastick.rocks/cdn-cgi/image/width=80,quality=75/';

    return resizeString.concat(uri)
  }

  renderForMobile = () => {
    const { user } = this.props; 
    
    return (
      <div className="user-header-mobile">
        <div className="profile-img-mobile" style={{ backgroundImage: !isEmptyString(user?.photo_url) && `url(${user?.photo_url})`}} />
        <div className="user-info-col">
          <p className="user-fullname">{user.is_private ? 'Private User' : `${user.fname} ${user.lname}`}</p>
          {!user.is_private && <p className="user-username">{`@${user.username}`}</p>}
        </div>
        
        <div className="user-stat-container">
          <div className="user-stat-row">
            <div className="user-stat-col">
              <p className='stat-val'>{user.is_private ? '-' : this._totalSendsText()}</p>
              <p className='stat-text'>Total sends</p>
            </div>
            <div className="user-stat-col">
              <p className='stat-val'>{user.is_private ? '-' : this._topGradeText(user)}</p>
              <p className='stat-text'>Top grade</p>
            </div>
            <div className="user-stat-col">
              <p className='stat-val'>{user.is_private ? '-' : this._formatHeightReach()}</p>
              <p className='stat-text'>Height/Reach</p>
            </div>
          </div>
          {!isEmptyString(user.bio) && !user.is_private && <p className='user-bio'>{`"${user.bio}"`}</p> }
        </div>
      </div>
    );
  }

  renderForDesktop = () => {
    const { user } = this.props; 
    
    return (
      <div className="user-header-desktop wrap">
        <div className="profile-img" style={{ backgroundImage: !isEmptyString(user?.photo_url) && `url(${user?.photo_url})`}} />
        <div className="spacer"/>
        <div className='user-header-content'>
          <div className="user-header-row">
            <div className="user-info-col">
              <p className="user-fullname">{user.is_private ? 'Private User' : `${user.fname} ${user.lname}`}</p>
              {!user.is_private && <p className="user-username">{`@${user.username}`}</p>}
            </div>
                      
            <div className="user-stat-container">
              <div className="user-stat-row">
                <div className="user-stat-col">
                  <p className='stat-val'>{user.is_private ? '-' : this._totalSendsText()}</p>
                  <p className='stat-text'>Total sends</p>
                </div>
                <div className="user-stat-col">
                  <p className='stat-val'>{user.is_private ? '-' : this._topGradeText(user)}</p>
                  <p className='stat-text'>Top grade</p>
                </div>
                <div className="user-stat-col">
                  <p className='stat-val'>{user.is_private ? '-' : this._formatHeightReach()}</p>
                  <p className='stat-text'>Height/Reach</p>
                </div>
              </div>
            </div>
          </div>
          {!isEmptyString(user.bio) && !user.is_private && <p className='user-bio'>{`"${user.bio}"`}</p> }
        </div>
      </div>
    );
  }
  
  render() {
    const {
      user,
      isMobile
    } = this.props;
    
    if (!user) {
      return <div />;
    }
    
    return (
      <div className="user-profile-header">
        { isMobile ? 
          this.renderForMobile()
          :
          this.renderForDesktop()
        }
      </div>
    )	
  }
}
