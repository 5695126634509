import gql from 'graphql-tag';
import WEB_USER_FRAGMENT from '../../fragment/webUser';

export default gql`

query webUser($username: String!) {
  webUser(username: $username) {
    ...WebUserFields
  }
}

${WEB_USER_FRAGMENT}

`;
