// react
import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';

// queries
import GET_GRADES from '../../graphql/query/grade/getGrades';
import GlobalAutocompleteSearch from "./GlobalAutocompleteSearch";
import {getClimbUrl, getGymUrl, getLocationUrl} from "../../common/Navigation";
import {getClimbName} from "../../common/Climb";

class MainNav extends Component {
  renderCrumb = (label, link, prefix, disableLink) => {
    return (
      <div className={'crumb'}>
        { prefix && <span>{prefix}</span> }
        {disableLink &&
          label
        }
        {!disableLink &&
          <a href={link}>
            {label}
          </a>
        }
      </div>
    )
  }
  render() {
    const {
      gym,
      board,
      destination,
      area,
      climb,
      isMobile,
      origin,
    } = this.props;

    return (
      <div className="main-nav">
        <div className={classnames('wrap', isMobile && 'mobile')}>
          <div className="breadcrumbs">
            {gym &&
              this.renderCrumb(
                gym.name,
                getGymUrl(gym.slug),
              )
            }
            {board &&
              this.renderCrumb(
                board.name,
                getLocationUrl(board.slug),
              )
            }
            {destination &&
              this.renderCrumb(
                destination.name,
                getLocationUrl(destination.slug),
              )
            }
            {area &&
              this.renderCrumb(
                area.name,
                getLocationUrl(area.slug),
                <div className='icon-chevron-right' />
              )
            }
            {climb &&
              this.renderCrumb(
                getClimbName(climb, true),
                getClimbUrl(climb.slug),
                <div className='icon-chevron-right' />,
                true
              )
            }
          </div>

          <GlobalAutocompleteSearch
            origin={origin}
          />
        </div>
      </div>
    );
  }
}

// QUERIES
const withGrades = graphql(GET_GRADES, {
  name: 'grades',
});

export default compose(
  withGrades,
)(MainNav);
