import React from 'react';
import Loading from "../../component/Common/Misc/Loading";
import LoadingInline from "../../component/Common/Misc/LoadingInline";

export default class WeatherWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = "(function(d, s, id) {\n" +
      "      if (d.getElementById(id)) {\n" +
      "      if (window.__TOMORROW__) {\n" +
      "      window.__TOMORROW__.renderWidget();\n" +
      "    }\n" +
      "      return;\n" +
      "    }\n" +
      "      const fjs = d.getElementsByTagName(s)[0];\n" +
      "      const js = d.createElement(s);\n" +
      "      js.id = id;\n" +
      "      js.src = \"https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js\";\n" +
      "\n" +
      "      fjs.parentNode.insertBefore(js, fjs);\n" +
      "    })(document, 'script', 'tomorrow-sdk');";
    this.instance.appendChild(s);

    this._loadingTimeout = setTimeout(this.checkLoading, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this._loadingTimeout);
  }

  checkLoading = () => {
    const container = document.getElementsByClassName('tomorrow')[0];
    const iframes = container.getElementsByTagName('iframe');
    if (iframes && iframes[0]?.height > 0) {
      this.setState({
        isLoading: false,
      });
    } else {
      this._loadingTimeout = setTimeout(this.checkLoading, 1000);
    }
  }

  render() {
    const {
      latitude,
      longitude,
    } = this.props;
    return (
      <div className="tomorrow"
           data-latitude={latitude}
           data-longitude={longitude}
           data-language="EN"
           data-unit-system="IMPERIAL"
           data-skin="light"
           data-widget-type="upcoming"
           ref={el => (this.instance = el)}
      >
        <a
          href="https://www.tomorrow.io/weather-api/"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <img
            alt="Powered by the Tomorrow.io Weather API"
            src="https://weather-website-client.tomorrow.io/img/powered-by.svg"
            width="250"
            height="18"
          />
        </a>

        { this.state.isLoading &&
          <div className="loading-container">
            <LoadingInline />
          </div>
        }
      </div>
    );
  }
}
