// react
import React from 'react';

// react router
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';

import Login from '../Auth/Login';
import MainView from '../../component/App/MainView';
import UserAccountView from '../User/UserAccountView';
import UserReceiptsView from '../User/UserReceiptsView';
import ChallengesMarketView from '../Market/ChallengesMarketView';
import ChallengeProductView from '../Market/ChallengeProductView';
import ShareView from '../Share/ShareView';
import ClimbView from '../Climb/ClimbView';
import LocationView from '../Location/LocationView';
import GymView from '../Location/GymView';
import PostView from '../Post/PostView';
import EmbedSearchBarView from '../Search/EmbedSearchBarView';
import VideoEmbedView from '../Video/VideoEmbedView';
import ProfileView from '../User/ProfileView';

export default function Routes(props) {
  if (props.isLoggedIn && props.user) {
    return (
      <Switch>
        <Route
          path="/share"
          render={() => (
            <ShareView {...props}/>
          )}
        />
        <Route
          path="/login"
          render={() => <Redirect to="/" />}
        />
        <Route
          exact
          path="/account"
          render={() => (
            <MainView {...props}>
              <UserAccountView {...props} />
            </MainView>
          )}
        />
        <Route
          exact
          path="/purchase/challenge/:challenge_id"
          render={() => (
            <MainView {...props}>
              <ChallengeProductView {...props} />
            </MainView>
          )}
        />
        <Route
          exact
          path="/purchase"
          render={() => (
            <MainView {...props}>
              <ChallengesMarketView {...props} />
            </MainView>
          )}
        />
        <Route
          exact
          path="/receipts"
          render={() => (
            <MainView {...props}>
              <UserReceiptsView {...props} />
            </MainView>
          )}
        />
        <Route
          exact
          path="/climb/:climb_slug"
          render={() => (
            <ClimbView {...props} />
          )}
        />
        <Route
          exact
          path="/post/:climb_slug/:post_id/:post_item_id"
          render={() => (
            <PostView {...props} />
          )}
        />
        <Route
          exact
          path="/location/:location_slug"
          render={() => (
            <LocationView {...props} />
          )}
        />
        <Route
          exact
          path="/gym/:gym_slug"
          render={() => (
            <GymView {...props} />
          )}
        />
        <Route
          exact
          path="/search-embed"
          render={() => (
            <EmbedSearchBarView {...props} />
          )}
        />
        <Route
          exact
          path="/embed/:post_id/:post_item_id"
          render={() => (
            <VideoEmbedView {...props} />
          )}
        />
        <Route
          exact
          path="/user/:username"
          render={() => (
            <ProfileView {...props} />
          )}
        />
        <Route
          path="/"
          render={() => (
            <Redirect to="/purchase" />
          )}
        />
      </Switch>
    );
  }
  return (
    <Switch>
      <Route
        path="/share"
        render={() => (
          <ShareView {...props}/>
        )}
      />
      <Route
        path="/login"
        render={() => <Login />}
      />
      <Route
        exact
        path="/climb/:climb_slug"
        render={() => (
          <ClimbView {...props} />
        )}
      />
      <Route
        exact
        path="/post/:climb_slug/:post_id/:post_item_id"
        render={() => (
          <PostView {...props} />
        )}
      />
      <Route
        exact
        path="/location/:location_slug"
        render={() => (
          <LocationView {...props} />
        )}
      />
      <Route
        exact
        path="/gym/:gym_slug"
        render={() => (
          <GymView {...props} />
        )}
      />
      <Route
        exact
        path="/search-embed"
        render={() => (
          <EmbedSearchBarView {...props} />
        )}
      />
      <Route
        exact
        path="/embed/:post_id/:post_item_id"
        render={() => (
          <VideoEmbedView {...props} />
        )}
      />
      <Route
        exact
        path="/user/:username"
        render={() => (
          <ProfileView {...props} />
        )}
      />
      <Route
        path="/"
        render={() => <Login />}
      />
    </Switch>
  );
}
