import moment from 'moment';

export const diffWeeks = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return (d1.getTime() - d2.getTime()) / 1000 / 60 / 60 / 24 / 7.0;
};

export const getFullDate = (date) => {
  const dateObj = new Date(date);
  return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`; 
};

export const getShortDate = (date, showRelevance) => {
  const dateStart = moment(date).startOf('day');
  if (showRelevance) {
    const todayStart = moment().startOf('day');
    if (dateStart.utc().diff(todayStart.utc(), 'minutes') === 0) {
      return 'Today';
    }
  }
  return dateStart.format('MMM D');
};

export const getShortFullDate = (date) => {
  const dateStart = moment(date).startOf('day');
  return dateStart.format('M/D/YY');
};

export const getDateFromDate = (date = new Date(), addMonths = 0, addDays = 0) => {
  let newDate = new Date(date.setMonth(date.getMonth() + addMonths));
  newDate = new Date(newDate.setDate(date.getDate() + addDays));
  return newDate;
};


/**
 * Returns date text depending on relevancy to Today.
 * Includes text like "Today", "Yesterday", "This Week", etc.
 * @param {Date} date
 */
export const formatReleventDate = (date, daysOnly) => {
  const dateObj = moment(date).local();
  const today = moment().local();
  const diffDays = today.diff(dateObj, 'days');
  if (diffDays < 1) {
    if (daysOnly) {
      return 'Today';
    }
    // Get Hours
    const diffHours = today.diff(dateObj, 'hours');
    if (diffHours < 1) {
      // Get Minutes
      const diffMinutes = today.diff(dateObj, 'minutes');
      if (diffMinutes <= 1) {
        return 'Now';
      }
      return `${diffMinutes} minutes ago`;
    } else if (diffHours === 1) {
      return '1 hour ago';
    }
    return `${diffHours} hours ago`;
  } else if (diffDays === 1) {
    return 'Yesterday';
  } else if (diffDays === 2) {
    return 'Two Days Ago';
  } else if (diffDays === 3) {
    return 'Three Days Ago';
  }
  return getFullDate(date);
};
