import gql from 'graphql-tag';
import CLIMB_FRAGMENT from '../../fragment/webClimbBasic';

export default gql`

    query webClimbsForLocation(
        $location_id: ID!,
        $climb_name: String,
        $climb_type_id: ID,
        $min_grade_id: ID,
        $max_grade_id: ID,
        $min_rating: Int,
        $max_rating: Int,
        $sort_by: sortClimbs,
        $offset: Int!,
        $count: Int!
    ) {
        webClimbsForLocation(
            location_id: $location_id,
            climb_name: $climb_name,
            climb_type_id: $climb_type_id,
            min_grade_id: $min_grade_id,
            max_grade_id: $max_grade_id,
            min_rating: $min_rating,
            max_rating: $max_rating,
            sort_by: $sort_by,
            offset: $offset,
            count: $count,
            use_reduced_query: true,
        ) {
            ...WebClimbBasicFields
        }
    }

    ${CLIMB_FRAGMENT}

`;
