const config = {
  test: {
    type: 'test',
    uri: process.env.REACT_APP_TEST_URI,
    graphqluri: process.env.REACT_APP_TEST_GRAPHQL_URI,
    apolloClientOpts: {
      credentials: 'include',
    },
    subscriptionEndpoint: process.env.REACT_APP_TEST_SUBSCRIPTION_ENDPOINT,
    stripeKey: process.env.REACT_APP_TEST_STRIPE_P_KEY,
    recaptchaKey: process.env.REACT_APP_TEST_RECAPTCHA_KEY,
    gaID: process.env.REACT_APP_GA_ID,
    version: process.env.REACT_APP_VERSION,
    kayaShareUri: process.env.REACT_APP_TEST_KAYA_SHARE_URI,
    appleLoginRedirectUri: process.env.REACT_APP_TEST_APPLE_LOGIN_REDIRECT_URI,
    clientUri: process.env.REACT_APP_TEST_CLIENT_URI,
  },
  development: {
    type: 'development',
    uri: process.env.REACT_APP_DEV_URI,
    graphqluri: process.env.REACT_APP_DEV_GRAPHQL_URI,
    apolloClientOpts: {
      credentials: 'same-origin',
    },
    subscriptionEndpoint: process.env.REACT_APP_DEV_SUBSCRIPTION_ENDPOINT,
    stripeKey: process.env.REACT_APP_DEV_STRIPE_P_KEY,
    recaptchaKey: process.env.REACT_APP_DEV_RECAPTCHA_KEY,
    gaID: process.env.REACT_APP_DEV_GA_ID,
    version: process.env.REACT_APP_DEV_VERSION,
    kayaShareUri: process.env.REACT_APP_DEV_KAYA_SHARE_URI,
    appleLoginRedirectUri: process.env.REACT_APP_DEV_APPLE_LOGIN_REDIRECT_URI,
    clientUri: process.env.REACT_APP_DEV_CLIENT_URI,
  },
  beta: {
    type: 'beta',
    uri: process.env.REACT_APP_BETA_URI,
    graphqluri: process.env.REACT_APP_BETA_GRAPHQL_URI,
    apolloClientOpts: {
      credentials: 'same-origin',
    },
    subscriptionEndpoint: process.env.REACT_APP_BETA_SUBSCRIPTION_ENDPOINT,
    stripeKey: process.env.REACT_APP_BETA_STRIPE_P_KEY,
    recaptchaKey: process.env.REACT_APP_BETA_RECAPTCHA_KEY,
    gaID: process.env.REACT_APP_BETA_GA_ID,
    version: process.env.REACT_APP_BETA_VERSION,
    kayaShareUri: process.env.REACT_APP_BETA_KAYA_SHARE_URI,
    appleLoginRedirectUri: process.env.REACT_APP_BETA_APPLE_LOGIN_REDIRECT_URI,
    clientUri: process.env.REACT_APP_BETA_CLIENT_URI,
  },
  production: {
    type: 'production',
    uri: process.env.REACT_APP_URI,
    graphqluri: process.env.REACT_APP_GRAPHQL_URI,
    apolloClientOpts: {
      credentials: 'same-origin',
    },
    subscriptionEndpoint: process.env.REACT_APP_SUBSCRIPTION_ENDPOINT,
    stripeKey: process.env.REACT_APP_STRIPE_P_KEY,
    recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
    gaID: process.env.REACT_APP_GA_ID,
    version: process.env.REACT_APP_VERSION,
    kayaShareUri: process.env.REACT_APP_KAYA_SHARE_URI,
    appleLoginRedirectUri: process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URI,
    clientUri: process.env.REACT_APP_CLIENT_URI,
  },
};

module.exports = config[process.env.REACT_APP_CUSTOM_NODE_ENV];
