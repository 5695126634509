// react
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

export default function Modal(props) {
  return (
    <div className={classnames('modal', props.className)}>
      <div className="modal-blackout">
        <div className="modal-window">
          <div className="btn-modal-close" onClick={props.onClose} role="button">
            <FontAwesomeIcon icon="times" />
          </div>
          <div className="modal-content">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}
