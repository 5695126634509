// react
import React from 'react';
import classnames from 'classnames';

export default function LocationAdCard({ className, mobile }) {
  return (
    <div className={classnames('location-ad-card', 'wrap', mobile && 'mobile', className)}>
      <div className={classnames('card-content', mobile && 'mobile')}>
        { !mobile && <div className='spacer'/> }
        <div className={classnames('text-container', mobile && 'mobile')}>
          <div className='title'>Never get lost again with GPS pins, trails, and topos for 30+ major bouldering destinations across North America.</div>
          <div
            className='download-button'             
            onClick={() => window.open("https://kayaclimb.com/download")}
          >
            <div
              className="download-button-text"
            >
              Download KAYA
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
