import gql from 'graphql-tag';
import CHALLENGE_FRAGMENT from '../../fragment/challenge';

export default gql`

mutation payForChallenge($challenge_id: ID!, $last4: String!) {
  payForChallenge(challenge_id: $challenge_id, last4: $last4) {
    ...ChallengeFields
  }
}

${CHALLENGE_FRAGMENT}
`;
